import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Campaigns from "./components/Campaigns";
import Clinic from "./components/Clinic";
import NotFoundPage from "./components/common/NotFound";
import ContentType from "./components/ContentType";
import Dashboard from "./components/Dashboard";
import ECardList from "./components/ECardList";
import Landing from "./components/Landing";
import Login from "./components/Login";
import SkillSet from "./components/SkillSet";
import GenerateECard from "./components/GenerateECard";
import Protected from "./protected";
import RepProfile from "./components/Profile";
import DocList from "./components/DocList";
import RepDetails from "./components/RepDetails";
import EditDocDetails from "./components/EditDocDetails";
import ChemistForm from "./components/ChemistForm";

function App() {
  const isToken = localStorage.getItem("token");
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <Protected isToken={isToken}>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/home"
            element={
              <Protected isToken={isToken}>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/content-type"
            element={
              <Protected isToken={isToken}>
                <ContentType />
              </Protected>
            }
          />
          <Route
            path="/campaigns"
            element={
              <Protected isToken={isToken}>
                <Campaigns />
              </Protected>
            }
          />
          <Route
            path="/ecard-list/:id"
            element={
              <Protected isToken={isToken}>
                <ECardList />
              </Protected>
            }
          />
          <Route
            path="/generate-ecard"
            element={
              <Protected isToken={isToken}>
                <GenerateECard />
              </Protected>
            }
          />
          <Route
            path="/clinic/:id"
            element={
              <Protected isToken={isToken}>
                <Clinic />
              </Protected>
            }
          />
          <Route
            path="/chemist/:id"
            element={
              <Protected isToken={isToken}>
                <ChemistForm />
              </Protected>
            }
          />
          <Route
            path="/skill-set"
            element={
              <Protected isToken={isToken}>
                <SkillSet />
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected isToken={isToken}>
                <RepProfile />
              </Protected>
            }
          />
          <Route
            path="/doc-list"
            element={
              <Protected isToken={isToken}>
                <DocList />
              </Protected>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <Protected isToken={isToken}>
                <RepDetails />
              </Protected>
            }
          />
          <Route
            path="/edit-doc-details/:id"
            element={
              <Protected isToken={isToken}>
                <EditDocDetails />
              </Protected>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/landing/:id" element={<Landing />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
