//elephant code here
import DOMPurify from "dompurify";
import React from "react";

const FestiveGif1 = ({ doctorData, formLang }) => {
  const html = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />

  </head>
  <body>
    <div class="container">
      <div class="bgblue">
        <div style="position: absolute; top: 0px; width: 100%; height:600px;">
          <img
            class="img-border"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-border.png"
          />
        </div>
        <div style="position: absolute; top: 0px; width: 100%;">
          <div
            class="header"
            style="
              text-align: center;
              color: #fff;
              text-transform: uppercase;
              margin-top: 3rem;
              letter-spacing: 1px;
              font-weight: 700;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
            "
          >
            HAPPY AND PROSPEROUS
          </div>
          <div
            class="main-title"
            style="
              text-align: center;
              color: #ffb900;
              font-weight: 400;
              font-family: 'Lora', serif;
              font-size: 2rem;
              margin-bottom: 5px
            "
          >
            Diwali
          </div>
          <div
            class="message"
            style="
              text-align: center;
              color: #fff;
              font-weight: 300;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              margin: 0 34px;
              line-height: 18px;
            "
          >
            May the festival of lights fill your life with happiness and
            prosperity
          </div>
          <div
            style="
              font-size: 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              line-height: 30px;
              width: 100%;
              text-align: center;
              color: #fff;
              margin: 12px auto 5px auto;
            "
            class="warm-regards"
          >
            Warm Regards
          </div>
          <div
            style="
              font-size: 17px;
              font-family: 'Inter', sans-serif;
              font-weight: 700;
              line-height: 32px;
              text-align: center;
              max-width: 330px;
              width: 100%;
              color: #fff;
              margin: 0 auto 15px auto;
            "
            class="dr-name"
          >
           ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div
            style="
              font-size: 16px;
              font-family: 'Inter', sans-serif;
              font-weight: 700;
              line-height: 22px;
              max-width: 270px;
              width: 100%;
              color: #fff;
              text-align: center;
              margin: 12px auto 0.5rem auto;
            "
            class="clinic-name"
          >
           ${doctorData?.clinic_name}
          </div>
          <div
            style="
              font-size: 11px;
              font-family: 'Inter', sans-serif;
              line-height: 16px;
              text-align: center;
              max-width: 420px;
              width: 75%;
              color: #fff;
              margin: 0 auto;
            "
            class="clinic-address"
          >
            ${doctorData?.address}
          </div>
        </div>
        <img
          class="elephant-left"
          width="180"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-left.png"
        />
        <img
          class="elephant-right"
          width="180"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-right.png"
        />
        <div
          style="
            position: absolute;
            bottom: -9rem;
            width: 100%;
            z-index: 9;
            display: flex;
            justify-content: center;
          "
          class="rangoli"
        >
          <img
            width="300"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-rangoli.png"
            class="flower"
          />
          <div
            style="
              margin: 0px auto;
              position: absolute;
              bottom: 108px;
              z-index: 99;
            "
          >
            <div
              style="
                height: 115px;
                width: 115px;
                background: #340068;
                border-radius: 800px;
              "
            >
              <div
                class="diya"
                style="width: 60px; margin: 0px auto 0 auto; padding-top: 18px"
              >
                <img
                  width="90"
                  src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-diya-s.png"
                  class="diya-light"
                />
                <img
                  width="90"
                  src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-diya.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }
      .container {
        position: relative;
        width: calc(100vw - 32px);
        height: 600px;
        overflow: hidden;
        background: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/bg.png");
        background-position: center;
        background-size: cover;
      }
      .elephant-left {
        position: absolute;
        left: -8rem;
        bottom: 8rem;
      }
      .elephant-right {
        position: absolute;
        right: -8rem;
        bottom: 8rem;
      }
      .header,
      .main-title,
      .message,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .elephant-left,
      .elephant-right,
      .diya {
        visibility: hidden;
      }
      .img-border {
        height: 100vh;
        width: 100vw;
        animation: showAndBlink 1s forwards;
        animation-delay: 0.5s;
      }
      .header {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 1.5s;
      }
      .main-title {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 2.5s;
      }
      .message {
        animation: showMesssage 1s ease-out forwards;
        animation-delay: 3.5s;
      }
      .warm-regards {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 4.5s;
      }
      .dr-name,
      .clinic-name,
      .clinic-address {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 5s;
      }
      .elephant-left {
        animation: moveRight 1s ease-in-out forwards;
        animation-delay: 6s;
      }
      .elephant-right {
        animation: moveLeft 1s ease-in-out forwards;
        animation-delay: 7s;
      }
      .diya {
        animation: moveTop 1s ease-in-out forwards;
        animation-delay: 7.5s;
      }
      .flower {
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .diya-light {
        animation: shake-light infinite;
        animation-delay: 9s;
        transform: rotate(5deg);
      }
      @keyframes shake-light {
        0% {
          transform: rotate(0deg);
        }
        20% {
          transform: rotate(5deg);
        }
        40% {
          transform: rotate(0deg);
        }
        60% {
          transform: rotate(-5deg);
        }
        80% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(5deg);
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes moveTop {
        0% {
          transform: translateY(80%);
          /* bottom: -30%; */
        }
        100% {
          /* bottom: 10%; */
          transform: translateY(0%);
          visibility: visible;
        }
      }
      @keyframes showAndBlink {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        10% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes showHeader {
        0% {
          transform: translateY(-80%); /* Start 5% from the bottom */
          opacity: 0;
        }
        100% {
          transform: translateY(0); /* End at current position */
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes showMesssage {
        0% {
          transform: translateY(80%); /* Start 5% from the bottom */
          opacity: 0;
        }
        100% {
          transform: translateY(0); /* End at current position */
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveRight {
        0% {
          left: -30%;
        }
        100% {
          left: -25%;
          visibility: visible;
        }
      }
      @keyframes moveLeft {
        0% {
          right: -30%;
        }
        100% {
          right: -25%;
          visibility: visible;
        }
      }`}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};

export default FestiveGif1;
