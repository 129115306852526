import React, { useEffect, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { timeSlots } from "../../store/timeSlotStore";
import { isEmpty } from "lodash";
import { onBoardStoreData } from "../../store/onboardStore";

const ContentTwo = ({ doctorData, sanitizedTimingsHtml, setHeight }) => {
  const googleMapsUrl = `https://www.google.com/maps?q=${doctorData?.lat},${doctorData?.long}`;
  const specialityData = timeSlots((state) => state.specialityData);
  const formLang = onBoardStoreData((state) => state.formLang);
  const cleanName =
    formLang === "english"
      ? specialityData.name.replace(/\s*\(.*?\)\s*/, "")
      : specialityData?.hindi_name;

  console.log("fotntm", specialityData);
  const divRef = useRef(null);

  // Function to get the height of the div
  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };
  useEffect(() => {
    getDivHeight();
  }, [doctorData]);

  return (
    <div
      className="px-3.5 text-white m-3 md:w-full w-calc(100vw - 24px)"
      style={{
        backgroundImage: `url(${specialityData.card2_background_image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "",
      }}
      ref={divRef}
    >
      <div className="grid grid-cols-3 gap-2">
        <div className="col-span-2">
          <div className="py-3">
            <p className="font-semibold text-sm">
              {formLang === "english" ? "Dr. " : "डॉ. "}
              {doctorData?.first_name + " " + doctorData?.last_name}
            </p>
            <p className="text-[10px] mt-1.5 uppercase">
              {cleanName} <span>({doctorData?.professional_degree})</span>
            </p>
          </div>
          <div className="pb-3">
            <img
              src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border.png"
              alt="line"
              className="w-full"
            />

            <div className="py-3 text-[10px] capitalize">
              <p>
                <span className="font-semibold">{doctorData?.clinic_name}</span>
                , {doctorData?.address}
              </p>
            </div>
            {!isEmpty(sanitizedTimingsHtml) ? (
              <img
                src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border.png"
                alt="line"
                className="w-full"
              />
            ) : null}
            <div className="py-3 text-[10px]">
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: sanitizedTimingsHtml }}
              />
            </div>
            <img
              src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border.png"
              alt="line"
              className="w-full"
            />
            <div className="py-3 text-[10px]">
              <p className="flex items-center gap-1 mt-0.5">
                <span>
                  <img
                    src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border-telephone.png"
                    alt="telephone"
                    className="h-2 w-2"
                  />
                </span>
                <p className="mt-0.5 text-[10px]">
                  +91-{doctorData?.clinic_number}
                </p>
              </p>
              {doctorData?.email ? (
                <p className="flex items-center gap-1 mt-0.5">
                  <span>
                    <img
                      src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ic_baseline-email.png"
                      alt="telephone"
                      className="h-3 w-3"
                    />
                  </span>
                  <p className="mt-0.5 text-[10px]">{doctorData?.email}</p>
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex flex-col items-start h-full">
            <div className="flex flex-col justify-center items-center mt-4">
              <p className="text-[8px] px-2 pt-1 rounded-tl-md rounded-tr-md text-center bg-[#000072]">
                Scan for Location
              </p>
              <div className="qr-code-container relative p-1 rounded-md border-[3px] border-[#000072] bg-[#3B82F6]">
                <div className="bg-white p-1 rounded-md">
                  <QRCodeCanvas value={googleMapsUrl} size={80} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTwo;
