// add diwali green code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif4 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
  </head>
  <body>
    <div class="container">
      <img
        class="top-side-flower"
        style="opacity: 0.26"
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/top-diwali-green-23-10-2024.png"
      />
      <img
        class="top-flower"
        style="opacity: 0.26"
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/top-middle-diwali-green-23-10-2024.png"
      />
      <div class="top-light" style="position: absolute; top: 0px">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/lights.png"
        />
      </div>
      <div>
        <div class="light-one">
          <img
            width="80"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/kandil-light-left.png"
          />
        </div>
        <div class="light-three">
          <img
            width="80"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/kandil-light-center.png"
          />
        </div>
        <div class="light-two">
          <img
            width="80"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/kandil-light-right.png"
          />
        </div>
      </div>
      <div style="position: absolute; top: 9rem" class="happy-diwali-logo">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/happy-diwali-diwali-green-23-10-2024.png"
        />
         <p
          class="text-shadow msg"
          style="
              color: #eeb70a;
              font-size: 9px;
              width: 60%;
              line-height: 11px;
              text-align: center;
          "
        >
          May the light of Diwali bring you peace, happiness, and success in all
          your endeavors
        </p>
      </div>
      <div
        style="
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          position: absolute;
          bottom: 18rem;
        "
        class="warm-regards"
      >
        Warm Regards
      </div>
      <h1
        class="dr-name"
        style="
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          position: absolute;
          bottom: 15rem;
        "
      >
         ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
      </h1>
      <p
        class="clinic-address"
        style="
          font-size: 10px;
          line-height: 14px;
          text-align: center;
          position: absolute;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          position: absolute;
          bottom: 14.5rem;
          width: 70%;
        "
      >
        ${doctorData?.clinic_name}
      </p>
      <p
        class="clinic-address"
        style="
          font-size: 10px;
          line-height: 14px;
          text-align: center;
          position: absolute;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          position: absolute;
          bottom: 12rem;
          width: 70%;
          margin-top: 8px;
        "
      >
        ${doctorData?.address}
      </p>
      <div
        style="
          position: absolute;
          bottom: 20px;
          z-index: 99999999;
          width: 100%;
          text-align: center;
        "
      ></div>
      <div style="position: absolute; width: 100%" class="bottom-flower">
        <div style="display: flex; justify-content: space-between">
          <div>
            <img
              style="opacity: 0.26"
              src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bottom-diwali-green-23-10-2024.png"
            />
          </div>
          <div
            style="
            position: absolute;
            bottom: -4rem;
            width: 17rem;
            left: 10%;
            "
          >
            <img
              style="opacity: 0.26"
              src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bottom-middle-diwali-green-23-10-2024.png"
            />
          </div>
        </div>
      </div>
      <img
        class="bottom-deep"
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bottom-deep.png"
      />
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{` 
        body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: calc(100vw - 32px);
        height: 600px;
        overflow-x: hidden;
        overflow-y: hidden;
        background: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bg.png");
        background-position: center;
        background-size: cover;
      }
      img {
        max-width: 100%;
      }
      .overflow-hidden {
        overflow: hidden;
      }
      .top-flower,
      .bottom-flower,
      .top-light,
      .happy-diwali-logo,
      .msg,
      .dr-name,
      .warm-regards,
      .clinic-address {
        visibility: hidden;
      }
      .light-one {
        position: absolute;
        left: 15px;
        animation: light-drop-two 2.5s ease-in forwards;
      }
      @keyframes light-drop-one {
        0% {
          top: -60%;
        }
        80% {
          top: 0;
        }
        100% {
          top: -2%;
        }
      }
      .light-two {
        position: absolute;
        right: 15px;
        animation: light-drop-three 3s ease-in forwards;
      }
      @keyframes light-drop-two {
        0% {
          top: -60%;
        }
        80% {
          top: 0;
        }
        100% {
          top: -2%;
        }
      }
      .light-three {
        position: absolute;
        left: 40%;
        animation: light-drop-one 2s ease-in forwards;
      }
      @keyframes light-drop-three {
        0% {
          top: -60%;
        }
        80% {
          top: 0;
        }
        100% {
          top: -2%;
        }
      }
      .top-flower,
      .top-side-flower {
        animation: moveTop 1s ease-in-out forwards;
        animation-delay: 0s;
        position: absolute;
      }
      .top-side-flower {
        width: 23rem;
      }
      .top-flower {
        width: 12rem;
      }
      .top-light {
        animation: moveLight 1s ease-in-out forwards;
        animation-delay: 0.5s;
      }
      .happy-diwali-logo {
        animation: moveDiwaliLogo 1.5s ease-in-out forwards;
        animation-delay: 3.5s;
        display: grid;
        justify-items: center;
      }
      .msg {
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .warm-regards {
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 4.5s;
      }
      .dr-name {
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 5s;
      }
      .clinic-address {
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 5.5s;
      }
      .bottom-flower {
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 6s;
      }
      .text-shadow {
        font-size: 2em;
        background: linear-gradient(90deg, white, #eeb70a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .bottom-deep {
        width: 10rem;
        height: 9rem;
        position: absolute;
        bottom: 0rem;
        z-index: 8;
        animation: showAndBlink 1.5s forwards;
        animation-delay: 0.5s;
      }
      .top-side-flower {
        position: absolute;
        top: -2rem;
      }
      @keyframes moveName {
        0% {
          transform: translateY(20%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveDiwaliLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveLight {
        0% {
          top: -60%;
        }
        100% {
          top: -2%;
          visibility: visible;
        }
      }
      @keyframes moveTop {
        from {
          top: -30%;
          transform: translateY(-100px);
        }
        to {
          top: -5%;
          visibility: visible;
          transform: translate(0px);
        }
      }
      @keyframes moveBottom {
        0% {
          bottom: -60%;
          transform: translateY(-100px);
        }
        100% {
          bottom: -2rem;
          visibility: visible;
          transform: translateY(0px);
        }
      }
      @keyframes showAndBlink {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        10% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif4;
