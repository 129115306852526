import DOMPurify from "dompurify";
import React from "react";

function Brand1({ doctorData, formLang, brandSpecData }) {
  const convertBrandSerList = () => {
    let result = "";
    if (
      doctorData?.payload[0]?.doctor_brands &&
      doctorData?.payload[0]?.doctor_brands.length > 0
    ) {
      result += `<ul class="swanky-and-moo-moo-regular">\n`;
      doctorData?.payload[0]?.doctor_brands.forEach((brand, index) => {
        result += `  <li key="${index}">${brand.brand_item_name}</li>\n`;
      });
      result += "</ul>";
    } else {
      result += "<ul>\n</ul>";
    }

    return result.trim();
  };

  console.log("brandSpecDatabrandSpecData", brandSpecData);

  const html = ` <!DOCTYPE html>
	<html lang="en">
	<head>
		<meta charset="UTF-8">
		<link href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap" rel="stylesheet">
		<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@100..900&display=swap" rel="stylesheet">
		<title>Diwali Greeting</title>
	</head>
	<body>
		<div class="container">
			<div class="bgs">
				<div style="display:flex; min-height: 80px">
				<div class="leftcols">
					<div class="logo">
						<img src=${brandSpecData?.icon_logo} />
					</div>
				</div>
				<div class="rightcols">
					<div class="subtitle">${brandSpecData?.speciality?.heading}</div>
					<div style="text-align: left; margin: 4px auto">
						<img style="vertical-align: middle;" src=${
              brandSpecData?.border_icon
            } width="220" />
					</div>
					<div class="content">${brandSpecData?.speciality?.sub_heading}</div>
				</div>
				</div>
				<div style="display:block; padding:2.35rem 0.5rem 0rem 0.5rem;position:relative;">
				<div style="position:absolute;top: -36px;left:0.5rem;width:20%"> 
					<img src=${brandSpecData?.icon_quality} />
				</div>
				<div class="drname" >Dear Dr. 
					${doctorData?.first_name + " " + doctorData?.last_name},
				</div>
				<div class="paras">Request your Support</div>
				${convertBrandSerList()}
				</div>
				<div style="position:absolute;left:0.5rem;bottom:16px;width: 58%;">
				<div class="paras">Thank You,</div>
				<div class="parasbold capitalize">${doctorData?.rep_name}</div>
				<div class="paras" style="color:${
          brandSpecData?.speciality?.dark_color
        };margin-bottom:0.5rem;margin-top:0.25rem; font-weight: 600;">+91 ${
    doctorData?.payload[0]?.rep_mobile_number
  }
				</div>
				</div>
			 <div style="display:flex; position: absolute;right:0px;bottom: 16px; max-width:126px; width:100%;    align-items: center;background-color: #EEFAFF;border-radius:16px 0 0 0;padding:2px 0px 1px 2px;">
				<div style="padding:0rem 0rem 0rem 0rem; border-radius:8px 0 0 0; width:55%;display: flex;
					align-items: center;"> 
					<img style="vertical-align: bottom;" src=${brandSpecData?.icon_logo} />
				</div>	
				
				 <div style="${
           brandSpecData?.speciality?.light_color
         }; padding:0.25rem 0.35rem 0.25rem 0rem;">
                   <div style="max-width:108px;color:#315289; font-size:7px; text-align:left; font-weight:700;line-height:8px;border-left: solid 1px #315289;padding-left: 4px;">India’s First Technology Enabled Pharma Partner</div>
                </div>
				</div>
				
				 <div style="background-color:${
           brandSpecData?.speciality?.dark_color
         };position: absolute;right:0px;bottom:0px;display: flex; justify-content: flex-end; align-items: center; width: 100%;text-align: right; font-size:5px; font-family: 'Roboto', sans-serif; color:#fff; text-transform:uppercase;line-height: 16px;font-weight: 400;
    letter-spacing:0px;" class="footertext">We are going Paperless <img style="vertical-align: middle; margin:0 6px 0 5px;" width="12" src="${
      brandSpecData?.speciality?.paperless_image
    }" /></div>
			</div>
		</div>
	</body>
	</html> `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`
	  	 body {
		font-family: "Inter", sans-serif;
		color: white;
		margin: 0;
		padding: 0;
	}

	.bgs {
		position: relative;
		margin: 0px auto;
		padding: 0px;
		width: 350px;
		height: 350px;
		border: solid 1px #ccc;
	}
	img {
		max-width: 100%;
	}

	.logo {
		background-color: #fff;
		display: inline-block;
		padding: 0.09rem 0.61rem 0rem 0.15rem;
		border-radius: 0px 0px 20px 0px;
		position: absolute;
		left: 0px;
		width: 31%;
	}

	.leftcols {
		background: url(${brandSpecData?.speciality?.background_image}) no-repeat;
		position: relative;
		background-size: cover;
		width: 65%;
	}

	.rightcols {
		border-left: solid 1px #fff;
		padding: 0.25rem 0.5rem 0.25rem 0.5rem;
		width: 35%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		background: rgb(73, 107, 161);
		background: linear-gradient(90deg, ${brandSpecData?.speciality?.dark_color} 0%, ${brandSpecData?.speciality?.light_color} 100%);
	}

	.subtitle {
		text-align: left;
		font-size: 0.65rem;
		line-height: 0.8rem;
		font-weight: 700;
		font-family: "Inter", sans-serif;
		margin-bottom: 0px;
	}

	.content {
		font-family: "Inter", sans-serif;
		text-align: left;
		font-size: 0.4rem;
		line-height: 0.5rem;
		max-width: 94px;
		margin: 0px 0px 6px 0px;
	}

	.drname {
		color:${brandSpecData?.speciality?.dark_color};
		font-size: 0.9rem;
		line-height: 0.5rem;
		font-weight: 700;
		margin-bottom: 1rem;
		text-align: left;
	}

	ul {
		padding-left: 1rem;
		font-family: "Noto Sans Georgian", sans-serif;
		margin-top: 18px;
	}

	ul li::marker {
		font-weight: bold;
	}

	ul li {
		color: #262626;
		text-align: left;
		list-style: decimal;
		font-size: 0.7rem;
		font-weight: 600;
		margin-bottom: 0.5rem;
	}

	.paras {
		color: #9CA3AF;
		text-align: left;
		font-size: 0.7rem;
		line-height: 0.25rem;
		margin-top: 0.3rem;
		margin-bottom: 0.5rem;
	}

	.parasbold {
		color:${brandSpecData?.speciality?.dark_color};
		text-align: left;
		font-size: 0.8rem;
		line-height: 0.6rem;
		font-weight: 700;
		margin-bottom: 0.50rem;
		margin-top: 0.75rem;
	} `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
}

export default Brand1;
