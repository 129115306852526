import { create } from "zustand";
import { persist } from "zustand/middleware";

export const onBoardStoreData = create(
  persist(
    (set) => ({
      doctorData: {},
      setDoctorData: (data) => set(() => ({ doctorData: data })),
      doctorImg: "",
      setDoctorImg: (data) => set(() => ({ doctorImg: data })),
      doctorTag: "Dr.",
      setDoctorTag: (data) => set(() => ({ doctorTag: data })),
      formLang: "english",
      setFormLang: (data) => set(() => ({ formLang: data })),
      selectedSpeciality: null,
      setSelectedSpeciality: (data) =>
        set(() => ({ selectedSpeciality: data })),
      specialityLangArray: null,
      setSpecialityLangArray: (data) =>
        set(() => ({ specialityLangArray: data })),
      festiveCardType: "",
      setFestiveCardType: (data) => set(() => ({ festiveCardType: data })),
      brandServiceDrop: null,
      setBrandServiceDrop: (data) => set(() => ({ brandServiceDrop: data })),
      brandSpecData: null,
      setBrandSpecData: (data) => set(() => ({ brandSpecData: data })),
      brandSpeciality: "",
      setBrandSpeciality: (data) => set(() => ({ brandSpeciality: data })),
      repDetails: "",
      setRepDetails: (data) => set(() => ({ repDetails: data })),
    }),
    {
      name: "doctor_card_data",
      getStorage: () => localStorage,
    }
  )
);
