import React from "react";

const FestiveCardOne = ({ doctorData, formLang }) => {
  return (
    <>
      <div
        className="text-white overflow-hidden"
        style={{
          backgroundImage: `url(https://d2goa5bsb5sry2.cloudfront.net/Diwali%2013.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "350px",
          width: "350px",
        }}
      >
        <div className="flex flex-col justify-center items-center w-full h-full">
          <p className="font-lora text-[8px] mt-14 mb-1">Warm Regards</p>
          <p className="font-soul text-base capitalize font-semibold">
            {formLang === "english" ? "Dr. " : "डॉ. "}
            {doctorData?.first_name + " " + doctorData?.last_name}
          </p>
          <p className="font-inter text-[10px] capitalize mt-2 font-semibold">
            {doctorData?.clinic_name}
          </p>
          <p className="font-inter text-[7px] capitalize text-center w-[40%] leading-[8px] mt-0.5">
            {doctorData?.address}
          </p>
        </div>
      </div>
    </>
  );
};

export default FestiveCardOne;
