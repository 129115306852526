import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Journal1 = ({ item, index }) => {
  const share = () => {
    if (window?.android_bridge || window?.webkit) {
      let urlLink = item?.link ?? "";
      let message = item?.title ?? "";
      let img = item?.image ?? "";
      let desc = item?.description ?? "";

      // Use WhatsApp's supported formatting for bold text
      message = `* ${message} * \n\n${desc}`;

      // If using the android_bridge, call the share method
      if (window?.android_bridge) {
        window?.android_bridge?.share(urlLink, "", message, img);
      }
    }
  };

  const shareECard = async () => {
    let link = item?.link;
    if (window?.android_bridge) {
      window.android_bridge.redirectToExternalLink(link);
    } else if (window?.webkit) {
      window.webkit.messageHandlers.ios_bridge.postMessage({
        functionName: "redirectToExternalLink",
        data: { link },
      });
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="rounded-lg shadow-xl p-3 grid gap-3 mb-2" key={index}>
      <img src={item?.image} alt="journal-img" className="rounded-lg" />
      <p className="text-black font-semibold">{item?.title}</p>
      <p className="text-black">{item?.description}</p>
      <hr />
      <div className="flex justify-between items-center">
        {item?.link && (
          <button
            className="bg-blue-400 rounded-md p-2 text-white"
            onClick={shareECard}
          >
            Click Here
          </button>
        )}
        <FaWhatsapp
          className="text-2xl text-gray-400 justify-self-end"
          onClick={share}
        />
      </div>
    </div>
  );
};

export default Journal1;
