import DOMPurify from "dompurify";
import React, { useEffect, useRef } from "react";
import { onBoardStoreData } from "../../store/onboardStore";
import { QRCodeCanvas } from "qrcode.react";

const ChemistCard1 = ({ doctorData, sanitizedTimingsHtml, setHeight }) => {
  const doctorImg = onBoardStoreData((state) => state.doctorImg);
  const googleMapsUrl = `https://www.google.com/maps?q=${doctorData?.lat},${doctorData?.long}`;
  const divRef = useRef(null);

  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };

  useEffect(() => {
    getDivHeight();
  }, [doctorData]);

  return (
    <>
      <style>
        {`
        body,
        html {
            margin: 0;
            padding: 0;
            font-family: "Roboto", sans-serif;
        }
        .image-container {
            color: #f0f0f0;
            position: relative;
            width: 100%;
            height:300px;
           background-image: url(${
             doctorData?.shop_photo ??
             "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-1.webp"
           });
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
        }
        .overlay-image {
            position: absolute;
            inset: 0;
            height: 301px;
            left: 0;
        }
        .image-header {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin: 20px;
        }
        .image-header .name {
            font-size: 14px;
            font-weight: 500;
        }
        .image-header .speciality {
            font-size: 10px;
            font-weight: 500;
        }
        .image-header .mci-number {
            font-size: 10px;
            font-weight: 500;
        }
        .location-qr-container {
            width: 50px;
            position: absolute;
            left: 52%;
            bottom: 3%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-wrap: nowrap;
            gap: 8px;
        }
        .qr-container {
            position: relative;
            width: 100%;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .corner {
            position: absolute;
            width: 5px;
            height: 8px;
            border: 1px solid #66ccff;
            /* Light blue */
        }
        .corner.top-left {
            top: -2px;
            left: -2px;
            border-right: none;
            border-bottom: none;
        }
        .corner.top-right {
            top: -2px;
            right: -2px;
            border-left: none;
            border-bottom: none;
        }
        .corner.bottom-left {
            bottom: -2px;
            left: -2px;
            border-right: none;
            border-top: none;
        }
        .corner.bottom-right {
            bottom: -2px;
            right: -2px;
            border-left: none;
            border-top: none;
        }
        /* Placeholder for the QR code */
        .qr-placeholder {
            width: 90%;
            height: 90%;
            background: white;
        }
        .qr-location-text {
            font-size: 8px;
        }
        .chemist-info {
            border-left: 1px solid;
            border-image: linear-gradient(to top, #1B3D6E, rgb(83, 165, 197)) 1;
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 8px;
            top: 68px;
            margin-top: 30px;
            margin-left: 14px;
        }
        .fa-icon-custom-color {
            color: rgb(115, 201, 223);
            background: #1B3D6E;
        }
        .fa-location-custom-style {
            position: absolute;
            top: 0;
            left: -8px;
            border: 1px solid rgb(83, 165, 197);
            font-size: 9px;
            aspect-ratio: 1;
            padding: 2px 3px;
            border-radius: 50%;
        }
        .fa-phone-custom-style {
            position: absolute;
            top: 0;
            left: -8px;
            border: 1px solid rgb(83, 174, 197);
            font-size: 8px;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 50%;
        }
        .fa-whatsapp-custom-style {
            position: absolute;
            top: 0;
            left: -8px;
            border: 1px solid rgb(83, 174, 197);
            font-size: 10px;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 50%;
        }
        .fa-clock-custom-style {
            border: 1px solid rgb(83, 174, 197);
            font-size: 9px;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 50%;
        }
        .address-container {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 6px;
        }
        .address-header {
            display: flex;
            align-items: start;
            gap: 6px;
        }
        .address-container .store-name {
            font-weight: 500;
            font-size: 12px;
        }
        .address-container .store-address {
            font-size: 8px;
        }
            `}
      </style>
      {/* <div ref={divRef}>
        <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
      </div> */}
      <div className="image-container" ref={divRef}>
        <img
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-empty.png"
          alt="Doctor Profile"
          className="overlay-image"
        />
        <div className="image-header">
          <span className="name">
            {doctorData?.first_name} {doctorData?.last_name}
          </span>
          <span className="speciality">
            {doctorData?.designation ?? "Pharmacist"}
          </span>
          <span className="mci-number">{doctorData?.mci_number ?? ""}</span>
        </div>
        <div className="chemist-info">
          <div className="address-container">
            <i className="fa-solid fa-location-dot fa-icon-custom-color fa-location-custom-style"></i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                width: "40%",
                paddingLeft: "10px",
                minHeight: "15px",
              }}
            >
              <span className="store-name">{doctorData?.clinic_name}</span>
              <span className="store-address">{doctorData?.address}</span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              gap: "6px",
              position: "relative",
              minHeight: "15px",
            }}
          >
            <i className="fa-solid fa-phone fa-icon-custom-color fa-phone-custom-style"></i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                paddingLeft: "10px",
              }}
            >
              <span style={{ fontSize: "8px" }}>
                +91 {doctorData?.clinic_number}
              </span>
              <span style={{ fontSize: "8px" }}>{doctorData?.email ?? ""}</span>
            </div>
          </div>
          {doctorData?.whatsapp_number && (
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                margin: "10px 0",
                position: "relative",
                minHeight: "25px",
              }}
            >
              <i className="fa-brands fa-whatsapp fa-icon-custom-color fa-whatsapp-custom-style"></i>
              <span
                style={{
                  fontSize: "8px",
                  position: "absolute",
                  top: "3px",
                  left: "12px",
                }}
              >
                +91 ${doctorData?.whatsapp_number}
              </span>
            </div>
          )}
          {sanitizedTimingsHtml && (
            <div
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                marginLeft: "-7px",
              }}
            >
              <i className="fa-regular fa-clock fa-icon-custom-color fa-clock-custom-style"></i>
              <div
                className="text-[10px]"
                dangerouslySetInnerHTML={{ __html: sanitizedTimingsHtml }}
              />
            </div>
          )}
        </div>
        <div className="location-qr-container">
          <div className="qr-container">
            <div className="">
              <QRCodeCanvas value={googleMapsUrl} size={45} />
            </div>
            <div className="corner top-left"></div>
            <div className="corner top-right"></div>
            <div className="corner bottom-left"></div>
            <div className="corner bottom-right"></div>
          </div>
          <span className="qr-location-text">Scan for location</span>
        </div>
      </div>
    </>
  );
};
export default ChemistCard1;
