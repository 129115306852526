import React from "react";
import { Link } from "react-router-dom";
import { ecardList } from "../config/pathConstant";
import { timeSlots } from "../store/timeSlotStore";

const CampaignCard = ({ item, key }) => {
  const setCampaignId = timeSlots((state) => state.setCampaignId);

  const campaignIcons = {
    1: "doctor.png",
    2: "festive.png",
    3: "medicine-icon.svg",
    4: "medical-days.svg",
    5: "chemist.svg",
    6: "journal.svg",
    7: "ecg.png",
  };

  return (
    <Link to={`${ecardList}/${item?.id}`} key={key}>
      <div
        onClick={() => {
          setCampaignId(item.id);
        }}
        className="rounded-md bg-blue-100 p-2 flex flex-col justify-center items-center"
      >
        <img
          src={campaignIcons[item?.id]}
          className="h-20 w-20"
          alt={item?.title}
        />
        <div className="font-semibold text-sm">{item?.title}</div>
      </div>
    </Link>
  );
};

export default CampaignCard;
