import DOMPurify from "dompurify";
import React from "react";

function FestiveCardSix({ doctorData, formLang }) {
  const html = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">

	<link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
	<link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
	<link href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap" rel="stylesheet">


    <title>Diwali Greeting</title>
</head>
<body>
<div class="container">
	<div class="bgs">
		<div class="header">Warm Regards</div>
        <div class="content-name">${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       
	</div>
</div>
</body>
</html>
`;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`body { 
	font-family: Arial, sans-serif;

	color: white;
	margin: 0;
	padding: 0;}
	
  .bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-coins-23-10-2024.jpg') no-repeat center center;
background-size: contain;margin: 0px 0;padding: 0px;width:350px; height:350px;}
.header {text-align: left;font-size:9px; line-height:9px;margin-bottom:4px; padding-top:232px;  font-family: "Lustria", serif;margin-left:34px;}
.subtitle {text-align: left;font-size: 12px;line-height:12px; font-weight:600;  font-family: "Lustria", serif; margin-bottom: 3px;margin-left:34px;}
.content-name{text-align: left;font-weight:700;font-size:16px; line-height:16px; margin:0px 0 12px 0;  font-family: "Lustria", serif;margin-left:34px;}
.content {font-family: 'Poppins', sans-serif;text-align: left;letter-spacing:0px;font-size: 8px;line-height:11px; max-width:154px; margin:0px;margin-left:34px;}
        
 `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
}

export default FestiveCardSix;
