import React from "react";
import Header from "./common/Header";
import { onBoardStoreData } from "../store/onboardStore";

const RepDetails = () => {
  const repDetails = onBoardStoreData((state) => state.repDetails);

  return (
    <>
      <Header isBack={true} />
      <div className="p-4 pt-8 mt-14 h-[calc(100vh-56px)] bg-gray-50">
        <div className="max-w-md w-full shadow-md bg-white rounded-lg p-4">
          <h1 className="text-lg font-bold text-gray-900 mb-1">Your Details</h1>
          <div className="mb-3 border-b-[1px] border-gray-400 mt-2"></div>
          <div className="mt-5">
            <div className="flex gap-3 items-center">
              <div className="bg-[#2975a4] w-16 h-16 uppercase rounded-full flex items-center justify-center text-white font-bold text-2xl">
                {repDetails?.fname?.charAt(0) + repDetails?.lname?.charAt(0) ||
                  "U"}
              </div>
              <div>
                <p className="text-gray-500">
                  Username:{" "}
                  <span className="font-medium text-base capitalize text-gray-600">
                    {repDetails?.username}
                  </span>
                </p>
                <p className="text-xl capitalize font-semibold text-gray-800">
                  {repDetails?.fname} {repDetails?.lname}
                </p>
                {repDetails?.mobile_number && (
                  <p className="text-gray-500">
                    Mobile No:{" "}
                    <span className="font-medium text-gray-600">
                      {repDetails?.mobile_number}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RepDetails;
