//guru nanak template 1 code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif10 = ({ doctorData }) => {
  const html = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Guru nank Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />

  </head>
  <body>
    <div class="container">
          <img
            class="left-light"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/leftLight.png"
          />
          <img
            class="right-light"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/rightLight.png"
          />
          <img
            class="flower-left"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/flower.png"
          />
          <img
            class="flower-right"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/flower.png"
          />
          <div class="bottom-diya">
          <img
            class="diya main-animate"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/diya.png"
          />
          <img
            class="flower-bottom"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/flower.png"
          />
          <img
            class="diya main-animate"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/diya.png"
          />
          </div>
        <div class="greeting">
          <img
            class="guru main-animate"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/guru.png"
          />
          <div
            class="main-title main-animate"
          >
            Happy
            <p>Guru Nanak Jayanti</p>
          </div>
          <div
            style="
              font-size: 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              line-height: 30px;
              width: 100%;
              text-align: center;
              color: #fff;
              margin: 12px auto 5px auto;
            "
            class="warm-regards main-animate"
          >
            Warm Regards
          </div>
          <div class="dr-name main-animate"
          >
          Dr.
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div
            class="clinic-name main-animate"
          >
           ${doctorData?.clinic_name}
          </div>
          <div
            class="clinic-address main-animate"
          >
            ${doctorData?.address}
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`      
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }
      .container {
        position: relative;
        height: 600px;
        overflow: hidden;
        background: url("https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/bg.png");
        background-position: center;
        background-size: cover;
      }
      .guru {
        height: 12rem; 
         text-align: center;
        margin: 10px auto;
      }
    .left-light, .right-light {
        height: 5rem;
    }
    .left-light {
        position: absolute;
        left: 0;
        top: 0;
    }
    .right-light {
        position: absolute;
        right: 0;
        top: 0;
    }
    .greeting {
      position: absolute; 
      top: 4rem; 
      width: 100%;
    }
    .flower-left, .flower-right, .flower-bottom {
      animation: rotate 50s infinite;
      animation-delay: 0.5s;
      transform: rotate(10deg);
    }
    .flower-left, .flower-right {
      position: absolute;
      bottom: 6rem;
      height: 10rem;
      width: 10rem;
    }
    .flower-left {
      left: -5.5rem;
    }
    .flower-right {
        right: -5.5rem;
    }
    .diya {
      height: 3rem;
    }
    .flower-bottom {
      position: absolute;
      height: 6rem;
      bottom: -4rem;
    }
    .bottom-diya {
      position: absolute;
      bottom: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
      grid-gap: 7rem;
    }
      .header,
      .main-title,
      .message,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .guru,
      .diya {
        visibility: hidden;
      }
      .main-title {
        text-align: center;
        color: #ffb900;
        font-weight: 400;
        font-family: 'Lora', serif;
        font-size: 18px;
        margin: 5px auto;
        display: grid;
        justify-items: flex-start;
        width: 50%;
      }
        .dr-name {
          font-size: 14px;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
          width: 60%;
          color: #fff;
          margin: 0 auto 15px auto;
        }
      .clinic-name {
          font-size: 14px;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          line-height: 22px;
          width: 60%;
          color: #fff;
          text-align: center;
          margin: 12px auto 0.5rem auto;
      }
      .clinic-address {
          font-size: 9px;
          font-family: 'Inter', sans-serif;
          line-height: 16px;
          text-align: center;
          width: 60%;
          color: #fff;
          margin: 0 auto;
      }
      .main-animate {
        animation: showMesssage 1s ease-out forwards;
        animation-delay: 0.5s;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes showMesssage {
        0% {
          transform: translateY(40%); /* Start 5% from the bottom */
          opacity: 0;
        }
        100% {
          transform: translateY(0); /* End at current position */
          opacity: 1;
          visibility: visible;
        }
      }
    `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};

export default FestiveGif10;
