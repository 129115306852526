import DOMPurify from "dompurify";
import React, { useEffect, useRef } from "react";
import { onBoardStoreData } from "../../store/onboardStore";
import { QRCodeCanvas } from "qrcode.react";

const ChemistCard2 = ({ doctorData, sanitizedTimingsHtml, setHeight }) => {
  const doctorImg = onBoardStoreData((state) => state.doctorImg);
  const divRef = useRef(null);

  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };

  useEffect(() => {
    getDivHeight();
  }, [doctorData]);

  return (
    <>
      <style>{`         body,
        html {
            margin: 0;
            padding: 0;           
            background-color: #f0f0f0;
            font-family: "Roboto", sans-serif;
        }
        .card-container {
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: white;
            height: 300px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        }
        .left-section .left-section-image {
            position: absolute;
            width: 180px;
            top: 20px;
            object-fit: contain;
        }
        .left-section .image-circle {
            width: 140px;
            aspect-ratio: 1;
            position: absolute;
            border-radius: 50%;
            border: 4px solid white;
            top: 70px;
            left: 30px;
        }
        .card-container .top-left-shape {
            height: 8px;
            width: 45%;
            background-color: #1b3d6e;
            border-bottom-right-radius: 25px 100%;
            position: absolute;
            top: 0;
        }
        .card-container .bottom-right-shape {
            position: absolute;
            width: 95%;
            height: 20px;
            bottom: 0;
            left: 25px;
            background-color: #1b3d6e;
            border-top-left-radius: 154px 40px;
            right: -40px;
        }
        .card-container .left-vertical-line {
            position: absolute;
            top: 5px;
            right: 10px;
            height: 90%;
            width: 2px;
            background: rgba(132, 241, 241, 0.568);
        }
        .card-container .right-section {
            color: #1b3d6e;
            width: 50%;
            margin-right: 18px;
            top: 20px;
            right: 20px;
            margin-top: -15px;
        }
        .right-section-header {
            display: flex;
            flex-direction: column;
            align-items: end;
        }
        .right-section-header .name {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            font-weight: 700;
        }
        .name .plus-image {
            width: 35px;
        }
        .right-section-header .speciality {
            font-size: 10px;
            font-weight: 500;
            padding: 2px 4px;
            border-radius: 2px;
            background: rgb(107, 223, 223);
        }
        .right-section .mci-number {
            font-size: 10px;
            font-weight: 500;
            margin-top: 10px;
            text-align: end;
        }
        .right-section .chemist-info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 15px;
        }
        .chemist-info .chemist-info-section-one {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 4px;
            font-size: 12px;
            font-weight: 600;
        }
        .chemist-info-section-one .store-address {
            font-size: 10px;
            font-weight: 400;
            width: 150px;
            text-align: end;
        }
        .chemist-info .chemist-info-section-two {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 2px;
            font-size: 10px;
        }
        .chemist-info .whatsapp {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 4px;
            font-size: 10px;
        }
        .chemist-info .timings {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 4px;
            font-size: 10px;
        }
        .main-content {
            width: 500px;
            display: flex;
            justify-content: space-between;
        }`}</style>
      <div ref={divRef} className="card-container">
        <div className="top-left-shape"></div>
        <div className="bottom-right-shape"></div>
        <div className="main-content">
          <div className="left-section">
            <img
              src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/card--circle-shape.png"
              className="left-section-image"
              alt="Card Shape"
            />
            <img
              className="image-circle"
              src={
                doctorData?.shop_photo ??
                "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-2.jpg"
              }
              alt="Circle"
            />
          </div>
          <div className="right-section">
            <div className="right-section-header">
              <div className="name">
                <img
                  src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-icon.png"
                  className="plus-image"
                  alt="Chemist Icon"
                />
                <span>
                  {doctorData?.first_name} {doctorData?.last_name}
                </span>
              </div>
              <span className="speciality">
                {doctorData?.designation ?? "Pharmacist"}
              </span>
            </div>
            <div className="mci-number">{doctorData?.mci_number ?? ""}</div>
            <div className="chemist-info">
              <div className="chemist-info-section-one">
                <span className="store-name">{doctorData?.clinic_name}</span>
                <span className="store-address">{doctorData?.address}</span>
              </div>
              <div className="chemist-info-section-two">
                <span className="mobile-number">
                  +91 {doctorData?.clinic_number}
                </span>
                <span className="email">{doctorData?.email ?? ""}</span>
              </div>
              {doctorData?.whatsapp_number && (
                <div className="whatsapp">
                  <span>+91 ${doctorData?.whatsapp_number}</span>
                  <i className="fa-brands fa-whatsapp"></i>
                </div>
              )}
              {sanitizedTimingsHtml && (
                <div
                  style={{
                    display: "flex",
                    gap: "6px",
                    alignItems: "center",
                    marginLeft: "-7px",
                  }}
                >
                  <i className="fa-regular fa-clock fa-icon-custom-color fa-clock-custom-style"></i>
                  <div
                    className="text-[10px]"
                    dangerouslySetInnerHTML={{ __html: sanitizedTimingsHtml }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="left-vertical-line"></div>
      </div>
    </>
  );
};
export default ChemistCard2;
