import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCampaignPostData, journalContent } from "../config/apiEndpoints";
import { getRequest } from "../config/axiosInterceptor";
import { chemistForm, clinic, generateECard } from "../config/pathConstant";
import Header from "./common/Header";
import Loader from "./common/Loader";
import { timeSlots } from "../store/timeSlotStore";
import { onBoardStoreData } from "../store/onboardStore";
import FestiveGif1 from "./FestiveGif/FestiveGif1";
import FestiveGif2 from "./FestiveGif/FestiveGif2";
import Journal1 from "./journals/Journal1";

const ECardList = () => {
  const [posterData, setPosterData] = useState([]);
  const [journalData, setJournalData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const setECardId = timeSlots((state) => state.setECardId);
  const { id } = useParams();
  const clearSlots = timeSlots((state) => state.clearSlots);
  const setDoctorData = onBoardStoreData((state) => state.setDoctorData);
  const campaignId = timeSlots((state) => state.campaignId);
  const setFormLang = onBoardStoreData((state) => state.setFormLang);
  const formLang = onBoardStoreData((state) => state.formLang);
  const [selectedLanguage, setSelectedLanguage] = useState(formLang);
  const [isOpen, setIsOpen] = useState(false);
  const setFestiveCardType = onBoardStoreData(
    (state) => state.setFestiveCardType
  );
  const setBrandSpeciality = onBoardStoreData(
    (state) => state.setBrandSpeciality
  );

  const doctorDetails = {
    first_name: "Nidhi",
    last_name: "Patel",
    clinic_name: "Medisage Clinics",
    address:
      "Lodha Supremus, 1403, Saki Vihar Rd, Tunga Village, Chandivali, Powai, Mumbai, Maharashtra 400072, India",
  };

  useEffect(() => {
    clearSlots();
    setDoctorData([]);
  }, []);

  useEffect(() => {
    const openGooglePermission = () => {
      if (window?.android_bridge) {
        window?.android_bridge?.deviceLocationPermission(true);
      } else if (window?.webkit) {
        window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
          functionName: "deviceLocationPermission",
          data: true,
        });
      }
    };
    openGooglePermission();
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      setLoading(true);
      try {
        const response = await getRequest({
          url: `${getCampaignPostData}/${id}?nopagination`,
        });
        const data = response?.data;
        if (data?.status) {
          setLoading(false);
          setPosterData(data?.response?.data);
        } else {
          setLoading(false);
          toast.error("Failed to fetch posters");
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    };
    fetchImage();
  }, [id]);

  useEffect(() => {
    console.log(campaignId, "campaignId");
    const fetchJournalContent = async () => {
      setJournalData([]);
      setNoData(false);
      setLoading(true);
      try {
        const response = await getRequest({
          url: `${journalContent}?type=${
            campaignId == 6 ? "journal" : campaignId == 7 ? "ecg" : ""
          }`,
        });
        const data = response?.data;
        if (data?.status) {
          setLoading(false);
          console.log(data.response);
          if (data?.response?.data?.length > 0) {
            setNoData(false);
            setJournalData(data?.response?.data);
          } else {
            setNoData(true);
            setJournalData([]);
          }
        } else {
          setNoData(false);
          setLoading(false);
          toast.error("Failed to fetch posters");
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    };
    if (campaignId == 6 || campaignId == 7) fetchJournalContent();
  }, [campaignId]);

  // Language-specific card IDs
  const languages = [
    {
      value: "english",
      label: "English",
      cardIds: [
        4, 5, 6, 9, 10, 11, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 39, 40,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
      ],
    },
    { value: "hindi", label: "Hindi", cardIds: [8, 15] },
    { value: "marathi", label: "Marathi", cardIds: [7] },
  ];

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (lang) => {
    setSelectedLanguage(lang);
    setFormLang(lang); // Persist language selection
    setIsOpen(false); // Close the dropdown after selection
  };

  // Filter poster data based on selected language
  const filteredPosterData = posterData.filter((item) => {
    const language = languages.find((lang) => lang.value === selectedLanguage);
    return language?.cardIds.includes(item?.id);
  });

  return (
    <div>
      <Header isBack={true} />
      <div className="p-4 mt-16">
        {campaignId == 1 || campaignId == 4 || campaignId == 5 ? (
          <>
            <p className="font-semibold mb-4">
              {campaignId == 1
                ? "ECard List"
                : campaignId == 4
                ? "Medical Days List"
                : campaignId == 5
                ? "Chemist List"
                : "ECard List "}
            </p>
            {!isEmpty(posterData) &&
              posterData?.map((item, index) => {
                return (
                  <Link
                    to={
                      campaignId == 5
                        ? `${chemistForm}/${item?.id}`
                        : `${clinic}/${item?.id}`
                    }
                    className="grid gap-2 mb-4"
                    key={index}
                  >
                    <img
                      onClick={() => {
                        setECardId(item?.id);
                        setFormLang("english");
                        setFestiveCardType("img");
                      }}
                      alt={item?.title}
                      src={item?.poster_image}
                      className="h-full w-full rounded-md"
                    />
                  </Link>
                );
              })}
          </>
        ) : campaignId == 2 ? (
          <>
            <p className="font-semibold mb-4">Festive List</p>
            <div className=" flex justify-between items-center py-4 text-sm text-gray-700">
              <p className="text-sm">Select language of card</p>
              {/* Dropdown UI */}
              <div className="">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className=" flex justify-between items-center bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 "
                >
                  {
                    languages.find((lang) => lang.value === selectedLanguage)
                      ?.label
                  }
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M7 10l5 5 5-5H7z"
                    />
                  </svg>
                </button>
                {isOpen && (
                  <div className="absolute mt-1 bg-white rounded-md shadow-lg z-10">
                    {languages.map((lang) => (
                      <button
                        key={lang.value}
                        onClick={() => handleSelect(lang.value)}
                        className={`block w-full text-left px-4 py-2 text-sm ${
                          selectedLanguage === lang.value
                            ? "bg-blue-500 text-white"
                            : "text-gray-900 hover:bg-blue-100"
                        }`}
                      >
                        {lang.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {!isEmpty(filteredPosterData) &&
              filteredPosterData.map((item, index) => (
                <React.Fragment key={index}>
                  <Link
                    to={`${clinic}/${item?.id}`}
                    className="grid gap-2 mb-4"
                  >
                    {item?.poster_image.includes(".mp4") ? (
                      <video
                        onClick={() => {
                          setECardId(item?.id);
                          setFestiveCardType("gif");
                          setFormLang(selectedLanguage);
                        }}
                        width="640"
                        height="360"
                        autoPlay
                        muted
                        loop
                      >
                        <source src={item?.poster_image} type="video/mp4" />
                      </video>
                    ) : (
                      <img
                        onClick={() => {
                          setECardId(item?.id);
                          setFestiveCardType("img");
                          setFormLang(selectedLanguage); // Set the selected language
                        }}
                        alt={item?.title}
                        src={item?.poster_image}
                        className="h-full w-full rounded-md"
                      />
                    )}
                  </Link>
                </React.Fragment>
              ))}
          </>
        ) : campaignId == 6 || campaignId == 7 ? (
          <>
            <p className="mb-4 font-semibold">
              {campaignId == 6 ? "Journal List" : "ECG List"}
            </p>
            {!isEmpty(journalData) ? (
              journalData?.map((item, index) => {
                return <Journal1 item={item} index={index} />;
              })
            ) : noData ? (
              <div className="grid justify-items-center items-center">
                <img src="/noData.svg" className="h-64" alt="" />
                <p className="font-semibold text-lg">No Data Found</p>
              </div>
            ) : null}
          </>
        ) : null}
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default ECardList;
