import DOMPurify from "dompurify";
import React from "react";

const FestiveCard14 = ({ doctorData, formLang }) => {
  const html = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">
    <title>Diwali Greeting</title>
</head>
<body>
<div class="container">
	<div class="bgs">
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div> 
	</div>
</div>
</body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`
      body { 
	font-family: "Oswald", sans-serif;
	color: white;
	margin: 0;
	padding: 0;}

.bgs{text-align: center;background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/03-guru-nanak-jayanti-12-11-2024-text.jpg') no-repeat center center;
background-size: contain;margin: 0px 0;padding: 0px;width:350px; height:350px;}
.content-name{text-align: left;padding-left:30px;color:#47210C; font-weight:600;font-size:13px; line-height:12px; padding:282px 0 8px 5px;    font-family: "Oswald", sans-serif;}

.subtitle {text-align: left;padding-left:10px;color:#47210C; font-size:11px;line-height:13px; font-weight:500;   font-family: "Oswald", sans-serif;serif; margin-bottom: 4px;}
.content {text-align: left;padding-left:10px;color:#47210C; font-size: 8.2px;font-family: "Oswald", sans-serif;line-height:10px; max-width:230px; margin:0px 0;}       
   
      `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};

export default FestiveCard14;
