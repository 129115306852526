import React, { useEffect, useRef } from "react";
import { timeSlots } from "../../store/timeSlotStore";
import { onBoardStoreData } from "../../store/onboardStore";
import { isEmpty } from "lodash";

const ContentThree = ({
  doctorData,
  sanitizedTimingsHtml,
  setHeight,
  doctorTag,
}) => {
  const doctorImg = onBoardStoreData((state) => state.doctorImg);
  const specialityData = timeSlots((state) => state.specialityData);
  const formLang = onBoardStoreData((state) => state.formLang);
  const cleanName =
    formLang === "english"
      ? specialityData.name.replace(/\s*\(.*?\)\s*/, "")
      : specialityData?.hindi_name;

  const divRef = useRef(null);

  // Function to get the height of the div
  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };

  useEffect(() => {
    getDivHeight();
  }, [doctorData]);

  return (
    <div
      className="grid grid-cols-[1fr,150px] m-2"
      style={{
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      }}
      ref={divRef}
    >
      <div className="p-2">
        <div className="flex items-center gap-1.5">
          <img
            src={
              specialityData?.icon
                ? specialityData?.icon
                : `https://d3typ5yqytip29.cloudfront.net/images_uploaded/pediatrician-logo.png`
            }
            alt="speciality_logo"
            className={`h-6 w-6 ${
              doctorData?.speciality_image ? "rounded-md" : "rounded-none"
            }`}
          />
          <p
            className="text-xs leading-[13px]"
            style={{ color: specialityData?.color }}
          >
            {cleanName}
          </p>
          {/* <img src={specialityData?.border_icon} alt="speciality_line" /> */}
        </div>
        <div className="flex flex-col justify-between h-auto">
          <div className="w-full py-2" style={{ color: specialityData?.color }}>
            <p className="text-xs font-semibold capitalize">
              {formLang === "english" ? "Dr. " : "डॉ. "}
              {doctorData?.first_name + " " + doctorData?.last_name}
            </p>
            <p
              className="border-[1px] rounded-lg text-[10px] mt-2 inline-flex p-1 uppercase"
              style={{ border: `1px solid ${specialityData?.color}` }}
            >
              {doctorData?.professional_degree}
            </p>
          </div>
          <div className="mt-2">
            <p
              className="text-[10px] font-medium"
              style={{ color: specialityData?.color }}
            >
              +91 - {doctorData?.clinic_number}
            </p>
            {doctorData?.email ? (
              <p
                className="text-[10px] font-medium w-[70%] break-words"
                style={{ color: specialityData?.color }}
              >
                {doctorData?.email}
              </p>
            ) : null}
          </div>
          <div className="text-gray-500 text-[10px] mt-4 capitalize">
            <p className="mb-4 w-[80%]">
              <span className="font-semibold mr-0.5 text-xs">
                {doctorData?.clinic_name},
              </span>
              <span className="text-xs">{doctorData?.address}</span>
            </p>
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: sanitizedTimingsHtml }}
            />
          </div>
        </div>
      </div>
      <div
        className="flex items-center relative"
        style={{
          backgroundImage: `url(${specialityData?.ver_background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div className="absolute -left-14 top-[32px]">
          <img
            className="h-32 w-32 rounded-full"
            style={{ border: `3px solid ${specialityData?.color}` }}
            src={
              doctorData?.profile_photo
                ? doctorData?.profile_photo
                : !isEmpty(doctorImg)
                ? doctorImg
                : "https://d3qziwubca5qnz.cloudfront.net/images_uploaded/dr-pediatrician-profile.jpg"
            }
            alt="Dr_photo"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentThree;
