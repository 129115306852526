// add laxmi mata code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif3 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link href="https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap" rel="stylesheet" />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
      rel="stylesheet"
    />
  </head>

  <body>
    <div class="container">
      <img
        class="d-border"
        style="
          width: calc(100vw - 52px);
          left: 10px;
          position: absolute;
          bottom: 10px;
        "
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-border.png"
      />
      <div
        style="
          position: absolute;
          top: 4rem;
          width: 100%;
          z-index: 9;
          display: flex;
          justify-content: center;
        "
      >
        <img
          style="width: 10rem; height: 10rem; opacity: 0.8; margin: 9rem 0 0 0"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-foot.png"
        />
        <img
          style="width: 14rem; height: 14rem"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-circle.png"
        />
      </div>
      <div
        style="
          position: absolute;
          top: 0px;
          display: grid;
          grid-template-columns: 1fr 1fr;
        "
      >
        <img
          class="flower-one"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-flwr.png"
        />
        <img
          class="flower-two"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-flwr.png"
        />
      </div>
      <div style="text-align: center; padding-top: 40px; margin: 0">
        <p
          style="
            font-family: 'Alex Brush', cursive;
            color: #fff;
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 0px;
          "
          class="happy-text"
        >
          Happy
        </p>
        <p
          style="
            font-family: 'Lora', serif;
            color: #edb45d;
            font-size: 32px;
            line-height: 33px;
            font-weight: 700;
            margin: 0;
          "
          class="diwali-text"
        >
          Diwali
        </p>
      </div>
      <div
        style="
          position: absolute;
          bottom: 7rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
        "
      >
        <div class="" style="padding: 0 0rem 1.5rem 1.5rem" class="container-2">
          <div
            style="
              font-size: 12px;
              font-family: 'Lora', serif;
              font-weight: 400;
              line-height: 18px;
              color: #fff;
              text-align: left;
              margin: 2rem 0 2rem 0;
            "
            class="greeting"
          >
            May the festival of lights illuminate your life with health,
            happiness, and success.
          </div>
          <div
            style="
              font-size: 10px;
              font-family: 'Lora', serif;
              font-weight: 300;
              line-height: 1.15rem;
              text-align: left;
              color: #fff;
              margin: 1.2rem 0 0.5rem 0;
            "
            class="warm-regards"
          >
            Warm Regards
          </div>
          <div
            style="
              font-size: 16px;
              font-family: 'Lora', serif;
              font-weight: 400;
              line-height: 1.3rem;
              text-align: left;
              color: #fff;
              margin: 0 0;
            "
            class="dr-name"
          >
              ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div
            style="
              font-size: 12px;
              font-family: 'Lora', serif;
              font-weight: 600;
              color: #fff;
              text-align: left;
              margin: 2rem 0 5px 0;
            "
            class="clinic-name"
          >
            ${doctorData?.clinic_name}
          </div>
          <div
            style="
              font-size: 9px;
              font-family: 'Lustria', serif;
              text-align: left;
              line-height: 16px;
              color: #fff;
              margin: 0 0;
            "
            class="clinic-address"
          >
            ${doctorData?.address}
          </div>
        </div>
        <div style="position: relative">
          <img
            class="laxmi-img"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-laxmi.png"
          />
          <img
            class="coin-img"
            style="width: 60px; margin: -1.5rem auto 0rem auto"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-coin.png"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }
      .container {
        position: relative;
        width: calc(100vw - 32px);
        height: 600px;
        overflow-x: hidden;
        overflow-y: hidden;
        background: #ad1429;
        background-position: center;
        background-size: cover;
      }

      .d-border,
      .flower-one,
      .flower-two,
      .greeting,
      .happy-text,
      .diwali-text,
      .greeting,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .laxmi-img,
      .coin-img {
        visibility: hidden;
      }

      .laxmi-img {
        position: absolute;
        right: -3rem;
        bottom: 4rem;
        width: 150px;
      }

      .d-border {
        animation: showBorder 1s ease-in-out forwards;
        animation-delay: 0.5s;
      }

      .flower-one {
        animation: moveFlower 1s ease-in-out forwards;
        animation-delay: 1.5s;
      }

      .flower-two {
        animation: moveFlower 1s ease-in-out forwards;
        animation-delay: 1.5s;
        transform: scaleX(-1);
      }

      .happy-text,
      .diwali-text {
        animation: moveGreetings 1s ease-out forwards;
        animation-delay: 2.5s;
      }

      .laxmi-img {
        transform: translateX(-50%);
        animation: moveLaxmiImg 1s ease-in-out forwards;
        animation-delay: 3s;
      }

      .coin-img {
        position: absolute;
        animation: moveTop 1s ease-out forwards;
        animation-delay: 3.5s;
        right: 70px;
      }

      .greeting,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address {
        animation: slideInLeft 1s ease-out forwards;
        animation-delay: 4.5s;
      }

      .container-2 {
        overflow: hidden;
      }

      @keyframes slideInLeft {
        from {
          transform: translateX(-100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }

      @keyframes moveTop {
        0% {
          bottom: -60%;
          transform: translateY(30%);
        }
        100% {
          bottom: 7%;
          transform: translateY(0%);
          visibility: visible;
        }
      }

      @keyframes moveLaxmiImg {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }

      @keyframes moveGreetings {
        0% {
          transform: translateY(-30%);
          opacity: 0;
        }
        100% {
          transform: translateY(30%);
          opacity: 1;
          visibility: visible;
        }
      }

      @keyframes moveFlower {
        0% {
          top: -30%;
          opacity: 0;
        }
        100% {
          top: 5%;
          opacity: 1;
          visibility: visible;
        }
      }

      @keyframes showBorder {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
        `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif3;
