import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useRef } from "react";
import { timeSlots } from "../../store/timeSlotStore";
import { onBoardStoreData } from "../../store/onboardStore";

const ContentOne = ({ doctorData, sanitizedTimingsHtml, setHeight }) => {
  const googleMapsUrl = `https://www.google.com/maps?q=${doctorData?.lat},${doctorData?.long}`;
  const specialityData = timeSlots((state) => state.specialityData);
  const formLang = onBoardStoreData((state) => state.formLang);
  const divRef = useRef(null);

  // Function to get the height of the div
  const getDivHeight = () => {
    if (divRef.current) {
      const divHeight = divRef.current.getBoundingClientRect().height;
      setHeight(divHeight);
    }
  };
  useEffect(() => {
    getDivHeight();
  }, [doctorData]);

  return (
    <div className="m-2 shadow-md" ref={divRef}>
      <div
        style={{
          backgroundImage: `url(${specialityData?.hor_background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "140px",
          width: "100%",
        }}
        className="relative overflow-hidden"
      >
        <img
          src="https://d3qziwubca5qnz.cloudfront.net/images_uploaded/pediatrician-mddnb-12-10-2024-bg-cove.png"
          alt="bg-cov"
          className="absolute -bottom-1"
        />
        <div className="absolute bottom-0 left-4">
          <div
            className="h-28 relative w-28 bg-white border-4 flex justify-center items-center rounded-full z-1"
            style={{ border: `3px solid ${specialityData?.color}` }}
          >
            <p
              className="absolute -top-2 bg-opacity-[0.5] text-[8px] font-semibold rounded-sm text-white py-1 px-2 "
              style={{ backgroundColor: specialityData?.color }}
            >
              Scan for Location
            </p>
            <div className="">
              <QRCodeCanvas value={googleMapsUrl} size={65} />
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 pb-5">
        <div className="text-black">
          <p className="text-lg font-semibold">
            {formLang === "english" ? "Dr. " : "डॉ. "}
            {doctorData?.first_name + " " + doctorData?.last_name}
          </p>
          <div className="text-white mt-2 text-[10px] flex justify-start items-center gap-2">
            <p
              className="px-3 py-1 rounded-lg"
              style={{ backgroundColor: specialityData?.color }}
            >
              {formLang === "english"
                ? doctorData?.speciality_name
                : doctorData?.speciality_hindi_name}
            </p>
            <p
              className="px-3 py-1 rounded-lg uppercase"
              style={{ backgroundColor: specialityData?.color }}
            >
              {doctorData?.professional_degree}
            </p>
          </div>
          <div className="grid grid-cols-2 items-start mt-4">
            <div className="border-r-[1px] pr-2 border-gray-300 border-dashed">
              <p className="text-[10px]">+91 {doctorData?.clinic_number}</p>
              {doctorData?.email && (
                <p className="text-[10px]">{doctorData?.email}</p>
              )}
              <div className="mt-3">
                <div
                  className="text-[10px]"
                  dangerouslySetInnerHTML={{ __html: sanitizedTimingsHtml }}
                />
              </div>
            </div>
            <div className="full text-[10px] pr-2 pl-5 flex flex-cols items-end capitalize">
              <p>
                <span className="font-semibold text-black">
                  {doctorData?.clinic_name}
                </span>
                , <span className="text-gray-500">{doctorData?.address}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
