// children day 1 code here

import DOMPurify from "dompurify";
import React from "react";

const ChildrenDay2 = ({ doctorData, formLang }) => {
  const html = `
<html lang="en"><head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&amp;display=swap" rel="stylesheet">
    <title>Diwali Greeting</title>
</head>
<body>
<div class="container">
    <div class="bgs">
        <div class="content-name">
        ${
          formLang === "english"
            ? `<span class="">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
          ${doctorData?.first_name + " " + doctorData?.last_name}
        </div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address} </div>
       
    </div>
</div>


  </body></html>  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`      
    body { 
    font-family: "Oswald", sans-serif;
    color: white;
    margin: 0;
    padding: 0;}
    
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/children-day-2.png') no-repeat center center;
background-size: contain;margin: 0px 0;padding: 0px;width:350px; height:350px;}
.content-name{ font-weight:600;font-size:10px; line-height:12px; padding:255px 0 8px 12px; color: #fff; font-weight:600;   font-family: "Oswald", sans-serif;}
.subtitle { font-size:10px;line-height:13px; font-weight:500;margin-top:5px;font-weight:600;   font-family: "Oswald", sans-serif;serif; margin-bottom: 4px;padding-left:12px}
.content {font-size: 9px;font-family: "Oswald", sans-serif;line-height:12px; max-width:230px;padding-left:12px;font-weight:600}       
    `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};

export default ChildrenDay2;
