import axios from "axios";
import DOMPurify from "dompurify";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { onBoardStoreData } from "../store/onboardStore";
import { timeSlots } from "../store/timeSlotStore";
import Header from "./common/Header";
import Loader from "./common/Loader";
import ContactNumberPopup from "./ContactNumberPopup";
import ContentOne from "./HTMLContent/ContentOne";
import ContentThree from "./HTMLContent/ContentThree";
import ContentTwo from "./HTMLContent/ContentTwo";
import HtmlFunction from "./HtmlFunction";
import FestiveCardOne from "./FestiveHtml/FestiveCardOne";
import FestiveCardTwo from "./FestiveHtml/FestiveCardTwo";
import FestiveCardThree from "./FestiveHtml/FestiveCardThree";
import FestiveCardFour from "./FestiveHtml/FestiveCardFour";
import FestiveCardFive from "./FestiveHtml/FestiveCardFive";
import FestiveCardSix from "./FestiveHtml/FestiveCardSix";
import FestiveCardSeven from "./FestiveHtml/FestiveCardSeven";
import FestiveCardEight from "./FestiveHtml/FestiveCardEight";
import FestiveCardNine from "./FestiveHtml/FestiveCardNine";
import FestiveCardTen from "./FestiveHtml/FestiveCardTen";
import FestiveCardEleven from "./FestiveHtml/FestiveCardEleven";
import FestiveCardTwelve from "./FestiveHtml/FestiveCardTwelve";
import { postGifRequest } from "../config/axiosInterceptor";
import {
  apiDomainPath,
  apiDomainProdPath,
  generateGif,
  generatePng,
} from "../config/apiEndpoints";
import FestiveGif1 from "./FestiveGif/FestiveGif1";
import FestiveGif2 from "./FestiveGif/FestiveGif2";
import FestiveGif3 from "./FestiveGif/FestiveGif3";
import FestiveGif4 from "./FestiveGif/FestiveGif4";
import FestiveGif5 from "./FestiveGif/FestiveGif5";
import FestiveGif6 from "./FestiveGif/FestiveGif6";
import FestiveGif7 from "./FestiveGif/FestiveGif7";
import FestiveGif8 from "./FestiveGif/FestiveGif8";
import FestiveGif9 from "./FestiveGif/FestiveGif9";
import Brand1 from "./brand/brand1";
import MedicalDays18_1 from "./MedicalDays/MedicalDays18_1";
import MedicalDays18_2 from "./MedicalDays/MedicalDays18_2";
import MedicalDays18_3 from "./MedicalDays/MedicalDays18_3";
import MedicalDays18_4 from "./MedicalDays/MedicalDays18_4";
import MedicalDays18_5 from "./MedicalDays/MedicalDays18_5";
import MedicalDays18_6 from "./MedicalDays/MedicalDays18_6";
import MedicalDays18_7 from "./MedicalDays/MedicalDays18_7";
import FestiveGif10 from "./FestiveGif/FestiveGif10";
import FestiveGif11 from "./FestiveGif/FestiveGif11";
import FestiveCard13 from "./FestiveHtml/FestiveCard13";
import FestiveCard14 from "./FestiveHtml/FestiveCard14";
import FestiveCard15 from "./FestiveHtml/FestiveCard15";
import FestiveCard16 from "./FestiveHtml/FestiveCard16";
import FestiveCard17 from "./FestiveHtml/FestiveCard17";
import ChildrenDay1 from "./FestiveGif/ChildrenDay1";
import ChildrenDay2 from "./FestiveGif/ChildrenDay2";
import ChemistCard1 from "./ChemistCards/ChemistCard1";
import ChemistCard2 from "./ChemistCards/ChemistCard2";

const websiteUrl = process.env.REACT_APP_ENV;

const GenerateECard = () => {
  const doctorData = onBoardStoreData((state) => state.doctorData);
  const doctorTag = onBoardStoreData((state) => state.doctorTag);
  const [loading, setLoading] = useState(false);
  const [bodyHtml, setBodyHtml] = useState();
  const [contentNoPopuo, setContentNoPopup] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const ecardId = timeSlots((state) => state.ecardId);
  console.log(ecardId, "ecardId");

  const [imageUrl, setImageUrl] = useState("");
  const specialityData = timeSlots((state) => state.specialityData);
  const formLang = onBoardStoreData((state) => state.formLang);
  const cleanName =
    formLang === "english"
      ? specialityData && specialityData.name
        ? specialityData.name.replace(/\s*\(.*?\)\s*/, "")
        : specialityData?.hindi_name || ""
      : "";

  const doctorImg = onBoardStoreData((state) => state.doctorImg);
  const campaignId = timeSlots((state) => state.campaignId);
  const [height, setHeight] = useState(0);
  const festiveCardType = onBoardStoreData((state) => state.festiveCardType);
  const brandSpecData = onBoardStoreData((state) => state.brandSpecData);
  const repDetails = onBoardStoreData((state) => state.repDetails);
  console.log(repDetails, "rep details");

  function formatClinicTimings(timing, specialityData) {
    const parsedTimings = JSON.parse(timing);
    const timeSlots = {};
    const dayOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Create time slots mapping days to the timings
    parsedTimings.forEach(({ in_clinic, day }) => {
      in_clinic.forEach(({ from_time, to_time }) => {
        const timeRange = `${convertTo12Hour(from_time)} - ${convertTo12Hour(
          to_time
        )}`;
        if (!timeSlots[timeRange]) {
          timeSlots[timeRange] = [];
        }
        timeSlots[timeRange].push(day);
      });
    });

    let result = "";

    // Process time slots for consecutive day ranges
    for (const [timeRange, days] of Object.entries(timeSlots)) {
      const sortedDays = days.sort(
        (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
      ); // Ensure days are in order
      let groupedDays = [];
      let currentGroup = [sortedDays[0]];

      for (let i = 1; i < sortedDays.length; i++) {
        const prevDayIndex = dayOrder.indexOf(sortedDays[i - 1]);
        const currDayIndex = dayOrder.indexOf(sortedDays[i]);

        // If the current day is consecutive to the previous day
        if (currDayIndex === prevDayIndex + 1) {
          currentGroup.push(sortedDays[i]);
        } else {
          // Push the grouped days as a range (e.g., "Mon - Wed") or individual (e.g., "Thu")
          groupedDays.push(formatDayGroup(currentGroup));
          currentGroup = [sortedDays[i]];
        }
      }

      // Push the final group of days
      groupedDays.push(formatDayGroup(currentGroup));

      const formattedDays = groupedDays.join(", ");
      result += `<p className="" style={{color: ${specialityData?.color}}}>
                    <span className="font-semibold mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px; font-weight: 600">${formattedDays}:</span>
                    <span className="mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px;">${timeRange}</span>
                 </p>\n`;
    }

    return result.trim();
  }

  // Helper function to format consecutive day ranges
  function formatDayGroup(days) {
    if (days.length === 1) {
      return days[0].slice(0, 3); // Single day (e.g., "Mon")
    }
    return `${days[0].slice(0, 3)} - ${days[days.length - 1].slice(0, 3)}`; // Consecutive days (e.g., "Mon - Wed")
  }

  function convertTo12Hour(time) {
    let [hour, minute] = time.split(":");
    hour = parseInt(hour);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${String(hour).padStart(2, "0")}:${minute} ${ampm}`;
  }

  const timing = doctorData?.timing;

  useEffect(() => {
    sessionStorage.setItem("card_height", height);
  }, [height]);

  useEffect(() => {
    if (doctorData) {
      const height = sessionStorage.getItem("card_height");
      setBodyHtml(
        HtmlFunction(
          ecardId,
          doctorData,
          specialityData,
          cleanName,
          doctorImg,
          campaignId,
          height,
          doctorTag,
          formLang,
          brandSpecData,
          repDetails
        )
      );
    }
  }, [doctorData, ecardId, specialityData, cleanName, campaignId, height]);

  const generateFestiveGif = async (type) => {
    //for dev
    // const apiEndpoint =
    //   apiDomainPath +
    //   (festiveCardType === "gif" && campaignId === 2
    //     ? generateGif
    //     : generatePng);

    //for prod
    const apiEndpoint =
      "https://generate-gif-prod.mymedisage.com" +
      (festiveCardType === "gif" && campaignId === 2
        ? generateGif
        : generatePng);

    try {
      setLoading(true);
      const response = await axios.post(
        apiEndpoint,
        {
          html: bodyHtml,
          type:
            festiveCardType === "gif"
              ? ""
              : campaignId === 1
              ? "ecard"
              : "festive_card",
          card_height: height,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);
      if (response?.status === 200) {
        console.log("Success:", response.data);

        if (type === "share") {
          if (isEmpty(mobileNumber)) {
            setContentNoPopup(true);
          } else {
            setImageUrl(response?.data?.image_url);
            setContentNoPopup(false);
          }
        } else {
          downloadCard(
            festiveCardType === "gif"
              ? response?.data?.gif_url
              : response?.data?.image_url
          );
        }
      } else {
        console.error("Unexpected status:", response.status);
      }
    } catch (error) {
      setLoading(false);
      const errorMessage = error.response ? error.response.data : error.message;
      console.error("Error:", errorMessage);
    }
  };

  const downloadCard = (imgUrl) => {
    console.log("add", imgUrl);

    // Extract file extension from imgUrl
    let fileExtension = imgUrl.split(".").pop();
    let pdf_name = `myCard.${fileExtension || "pdf"}`;
    let link = imgUrl;

    if (typeof window.android_bridge !== "undefined") {
      if (window.android_bridge?.downloadPdf) {
        window.android_bridge.downloadPdf(link, pdf_name);
        console.log("Downloading on Android");
      }
    } else if (typeof window?.webkit !== "undefined") {
      if (window?.webkit) {
        window?.webkit?.messageHandlers?.ios_bridge?.postMessage({
          functionName: "downloadPdf",
          data: {
            pdf_url: imgUrl,
            pdf_name: pdf_name,
          },
        });
        console.log("Downloading on iOS");
      }
    } else {
      if (imgUrl) {
        console.log(imgUrl, "Opening in new window");
        window.open(imgUrl, "_blank");
      }
    }
  };

  let sanitizedTimingsHtml = timing
    ? DOMPurify.sanitize(formatClinicTimings(timing))
    : "";

  const cardTitles = {
    1: "Your E-Card",
    2: "Your Festive Card",
    3: "Your Brand Card",
    4: "Medical Days Cards",
    5: "Your Chemist Card",
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const title = cardTitles[campaignId];

  return (
    <div>
      <Header isBack={true} />
      <div className="mt-16">
        <p className="font-semibold text-lg p-4">{title}</p>
        {campaignId == 1 ? (
          <div className="relative">
            {ecardId == 1 ? (
              <ContentOne
                doctorData={doctorData}
                sanitizedTimingsHtml={sanitizedTimingsHtml}
                setHeight={setHeight}
                height={height}
                doctorTag={doctorTag}
              />
            ) : ecardId == 2 ? (
              <ContentTwo
                doctorData={doctorData}
                sanitizedTimingsHtml={sanitizedTimingsHtml}
                setHeight={setHeight}
                height={height}
                doctorTag={doctorTag}
              />
            ) : ecardId == 3 ? (
              <ContentThree
                doctorData={doctorData}
                sanitizedTimingsHtml={sanitizedTimingsHtml}
                setHeight={setHeight}
                height={height}
                doctorTag={doctorTag}
              />
            ) : null}
          </div>
        ) : campaignId == 2 ? (
          <>
            <div className="flex justify-center items-center">
              {ecardId == 4 ? (
                <FestiveCardOne
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 5 ? (
                <FestiveCardTwo
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 6 ? (
                <FestiveCardThree
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 7 ? (
                <FestiveCardFour
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 8 ? (
                <FestiveCardFive
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 9 ? (
                <FestiveCardSix
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 10 ? (
                <FestiveCardSeven
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 11 ? (
                <FestiveCardEight
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 12 ? (
                <FestiveCardNine
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 13 ? (
                <FestiveCardTen
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 14 ? (
                <FestiveCardEleven
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 15 ? (
                <FestiveCardTwelve
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 16 ? (
                <FestiveGif1
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 17 ? (
                <FestiveGif2
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 18 ? (
                <FestiveGif3
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 19 ? (
                <FestiveGif4
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 20 ? (
                <FestiveGif5
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 21 ? (
                <FestiveGif6
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 22 ? (
                <FestiveGif7
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 23 ? (
                <FestiveGif8
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 41 ? (
                <FestiveCard13
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 42 ? (
                <FestiveCard14
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 43 ? (
                <FestiveCard15
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 44 ? (
                <FestiveCard16
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 45 ? (
                <FestiveCard17
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 46 ? (
                <ChildrenDay1
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 47 ? (
                <ChildrenDay2
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : null}
            </div>
            <div>
              {ecardId == 39 ? (
                <FestiveGif10
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : ecardId == 40 ? (
                <FestiveGif11
                  doctorData={doctorData}
                  doctorTag={doctorTag}
                  formLang={formLang}
                />
              ) : null}
            </div>
          </>
        ) : campaignId == 3 ? (
          <div className="flex justify-center items-center">
            <Brand1 doctorData={doctorData} brandSpecData={brandSpecData} />
          </div>
        ) : campaignId == 4 ? (
          <div className="flex justify-center items-center">
            {ecardId == 32 ? (
              <MedicalDays18_1 />
            ) : ecardId == 33 ? (
              <MedicalDays18_2 />
            ) : ecardId == 34 ? (
              <MedicalDays18_3 />
            ) : ecardId == 35 ? (
              <MedicalDays18_4 />
            ) : ecardId == 36 ? (
              <MedicalDays18_5 />
            ) : ecardId == 37 ? (
              <MedicalDays18_6 />
            ) : ecardId == 38 ? (
              <MedicalDays18_7 />
            ) : null}
          </div>
        ) : campaignId == 5 ? (
          <>
            {ecardId == 48 ? (
              <ChemistCard1
                doctorData={doctorData}
                sanitizedTimingsHtml={sanitizedTimingsHtml}
                setHeight={setHeight}
                height={height}
                doctorTag={doctorTag}
              />
            ) : ecardId == 49 ? (
              <ChemistCard2
                doctorData={doctorData}
                sanitizedTimingsHtml={sanitizedTimingsHtml}
                setHeight={setHeight}
                height={height}
                doctorTag={doctorTag}
              />
            ) : null}
          </>
        ) : null}
        <div className="p-4 text-sm">
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-md mr-2"
            onClick={() => {
              generateFestiveGif("download");
            }}
          >
            Download
          </button>
          <button
            type="button"
            className="bg-blue-500 text-white py-2 px-4 rounded-md"
            onClick={() => {
              if (isEmpty(mobileNumber)) {
                setContentNoPopup(true);
              }
            }}
          >
            Share
          </button>
        </div>
        {loading && <Loader waitMsg={true} />}
        {contentNoPopuo && (
          <ContactNumberPopup
            setMobileNumber={setMobileNumber}
            mobileNumber={mobileNumber}
            isOpen={contentNoPopuo}
            setIsOpen={setContentNoPopup}
            imageUrl={imageUrl}
          />
        )}
      </div>
    </div>
  );
};

export default GenerateECard;
