// add baby with diya code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif5 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Diwali Wishes Layout</title>
    <link href="https://cdn.example.com/canva-sans.css" rel="stylesheet" />
  </head>
  <body>
    <div class="container">
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/lantene_left.png"
        alt="Lantern"
        class="lantern-left"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/lantene_right.png"
        alt="Lantern"
        class="lantern-right"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/baby_with_diya.png"
        alt="Baby with Diya"
        class="baby-with-diya"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/diya_left.png"
        alt="Diya on Left"
        class="diya-left"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/diya_right.png"
        alt="Diya on Right"
        class="diya-right"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/best_wishes.png"
        alt="Best Wishes"
        class="best-wishes"
      />
      <div class="main-div">
        <p class="msg">
          May the festival of lights illuminate your life <br />
          with endless joy and success. Happy Diwali!
        </p>
        <p class="warm-regards clinic-animate">Warm Regards</p>
        <p class="dr-name clinic-animate">
         ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
        </p>
        <p class="clinic-name clinic-animate">${doctorData?.clinic_name}</p>
        <p class="clinic-address clinic-animate">
         ${doctorData?.address}
        </p>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{` 
        body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }

      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: calc(100vw - 32px);
        height: 600px;
        overflow-x: hidden;
        overflow-y: hidden;
        background: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/bg.png");
        background-position: center;
        background-size: cover;
      }

      .container img {
        position: absolute;
      }

      .lantern-left {
        top: 0;
        width: 8rem;
        animation: moveRight 1s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }

      .lantern-right {
        top: 0;
        width: 8rem;
        animation: moveLeft 1s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }

      .baby-with-diya {
        bottom: 5rem;
        left: 50%;
        transform: translateX(-50%);
        width: 12rem;
        animation: moveTest 4s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }

      .diya-left {
        left: -17%;
        width: 8rem;
        animation: moveTop 3s ease-in-out forwards;
      }

      .diya-right {
        right: -17%;
        width: 8rem;
        animation: moveTop 3s ease-in-out forwards;
      }

      .best-wishes {
        top: 3%;
        left: 50%;
        transform: translateX(-50%);
        width: 8rem;
        animation: wisses 5s ease-in-out;
      }

      .main-div {
        position: absolute;
        top: 22%;
        left: 50%;
        transform: translateX(-50%);
        width: 23rem;
        text-align: center;
      }

      .warm-regards {
        color: white;
        margin-top: 15px;
        margin-bottom: 0;
        font-size: 14px;
      }

      .dr-name {
        color: white;
        margin-top: 10px;
        font-size: 14px;
        text-transform: uppercase;
        width: 50%;
        text-align: center;
        margin: 5px auto 0 auto;
        line-height: 17px;
      }

      .clinic-name {
        color: white;
        margin-top: 18px;
        font-size: 16px;
        margin-bottom: 0;
      }

      .clinic-address {
        color: white;
        margin: 8px auto auto auto;
        font-size: 10px;
        line-height: 14px;
        width: 60%;
        text-align: center;
      }

      .msg {
        color: #ffff66;
        font-size: 10px;
        animation: wisses 8s ease-in-out;
      }

      .clinic-animate {
        animation: moveClinic 1s ease-out forwards;
        animation-delay: 3.1s;
        visibility: hidden;
      }

      @keyframes moveRight {
        0% {
          left: -12%;
        }

        100% {
          left: -17%;
          visibility: visible;
        }
      }

      @keyframes moveLeft {
        0% {
          right: -12%;
        }

        100% {
          right:-17%;
          visibility: visible;
        }
      }

      @keyframes moveTop {
        0% {
          bottom: -60%;
        }

        100% {
          bottom: -15%;
        }
      }

      @keyframes wisses {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 0.8;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes moveTest {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 0.8;
        }

        100% {
          opacity: 1;
          visibility: visible;
        }
      }

      @keyframes moveClinic {
        0% {
          transform: translateY(80%);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif5;
