import { Navigate } from "react-router-dom";
import { login } from "./config/apiEndpoints";

const Protected = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to={`${login}`} replace />;
  }
  return children;
};

export default Protected;
