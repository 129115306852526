//guru nanak template 2 code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif11 = ({ doctorData }) => {
  const html = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Guru nank Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />

  </head>
  <body>
    <div class="container">
          <img
            class="left-light"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower.png"
          />
          <img
            class="right-light"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower.png"
          />
          <img
            class="flower-left"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower2.png"
          />
          <img
            class="flower-right"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower2.png"
          />
        <img
            class="img-left animate-guru"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/img1.png"
          />
          <img
            class="img-right animate-guru"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/img1.png"
          />
         <div class="greeting">
          <img
            class="guru animate-guru"
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/guru.png"
          />
            <img
                class="guru-bg"
                src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower2.png"
            />
          <div
            class="main-title main-animate"
          >
            Happy
            <p>Guru Nanak Jayanti!</p>
          </div>
          <div style="margin: 15px 0">
           <div
            class="warm-regards main-animate"
          >
            Best Wishes
            </div>
                <div class="dr-name main-animate"
            >
            Dr.
                ${doctorData?.first_name + " " + doctorData?.last_name}
            </div>
          </div>
          <div>
            <div
                class="clinic-name main-animate"
            >
            ${doctorData?.clinic_name}
            </div>
            <div
                class="clinic-address main-animate"
            >
                ${doctorData?.address}
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`      
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }
      .container {
        position: relative;
        height: 600px;
        overflow: hidden;
        background: url("https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/bg.png");
        background-position: center;
        background-size: cover;
      }
      .guru {
        height: 8rem; 
         text-align: center;
        margin: 23px auto;
        z-index: 9;
      }
    .guru-bg {
        height: 12rem; 
        text-align: center;
        margin: 12px auto;
        position: absolute;
        top: -21px;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
    }
    .left-light, .right-light {
        height: 5rem;
        position: absolute;
        top: -5px;
        transform-origin: top left; /* The pivot point is the top-left corner (point A) */
        animation: swing 4s ease-in-out infinite; /* Apply the swing animation */
        animation: swing 4s ease-in-out infinite;    
    }
    .left-light {
        left: 1.5rem;
    }
    .right-light {
        right: 0;
    }
    .greeting {
      position: absolute; 
      top: 6rem; 
      width: 100%;
      display: grid;
      justify-items: center;
      grid-gap: 8px;
    }
    .flower-left, .flower-right {
      position: absolute;
      bottom: -3rem;
      height: 8rem;
      width: 8rem;
    }
    .flower-left {
      left: -4rem;
    }
    .flower-right {
        right: -4rem;
    }
    .img-left, .img-right {
        position: absolute;
        top: 12px;
        height: 4rem;
    }
    .img-left {
        left: 3rem;
    }
    .img-right {
        right: 3rem;
    }
    .diya {
      height: 3rem;
    }
    .flower-bottom {
      position: absolute;
      height: 6rem;
      bottom: -4rem;
    }
    .bottom-diya {
      position: absolute;
      bottom: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
      grid-gap: 7rem;
    }
    .warm-regards {
        font-size: 12px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        width: 100%;
        text-align: center;
        color: #fff;
        margin: 0 auto;
    }
      .header,
      .main-title,
      .message,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .guru,
      .img-right,
      .img-left,
      .diya {
        visibility: hidden;
      }
      .main-title {
        text-align: center;
        color: #fff;
        font-weight: 400;
        font-family: 'Lora', serif;
        font-size: 18px;
        margin: 5px auto 0px auto;
        width: 50%;
      }
        .dr-name {
          font-size: 16px;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
          color: #fff;
          margin: 0 auto;
        }
      .clinic-name {
          font-size: 15px;
          font-family: 'Inter', sans-serif;
          font-weight: 700;
          line-height: 22px;
          color: #fff;
          text-align: center;
          margin: 0 auto;
          width: 70%;
      }
      .clinic-address {
          font-size: 10px;
          font-family: 'Inter', sans-serif;
          line-height: 16px;
          text-align: center;
          color: #fff;
          margin: 5px auto;
          width: 70%;
      }
      .main-animate {
        animation: showMesssage 1s ease-out forwards;
        animation-delay: 0.5s;
      }
    .animate-guru {
        animation: moveLogo 1s ease-out forwards;
        animation-delay: 1s;
    }
    @keyframes moveLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          visibility: visible;
          opacity: 1;
        }
    }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes showMesssage {
        0% {
          transform: translateY(40%); 
          opacity: 0;
        }
        100% {
          transform: translateY(0); 
          opacity: 1;
          visibility: visible;
        }
      }
    @keyframes swing {
        0% {
            transform: translateX(-30%) rotate(-10deg); /* Start by swinging to the left */
        }
        50% {
            transform: translateX(-30%) rotate(10deg); /* Swing to the right */
        }
        100% {
            transform: translateX(-30%) rotate(-10deg); /* Return to the left */
        }
        }
    `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};

export default FestiveGif11;
