// add diwali mithai code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif6 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
  </head>

  <body style="margin: 0px; padding: 0px">
    <div class="container">
        <img
          class="diwali-blue-cream-mithai-tops"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-tops.png"
        />
        <div class="diwali-blue-cream-mithai-flwr-main">
          <img
            class="diwali-blue-cream-mithai-flwr-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-flwr.png"
          />
          <img
            class="diwali-blue-cream-mithai-flwr-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-flwr.png"
          />
        </div>

        <div class="diwali-blue-cream-mithai-text-main">
          <img
            class="diwali-blue-cream-mithai-luts"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-luts.png"
          />
        </div>

        <div class="diwali-blue-cream-mithai-text">
          <div class="toptext">
            Sending you loads of love, happiness, and blessings this Diwali.
            Let's celebrate the festival of lights together!
          </div>

          <img
            class="diwali-blue-cream-mithai-happy-diwali"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-happy-diwali.png"
          />
          <div class="warmregards">Warm Regards</div>
          <div class="drnames">
            ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div class="clinicname">
           ${doctorData?.clinic_name}
          </div>
          <div class="address">
           ${doctorData?.address}
          </div>
        </div>

        <div class="diwali-blue-cream-mithai-cerl">
          <img
            src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/diwali-blue-cream-mithai-flower.png"
          />
        </div>
        <div class="diwali-blue-cream-mithai-men">
          <img
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-men.png"
          />
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>
        {`
       body {
        margin: 0;
        padding: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100vw - 32px);
        height: 600px;
        position: relative;
        background-image: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-bg.png");
        background-repeat: repeat;
        background-size: contain;
        background-position: center center;
        overflow: hidden;
      }
      .diwali-blue-cream-mithai-text-main,
      .toptext,
      .diwali-blue-cream-mithai-happy-diwali,
      .warmregards,
      .drnames,
      .clinicname,
      .address,
      .diwali-blue-cream-mithai-men,
      .diwali-blue-cream-mithai-flwr-left,
      .diwali-blue-cream-mithai-flwr-right,
      .diwali-blue-cream-mithai-flwr-main,
      .diwali-blue-cream-mithai-tops {
        visibility: hidden;
      }
      .diwali-blue-cream-mithai-tops {
        width: 100%;
        position: absolute;
        top: 2px;
        z-index: 9;
        animation: moveTop 0.5s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-blue-cream-mithai-flwr-main {
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: space-between;
      }
      .diwali-blue-cream-mithai-flwr-left {
        transform: scaleX(-1);
        height: 9rem;
        animation: moveFromLeft 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .diwali-blue-cream-mithai-flwr-right {
        height: 9rem;
        animation: moveFromLeft 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .diwali-blue-cream-mithai-text-main {
        position: absolute;
        top: 68px;
        display: flex;
        justify-content: center;
        animation: moveWomen 1.5s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-blue-cream-mithai-luts {
        width: 70px;
        animation: fadeFlower 0.5s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-blue-cream-mithai-text {
        position: absolute;
        top: 144px;
        width: 100%;
      }
      .toptext {
        font-size: 12px;
        font-family: "Inter", sans-serif;
        line-height: 16px;
        text-align: center;
        width: 75%;
        color: #000;
        margin: 0 auto 0.5rem auto;
        animation: showHeader 1s ease-out forwards;
        animation-delay: 2s;
      }
      .diwali-blue-cream-mithai-happy-diwali {
        width: 240px;
        margin: 0px auto;
        display: block;
        animation: showWomen 1s ease-out forwards;
        animation-delay: 2.5s;
      }
      .warmregards {
        font-size: 14px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 30px;
        max-width: 140px;
        width: 100%;
        text-align: center;
        color: #000;
        margin: 0.5rem auto 0rem auto;
        animation: showHeader 1s ease-out forwards;
        animation-delay: 3s;
      }
      .drnames {
        font-size: 18px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
        max-width: 330px;
        width: 100%;
        color: #000;
        margin: 0 auto;
        animation: showHeader 1s ease-out forwards;
        animation-delay: 4s;
        z-index: 9
      }
      .clinicname {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 16px;
        max-width: 270px;
        width: 100%;
        color: #000;
        text-align: center;
        margin: 2rem auto 0.5rem auto;
        animation: moveTextToTop 1s ease-out forwards;
        animation-delay: 5s;
      }
      .address {
        font-size: 12px;
        font-family: "Inter", sans-serif;
        line-height: 16px;
        text-align: center;
        width: 75%;
        color: #000;
        margin: 10px auto;
        animation: moveTextToTop 1s ease-out forwards;
        animation-delay: 5s;
      }

      .diwali-blue-cream-mithai-cerl {
        position: absolute;
        bottom: 0px;
        z-index: 9;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .diwali-blue-cream-mithai-cerl img {
        width: 19rem;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .diwali-blue-cream-mithai-men {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .diwali-blue-cream-mithai-men img {
        position: absolute;
        bottom: 0px;
        z-index: 999;
        width: 13rem;
        animation: showWomen 1.5s ease-out forwards;
        animation-delay: 6s;
      }
      @keyframes moveTextToTop {
        0% {
          transform: translateY(80%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes showWomen {
        0% {
          opacity: 0;
        }
        30% {
          opacity: 0.2;
        }
        50% {
          opacity: 0.5;
        }
        75% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes fadeFlower {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes showHeader {
        0% {
          transform: translateY(-80%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveTop {
        from {
          top: -30%;
          transform: translateY(-100px);
        }
        to {
          top: 0%;
          visibility: visible;
          transform: translate(0px);
        }
      }
      @keyframes moveFromLeft {
        0% {
          left: -4%;
        }
        100% {
          left: 2%;
          visibility: visible;
        }
      }
      `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif6;
