// add green yellow traditional code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif7 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
      rel="stylesheet"
    />
  </head>
  <body>
    <div class="container">
      <div class="container-main">
        <img
          class="diwali-green-yellow-traditional-family-crcl"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-family-crcl.png"
        />

        <div class="diwali-green-yellow-traditional-top-main">
          <img
            class="diwali-green-yellow-traditional-top-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-top-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-top-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-top-right.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-happy-diwalis-main">
          <div class="diwali-green-yellow-traditional-happy-diwalis"></div>
          <img
            class="diwali-green-yellow-traditional-happy-diwalis-cer"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-happy-diwalis-cer.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-main">
          <img
            class="diwali-green-yellow-traditional-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-left.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-top-right-anim">
        Wishing you and your family a very Happy Diwali from us. 
        </div>
        <div class="contant-text">
          <div class="warmregards">Warm Regards</div>
          <div class="drname">
           ${formLang === "english" ? "Dr. " : "डॉ. "}
        ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div class="clinicname">
        ${doctorData?.clinic_name}
          </div>
          <div class="address">
                   ${doctorData?.address}
          </div>
        </div>
        <div class="diwali-green-yellow-traditional-flwr-leftmain">
          <img
            class="diwali-green-yellow-traditional-flwr-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-flwr-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-flwr-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-flwr-left.png"
          />
        </div>
        <div class="diwali-green-yellow-traditional-family-leftmains">
          <img
            class="diwali-green-yellow-traditional-family-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-family-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-flwr-bottom"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-flwr.png"
          />
          <img
            class="diwali-green-yellow-traditional-family-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-family-right.png"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>
        {`
         <style>
      body {
        margin: 0;
        padding: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-image: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #193b1e;
        background-position: center center;
        width: calc(100vw - 32px);
      }
      .container-main {
        width: calc(100vw - 32px);
        height: 600px;
        position: relative;
        overflow: hidden;
      }
      .diwali-green-yellow-traditional-family-crcl {
        width: 24rem;
        position: absolute;
        top: 5rem;
        z-index: 9;
      }
      .diwali-green-yellow-traditional-top-main {
        position: absolute;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-green-yellow-traditional-top-left {
        height: 100px;
      }
      .diwali-green-yellow-traditional-top-right {
        height: 100px;
      }
      .diwali-green-yellow-traditional-happy-diwalis-main {
        position: absolute;
        top: 2rem;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
      }
      .diwali-green-yellow-traditional-happy-diwalis {
        width: 125px;
        height: 125px;
        position: relative;
        background-image: url('https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-happy-diwalis.png');
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        background-position: center center;
        align-items: center;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .diwali-green-yellow-traditional-happy-diwalis-cer {
        width: 6rem;
        position: absolute;
        top: 25px;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-green-yellow-traditional-main {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        animation: moveLight 1s ease-in-out forwards;
        animation-delay: 1.5s;
        visibility: hidden;
      }
      .diwali-green-yellow-traditional-left {
        width: 65px;
        transform: scaleX(-1);
      }
      .diwali-green-yellow-traditional-right {
        width: 65px;
      }
      .diwali-green-yellow-traditional-top-right-anim {
        position: absolute;
        top: 10.5rem;
        z-index: 9;
        /* margin: auto; */
        text-align: center;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        left: 25%;
        animation-delay: 2s;
        width: 50%;
        font-size: 10px;
        color: white;
      }
      .contant-text {
        position: absolute;
        top: 12rem;
        width: 100%;
      }
      .warmregards {
        font-size: 10px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 16px;
        width: 75%;
        text-align: center;
        color: #fff;
        margin: 1.2rem auto 5px auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 3s;
      }
      .drname {
        font-size: 14px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 17px;
        text-align: center;
        width: 60%;
        color: #fff;
        margin: 0 auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 3s;
      }
      .clinicname {
        font-size: 12px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 17px;
        width: 65%;
        color: #fff;
        text-align: center;
        margin: 20px auto 0.5rem auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .address {
        font-size: 11px;
        font-family: "Inter", sans-serif;
        line-height: 16px;
        text-align: center;
        width: 60%;
        color: #fff;
        margin: 0 auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .diwali-green-yellow-traditional-flwr-leftmain {
        position: absolute;
        bottom: 13rem;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
      }
      .diwali-green-yellow-traditional-flwr-left {
        width: 50px;
        animation: moveRight 10s ease-in-out forwards;
      }
      .diwali-green-yellow-traditional-flwr-right {
        width: 50px;
        transform: scaleX(-1);
        animation: moveLeft 10s ease-in-out forwards;
      }
      .diwali-green-yellow-traditional-family-leftmains {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 9;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: end;
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 5.5s;
        visibility: hidden;
      }
      .diwali-green-yellow-traditional-family-left {
        width: 120px;
      }
      .diwali-green-yellow-traditional-flwr-bottom {
        width: 95px;
        object-fit: contain;
      }
      .diwali-green-yellow-traditional-family-right {
        width: 120px;
      }

      @keyframes moveBottom {
        0% {
          bottom: -200px;
        }
        100% {
          bottom: 0;
          visibility: visible;
        }
      }

      @keyframes moveLight {
        0% {
          top: -30%;
        }
        100% {
          top: 6%;
          visibility: visible;
        }
      }
      @keyframes moveDiwaliLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }

      @keyframes moveLeft {
        0% {
          transform: translateX(300px); /* Starting from the right */
        }
        100% {
          transform: translateX(-1); /* Moving back to the original position */
        }
      }

      @keyframes moveRight {
        0% {
          transform: translateX(-300px); /* Starting from the right */
        }
        100% {
          transform: translateX(1px); /* Moving back to the original position */
        }
      }
    </style>
        `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif7;
