import React from "react";

const FestiveCardTwo = ({ doctorData, formLang }) => {
  return (
    <>
      <div
        className="text-white overflow-hidden"
        style={{
          backgroundImage: `url(https://d2goa5bsb5sry2.cloudfront.net/Diwali%2015.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "350px",
          width: "350px",
        }}
      >
        <div className="flex flex-col justify-center items-center text-black pt-8 w-full h-full gap-1">
          <p className="font-lumanosimo capitalize text-sm mt-4">
            {formLang === "english" ? "Dr. " : "डॉ. "}
            {doctorData?.first_name + " " + doctorData?.last_name}
          </p>
          <p className="font-lustria capitalize text-[10px]">
            {doctorData?.clinic_name}
          </p>
          <p className="font-lustria capitalize text-[8px] text-center w-[80%]">
            {doctorData?.address}
          </p>
        </div>
      </div>
    </>
  );
};

export default FestiveCardTwo;
