// add couple code here
import DOMPurify from "dompurify";
import React from "react";

const FestiveGif2 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Diwali Wishes Layout</title>
    <link href="https://cdn.example.com/canva-sans.css" rel="stylesheet" />
  </head>
  <body>
    <div class="container">
      <div class="red-line-container-left">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="img-border img-border-1"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="img-border img-border-2"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="img-border img-border-3"
        />
      </div>
      <div class="red-line-container-right">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="img-border img-border-1"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="img-border img-border-2"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="img-border img-border-3"
        />
      </div>
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/top_border.png"
        class="top-border"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/bottom_border.png"
        class="bottom-border"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/logo.png"
        class="logo"
      />
      <p class="msg">
        May the divine light of Diwali fill your life with love, laughter and
        prosperity!.
      </p>
      <div class="main-div">
        <p class="warm-regards">Warm Regards</p>
        <p class="dr-name bold-name">
           ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
        </p>
        <p class="clinic">
          <span class="bold-name">
           ${doctorData?.clinic_name}, </span>
            ${doctorData?.address}
        </p>
      </div>
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/diya.png"
        class="diya"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/mandap.png"
        class="mandap"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/couple.png"
        class="couple"
      />
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>{`
         body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }

      .container {
        position: relative;
        width: calc(100vw - 32px);
        height: 600px;
        background: white;
        background-position: center;
        background-size: cover;
        overflow: hidden;
        display: grid;
        justify-items: center;
      }

      .red-line-container-left,
      .red-line-container-right {
        width: 2rem;
        height: 100vh;
        background-color: red;
      }

      .red-line-container-left {
        position: absolute;
        left: 0;
      }

      .red-line-container-right {
        position: absolute;
        right: 0;
      }

      .img-border {
        width: 4rem;
        height: 201px;
      }

      .top-border,
      .bottom-border {
        position: absolute;
        height: 4rem;
      }

      .bottom-border {
        z-index: 9;
      }

      .logo {
        position: absolute;
        top: 0rem;
        height: 9rem;
        width: 9rem;
        margin-left: 2rem;
        justify-self: flex-start;
      }

      .msg {
        color: #f7941c;
        font-weight: 700;
        width: 70%;
        position: absolute;
        top: 7rem;
        font-size: 12px;
        line-height: 14px;
      }

      .main-div {
        position: absolute;
        top: 27%;
        left: 3.1rem;
        width: calc(100vw - 138px);
        font-size: 12px;
      }

      .main-div p {
      line-height: 15px;
        margin: 0 0 5px 0;
      }

      .bold-name {
        font-weight: 600;
      }

      .mandap {
      position: absolute;
      height: 20rem;
      width: 15rem;
      }

      .couple {
      position: absolute;
      bottom: -2rem;
      height: 14rem;
      }

      .diya {
        position: absolute;
        bottom: 14rem;
        height: 3rem;
        width: 14rem;
      }

      .img-border,
      .top-border,
      .bottom-border,
      .main-div,
      .logo,
      .msg,
      .diya,
      .mandap,
      .couple {
        visibility: hidden;
      }

      .img-border-1,
      .img-border-2,
      .img-border-3,
      .img-border-4 {
        animation: showBorder 1s ease-in forwards;
      }

      .img-border-1 {
        animation-delay: 0.8s;
      }

      .img-border-2 {
        animation-delay: 1s;
      }

      .img-border-3 {
        animation-delay: 1.5s;
      }

      .img-border-4 {
        animation-delay: 2s;
      }

      .top-border {
        animation: showTopBorder 1s ease-in forwards;
        animation-delay: 2.5s;
      }

      .bottom-border {
        animation: showBottomBorder 1s ease-in forwards;
        animation-delay: 3s;
      }

      .logo {
        animation: moveLogo 1s ease-in-out forwards;
        animation-delay: 3.5s;
      }

      .msg {
        animation: moveLogo 1s ease-in-out forwards;
        animation-delay: 3.5s;
      }

      .main-div {
        animation: moveClinic 1s ease-out forwards;
        animation-delay: 4s;
      }

      .mandap {
        animation: showMandap 1s ease-in forwards;
        animation-delay: 4.5s;
      }

      .diya {
        animation: moveLogo 1s ease-in forwards;
        animation-delay: 5s;
      }

      .couple {
        animation: moveLogo 1s ease-in forwards;
        animation-delay: 5.5s;
      }

      @keyframes moveLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          visibility: visible;
          opacity: 1;
        }
      }

      @keyframes showTopBorder {
        0% {
          top: -30%;
        }
        100% {
          top: -1.5rem;
          visibility: visible;
        }
      }

      @keyframes showBottomBorder {
        0% {
          bottom: -30%;
        }
        100% {
          bottom: -1.5rem;
          visibility: visible;
        }
      }

      @keyframes showBorder {
        0% {
          transform: translateY(-30%);
        }
        100% {
          transform: translateY(0%);
          visibility: visible;
        }
      }

      @keyframes moveClinic {
        0% {
          transform: translateY(80%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }

      @keyframes showMandap {
        0% {
          bottom: -80%;
        }
        100% {
          bottom: -5rem;
          visibility: visible;
        }
      } 
      `}</style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif2;
