import DOMPurify from "dompurify";
import React from "react";
import { onBoardStoreData } from "../../store/onboardStore";

const MedicalDays18_2 = () => {
  const repDetails = onBoardStoreData((state) => state.repDetails);
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <title>Greeting</title>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/02-world-7-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            display: flex;
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/02-world-7-11-2024-02.jpg)
              repeat;
            position: relative;
            background-size: cover;
          "
        >
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
                +91 ${repDetails?.mobile_number}
            </div>
          </div>
        </div>
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/02-world-7-11-2024-03.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>
        {`
       body {
        font-family: "Inter", sans-serif;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 350px;
        height: 350px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 50%;
      }
      .rightcols {
        padding: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .parasbold {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        text-align: left;
        font-size: 0.62rem;
        line-height: 0.75rem;
        font-weight: 400;
        margin-top: 0.2rem;
        margin-bottom: 0.45rem;
        padding-left: 12px;
      }
      .paras {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        font-weight: 400;
        text-align: left;
        font-size: 0.62rem;
        line-height: 0.5rem;
        padding-left: 12px;
      }
        `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default MedicalDays18_2;
