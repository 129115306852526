import React from "react";

const FestiveCardFive = ({ doctorData, formLang }) => {
  return (
    <>
      <div
        className="text-white overflow-hidden"
        style={{
          backgroundImage: `url("https://d2goa5bsb5sry2.cloudfront.net/ecard-5%20(1).png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "350px",
          width: "350px",
        }}
      >
        <div className="flex flex-col justify-end items-center w-full h-full gap-1 p-7">
          <p className="font-soul text-sm capitalize">
            {formLang === "english" ? "Dr. " : "डॉ. "}
            {doctorData?.first_name + " " + doctorData?.last_name}
          </p>
          <p className="font-inter text-[10px] capitalize mt-2">
            {doctorData?.clinic_name}
          </p>
          <p className="font-inter text-[8px] text-center capitalize w-[50%]">
            {doctorData?.address}
          </p>
        </div>
      </div>
    </>
  );
};

export default FestiveCardFive;
