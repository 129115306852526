const HtmlFunction = (
  cardNo,
  doctorData,
  specialityData,
  cleanName,
  doctorImg,
  campaignId,
  height,
  doctorTag,
  formLang,
  brandSpecData,
  repDetails
) => {
  function formatClinicTimings(timing, specialityData) {
    const parsedTimings = timing && JSON.parse(timing);
    const timeSlots = {};
    const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // Create a map of time slots and days
    parsedTimings &&
      parsedTimings.forEach(({ in_clinic, day }) => {
        in_clinic.forEach(({ from_time, to_time }) => {
          const timeRange = `${convertTo12Hour(from_time)} - ${convertTo12Hour(
            to_time
          )}`;
          if (!timeSlots[timeRange]) {
            timeSlots[timeRange] = [];
          }
          timeSlots[timeRange].push(day.slice(0, 3)); // Use short form of days like Mon, Tue, etc.
        });
      });

    let result = "";

    // Iterate over the time slots and group days accordingly
    for (const [timeRange, days] of Object.entries(timeSlots)) {
      const uniqueDays = [...new Set(days)].sort(
        (a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b)
      ); // Sort the days

      let groupedDays = [];
      let currentGroup = [uniqueDays[0]];

      for (let i = 1; i < uniqueDays.length; i++) {
        const prevDayIndex = dayOrder.indexOf(uniqueDays[i - 1]);
        const currDayIndex = dayOrder.indexOf(uniqueDays[i]);

        // If days are consecutive, group them
        if (currDayIndex === prevDayIndex + 1) {
          currentGroup.push(uniqueDays[i]);
        } else {
          // Push the grouped consecutive days or single day
          groupedDays.push(formatDayGroup(currentGroup));
          currentGroup = [uniqueDays[i]];
        }
      }

      // Push the final group of consecutive days
      groupedDays.push(formatDayGroup(currentGroup));

      const formattedDays = groupedDays.join(", ");
      result += `<p className="" style={{color: ${specialityData?.color}}}>
                    <span className="font-semibold mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px; font-weight: 600">${formattedDays}:</span>
                    <span className="mb-2 text-sm" style="font-size: 10px; margin-bottom: 4px;">${timeRange}</span>
                 </p>\n`;
    }

    return result.trim();
  }

  // Helper function to format consecutive days as a range
  function formatDayGroup(days) {
    if (days.length === 1) {
      return days[0]; // Single day
    }
    return `${days[0]} - ${days[days.length - 1]}`; // Consecutive days as range
  }

  // Convert 24-hour format to 12-hour AM/PM format
  function convertTo12Hour(time) {
    let [hour, minute] = time.split(":");
    hour = parseInt(hour);
    const ampm = hour >= 12 ? "PM" : "AM";
    hour = hour % 12 || 12;
    return `${String(hour).padStart(2, "0")}:${minute} ${ampm}`;
  }

  const convertBrandSerList = () => {
    let result = "";

    if (
      doctorData?.payload?.[0]?.doctor_brands &&
      doctorData.payload[0].doctor_brands.length > 0
    ) {
      result += `<ul class="swanky-and-moo-moo-regular">\n`;
      doctorData.payload[0].doctor_brands.forEach((brand, index) => {
        result += `  <li key="${index}">${brand?.brand_item_name}</li>\n`;
      });
      result += "</ul>";
    } else {
      result += "<ul>\n  </ul>";
    }

    return result.trim();
  };

  const htmlQrImage = (lat, long) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${long}`;
    return googleMapsUrl;
  };
  const timing = doctorData?.timing;
  const htmlContent1 = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Doctor Information</title>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
        />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
        <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>
        <script src="https://cdn.tailwindcss.com"></script>
        <style type="text/css">
          body, html {
          padding: 0px;
          margin: 0px;
          overflow: hidden;
          }
          .card {
          width: 500px; 
          height: ${height ? height + 100 : "350"} 
          }
          .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
        </style>
        <script>
          window.onload = function() {
            // Generate the QR Code after the page loads
            const qrcode = new QRCode(document.getElementById("qrcode"), {
              text: "${htmlQrImage(doctorData?.lat, doctorData?.long)}",
              width: 65,
              height: 65,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          };
        </script>
    </head>
    <body class="flex justify-start items-start bg-black min-h-screen">
        <div class="card bg-white">
          <div
              style="
              background-image: url(${specialityData?.hor_background_image});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              height: 140px;
              width: 100%;
              "
              class="relative"
              >
              <img
                src="https://d3qziwubca5qnz.cloudfront.net/images_uploaded/pediatrician-mddnb-12-10-2024-bg-cove.png"
                alt="bg-cov"
                class="absolute -bottom-1"
                />
              <div class="absolute bottom-0 left-4">
                <div class="h-28 relative w-28 bg-white border-4 flex justify-center items-center rounded-full z-10"
                    style="border: 3px solid ${specialityData?.color}"
                    >
                    <p class="absolute -top-2 bg-opacity-70 text-[8px] font-semibold rounded-md text-white py-1 px-2"
                      style="background-color: ${specialityData?.color}"
                      >
                      Scan for Location
                    </p>
                    <div>
                      <div id="qrcode"></div>
                    </div>
                </div>
              </div>
          </div>
          <div class="p-3 mb-2">
              <div class="text-black pb-2">
                <p class="text-lg font-semibold capitalize devanagari">
                     ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
                </p>
                <div class="text-white mt-1 text-[10px] flex justify-start items-center gap-2">
                    <p class="px-3 py-1 rounded-lg devanagari" style="background-color: ${
                      specialityData?.color
                    }">
                      ${cleanName}
                    </p>
                    <p class="px-3 py-1 devanagari uppercase rounded-lg" style="background-color: ${
                      specialityData?.color
                    }">
                      ${doctorData?.professional_degree}
                    </p>
                </div>
                <div class="grid grid-cols-2 my-4">
                    <div class="border-r-[1px] pr-2 border-gray-300 border-dashed">
                      <p class="text-[10px]">
                          +91 ${doctorData?.clinic_number}
                      </p>
                      <p class="text-[10px] devanagari">
                          ${doctorData?.email ?? ""}
                      </p>
                      <div class="mt-3" style="font-size: 10px;">
                          ${formatClinicTimings(timing)}
                      </div>
                    </div>
                    <div class="full text-[10px] pr-2 pl-5 flex flex-col items-end capitalize">
                      <p>
                          <span class="font-semibold devanagari text-black">
                          ${doctorData?.clinic_name}
                          </span>
                          , <span class="text-gray-500 devanagari">${
                            doctorData?.address
                          }</span> 
                      </p>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </body>
  </html>
  `;

  const htmlContent2 = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Doctor Information</title>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
        <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>
        <script src="https://cdn.tailwindcss.com"></script>
        <style type="text/css">
          body, html {
          padding: 0px;
          margin: 0px;
          overflow: hidden;
          }
          .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
        </style>
        <script>
          window.onload = function() {
            // Generate the QR Code after the page loads
            const qrcode = new QRCode(document.getElementById("qrcode"), {
              text: "${htmlQrImage(doctorData?.lat, doctorData?.long)}",
              width: 80,
              height: 80,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          };
        </script>
    </head>
    <body class="flex justify-center items-center h-screen">
        <div
          class="px-3 text-white"
          style="
          background-image: url(${specialityData?.card2_background_image});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: ${height ? height : "300"}
          width: 500px;
          "
          >
          <div class="grid grid-cols-3 gap-2 h-full">
              <div class="col-span-2">
                <div class="pb-2 pt-4">
                    ${
                      doctorData?.first_name
                        ? `
                    <p class="font-semibold text-md capitalize devanagari">
                    ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
                    </p>
                    `
                        : null
                    } 
                    <p class="text-[10px] devanagari mt-1">${cleanName} 
                    <span class="uppercase devanagari">(${
                      doctorData?.professional_degree
                    })</span>
                    </p>
                </div>
                <div class="py-3">
                    <img
                      src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border.png"
                      alt="line"
                      class="w-full"
                      />
                    ${
                      doctorData?.clinic_name && doctorData?.address
                        ? `
                    <div class="py-3 text-[10px] capitalize devanagari">
                      <p>
                          <span class="font-semibold devanagari">${doctorData?.clinic_name}</span>,
                          ${doctorData?.address}
                      </p>
                    </div>
                    `
                        : null
                    }
                    ${
                      timing
                        ? `<img
                      src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border.png"
                      alt="line"
                      class="w-full mb-4"
                      />`
                        : null
                    }
                    <span style="font-size: 10px;margin-top: 5px; margin-bottom: 5px">${formatClinicTimings(
                      timing
                    )}</span>
                    ${
                      timing
                        ? `<img
                      src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border.png"
                      alt="line"
                      class="w-full mt-4"
                      />`
                        : null
                    }
                </div>
                <div>
                ${
                  doctorData?.clinic_number
                    ? `
                        <p class="flex items-center gap-2 mb-3">
                          <img
                            src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ent-12-10-2024-border-telephone.png"
                            alt="telephone"
                            class="h-2 w-2"
                          />
                          <span class="mt-0.5" style="font-size: 10px">
                            +91-${doctorData.clinic_number}
                          </span>
                        </p>
                      `
                    : ""
                }
                    ${
                      doctorData?.email
                        ? `
                        <p class="flex items-center gap-2 mb-3">
                          <img
                            src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/ic_baseline-email.png"
                            alt="email"
                            class="h-3 w-3"
                          />
                          <span class="mt-0.5 devanagari" style="font-size: 10px">
                            ${doctorData.email}
                          </span>
                        </p>
                      `
                        : ""
                    }
                </div>
              </div>
              <div class="col-span-1">
                <div class="flex flex-col items-center h-full pt-3">
                    <div class="flex flex-col justify-center items-center mt-2">
                      <p class="text-[8px] px-2 pt-1 rounded-tl-md rounded-tr-md text-center bg-[#000072]">
                          Scan for Location
                      </p>
                      <div class="qr-code-container relative p-1 rounded-md border-[3px] border-[#000072] bg-[#3B82F6]">
                          <div class="bg-white p-1 rounded-md">
                            <div id="qrcode"></div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </body>
  </html>
  `;

  const htmlContent3 = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Doctor Information</title>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
        <script src="https://cdn.tailwindcss.com"></script>
        <style type="text/css">
          body, html {
          padding: 0px;
          margin: 0px;
          overflow: hidden;
          }
          .card {
          width: 500px; 
          height: ${height ? height : "350"} 
          }
          .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
        </style>
    </head>
    <body class="flex justify-center items-center">
        <div class="grid grid-cols-[1fr,200px] card" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;">
          <div class="p-2 bg-white">
              <div class="flex items-center gap-1">
                <img
                src=${
                  specialityData?.icon ||
                  "https://d3typ5yqytip29.cloudfront.net/images_uploaded/pediatrician-logo.png"
                }
                alt="speciality_logo"
                class="h-5 w-5 devanagari ${
                  doctorData?.speciality_image ? "rounded-md" : "rounded-none"
                }"
                />
                <p class="text-[10px] devanagari leading-[13px]" style="color: ${
                  specialityData?.color
                }">
                    ${cleanName}
                </p>
              </div>
              <div class="flex flex-col justify-between h-auto">
                <div class="w-full py-2" style="color: ${
                  specialityData?.color
                }">
                    <p class="text-xs font-semibold devanagari capitalize">
                      ${formLang === "english" ? "Dr. " : "डॉ. "}
            ${doctorData?.first_name + " " + doctorData?.last_name}
                    </p>
                    <p class="border-[1px] devanagari uppercase rounded-lg text-[8px] mt-2 inline-flex p-1" style="border: 1px solid ${
                      specialityData?.color
                    }">
                      ${doctorData?.professional_degree}
                    </p>
                </div>
                <div class="mt-4">
                    <p class="text-[10px] font-medium" style="color: ${
                      specialityData?.color
                    }">
                      +91 - ${doctorData?.clinic_number}
                    </p>
                    <p class="text-xs devanagari font-medium" style="color: ${
                      specialityData?.color
                    }">
                      ${doctorData?.email ?? ""}
                    </p>
                </div>
                <div class="text-gray-500 text-xs mt-4 capitalize w-[70%]">
                    <p class="mb-8 text-xs capitalize devanagari">
                      <span class="font-semibold mr-0.5">${
                        doctorData?.clinic_name
                      }, </span>
                      <span class="">${doctorData?.address}</span>
                    </p>
                    ${formatClinicTimings(timing)}
                </div>
              </div>
          </div>
          <div
              class="flex items-center relative"
              style="
              background-image: url(${specialityData?.ver_background_image});
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
              height: 100%;
              width: 100%;
              "
              >
              <div class="absolute -left-16 top-[10%]">
                <img
                class="h-36 w-36 rounded-full"
                style="border: 3px solid ${specialityData?.color}"
                src=${
                  doctorData?.profile_photo ||
                  "https://d3qziwubca5qnz.cloudfront.net/images_uploaded/dr-pediatrician-profile.jpg"
                }
                alt="Dr_photo"
                />
              </div>
          </div>
        </div>
    </body>
  </html>
  `;

  const festiveHtmlContent1 = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
      <title>Diwali Greeting</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              text-align: center;
              color: white;
              margin: 0;
              padding: 0;
          }
              .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
          .bgs {
                background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-02-17-06-2024.jpg') no-repeat center center;
              background-size: cover;
              margin: 0px auto;padding: 0px;
                  width: 800px;
      height: 800px;
          }
          .header {
              font-size: 20px; line-height:26px;font-family: "Lora", serif;
              margin-bottom:0px; padding-top:360px;
          }
          .subtitle {font-family: "Lora", serif;
              font-size: 28px;line-height:30px; font-weight:500;
              margin-bottom: 10px;
          }
      .content-name{font-weight:500;font-size:40px; line-height:58px; margin:15px 0 40px 0;font-family: "Lora", serif;}
          .content {
              font-size: 16px;
              line-height: 24px;
              max-width: 50%;
              margin: 0 auto;
          }
      </style>
  </head>
  <body>
      <div class="container">
    <div class="bgs">
      <div class="header">Warm Regards</div>
          <div class="content-name">${
            formLang === "english"
              ? `<span class="content-name">Dr. </span>`
              : `<span class="devanagari">डॉ. </span>`
          }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
          <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
          <div class="content devanagari">${doctorData?.address}</div>
         </div>
      </div>
  </body>
  </html>  
  `;

  const festiveHtmlContent2 = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
    <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Lumanosimo&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
      <title>Diwali Greeting</title>
    <style>
    body {font-family: Arial, sans-serif;text-align: center;color: white;margin: 0;padding: 0;}
    .bgs {background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-ganpathi-17-10-2024.jpg') no-repeat center center;background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height:800px;}
    .subtitle {color:#2E2E2E;font-size: 28px;line-height:38px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 4px;}
    .content-name{color:#2E2E2E;  font-family: "Lumanosimo", cursive;font-style:normal;
  font-weight:500;font-size:42px; line-height:66px; padding:420px 0 20px 0;  }
    .content {color:#2E2E2E;font-size: 20px;font-family: "Lustria", serif;line-height: 32px; max-width:70%; margin:0 auto;}
        .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
    </style>
  </head>
  <body>
      <div class="container">
    <div class="bgs">
         
          <div class="content-name">${
            formLang === "english"
              ? `<span class="content-name">Dr. </span>`
              : `<span class="devanagari">डॉ. </span>`
          }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
          <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
          <div class="content devanagari">${doctorData?.address}</div>
         </div>
      </div>
  </body>
  </html>
`;

  const festiveHtmlContent3 = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
    <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
      <title>Diwali Greeting</title>
      <style>
          body {
            
              font-family: Arial, sans-serif;
              text-align: center;
              color: white;
              margin: 0;
              padding: 0;
          }.devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
          .bgs {
              
                background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-laxmi-17-10-2024.jpg') no-repeat center center;
              background-size: cover;
              margin: 0px auto;padding: 0px;
                  width: 800px;
      height: 800px;
          }
          .header {
              font-size:20px; line-height:24px;
              margin-bottom:10px; padding-top:460px;  font-family: "Lustria", serif;
  
          }
          .subtitle {
              font-size: 26px;line-height:30px; font-weight:500;  font-family: "Lustria", serif;
              margin-bottom: 8px;
          }
      .content-name{font-weight:500;font-size:36px; line-height:42px; margin:0px 0 40px 0;  font-family: "Lustria", serif;
  }
          .content {
              font-size: 18px;font-family: "Lustria", serif;
              line-height:26px;
              max-width: 50%;
              margin: 0 auto;
          }
          
      </style>
  </head>
  <body>
      <div class="container">
    <div class="bgs">
      <div class="header">Warm Regards</div>
          <div class="content-name">${
            formLang === "english"
              ? `<span class="content-name">Dr. </span>`
              : `<span class="devanagari">डॉ. </span>`
          }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
          <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
          <div class="content devanagari">${doctorData?.address}</div>
         </div>
      </div>
  </body>
  </html>  
`;

  const festiveHtmlContent4 = `
<!DOCTYPE html>
<html lang="hi">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <!-- Google Fonts for English and Devanagari scripts (Hindi/Marathi) -->
    <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Lumanosimo&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">

    <title>Diwali Greeting</title>
    <style>
        body {
            text-align: center;
            color: white;
            margin: 0;
            padding: 0;
        }
        .bgs {
            background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-ganpathi-21-10-2024.jpg') no-repeat center center;
            background-size: cover;
            margin: 0 auto;
            padding: 0;
            width: 800px;
            height: 800px;
        }
        .subtitle {
            color: #2E2E2E;
            font-size: 28px;
            line-height: 38px;
            font-weight: 500;
            margin-bottom: 4px;
        }
        .content-name {
            color: #2E2E2E;
            font-weight: 500;
            font-size: 42px;
            line-height: 66px;
            padding: 420px 0 20px 0;
        }
        .content {
            color: #2E2E2E;
            font-size: 20px;
            line-height: 32px;
            max-width: 70%;
            margin: 0 auto;
        }
        .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="bgs">
            <div class="content-name devanagari">${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
            <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
            <div class="content devanagari">${doctorData?.address}</div>
        </div>
    </div>
</body>
</html>

`;

  const festiveHtmlContent5 = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Diwali Greeting</title>

    <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">

    <style>
        body {
            font-family: Arial, sans-serif;
            text-align: center;
            color: white;
            margin: 0;
            padding: 0;
        }
        .bgs {
            background: url('https://d2goa5bsb5sry2.cloudfront.net/ecard-5%20(1).png') no-repeat center center;
            background-size: cover;
            margin: 0 auto;
            padding: 0;
            width: 800px;
            height: 800px;
        }
        .header {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 10px;
            padding-top: 480px;
            
        }
        .subtitle {
            font-size: 26px;
            line-height: 30px;
            font-weight: 500;
            
            margin-bottom: 8px;
        }
        .content-name {
            font-weight: 500;
            font-size: 36px;
            line-height: 42px;
            margin: 0 0 40px;
            
        }
        .content {
            font-size: 18px;
            
            line-height: 26px;
            max-width: 50%;
            margin: 0 auto;
        }
        .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="bgs">
            <div class="header"></div>
            <div class="content-name devanagari">${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
            <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
            <div class="content devanagari">${doctorData?.address}</div>
        </div>
    </div>
</body>
</html>

  `;

  const festiveHtmlContent6 = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
        <title>Diwali Greeting</title>
        <style>
          body { 
          font-family: Arial, sans-serif;
          text-align: center;
          color: white;
          margin: 0;
          padding: 0;}
          .devanagari {
          font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
          }
          .bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-coins-23-10-2024.jpg') no-repeat center center;
          background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height: 800px;}
          .header {text-align: left;font-size:18px; line-height:22px;margin-bottom:10px; padding-top:540px;  font-family: "Lustria", serif;margin-left:80px;}
          .subtitle {text-align: left;font-size: 22px;line-height:26px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 10px;margin-left:80px;}
          .content-name{text-align: left;font-weight:700;font-size:36px; line-height:42px; margin:0px 0 40px 0;  font-family: "Lustria", serif;margin-left:80px;}
          .content {font-family: 'Poppins', sans-serif;text-align: left;letter-spacing:0.5px;font-size: 14px;line-height:20px; max-width:400px; margin:0px;margin-left:80px;}
        </style>
    </head>
    <body>
        <div class="container">
          <div class="bgs">
              <div class="header">Warm Regards</div>
              <div class="content-name">${
                formLang === "english"
                  ? `<span class="">Dr. </span>`
                  : `<span class="devanagari">डॉ. </span>`
              }
                ${doctorData?.first_name + " " + doctorData?.last_name}
              </div>
              <div class="subtitle">${doctorData?.clinic_name}</div>
              <div class="content">${doctorData?.address}</div>
          </div>
        </div>
    </body>
  </html>
  `;

  const festiveHtmlContent7 = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap" rel="stylesheet">
        <title>Diwali Greeting</title>
        <style>
          body { 
          font-family: Arial, sans-serif;
          text-align: center;
          color: white;
          margin: 0;
          padding: 0;}
          .devanagari {
          font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
          }
          .bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-pink-23-10-2024.jpg') no-repeat center center;
          background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height: 800px;}
          .header {font-size:18px; line-height:22px;margin-bottom:6px; padding-top:220px;  font-family: "Lustria", serif;}
          .subtitle {font-size: 22px;line-height:26px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 6px;}
          .content-name{font-weight:700;font-size:36px; line-height:42px; margin:0px 0 30px 0;  font-family: "Lustria", serif;}
          .content {font-size: 14px;font-family: 'Poppins', sans-serif;line-height:20px; max-width:400px; margin:0px auto;}
        </style>
    </head>
    <body>
        <div class="container">
          <div class="bgs">
              <div class="header">Warm Regards</div>
              <div class="content-name">${
                formLang === "english"
                  ? `<span class="content-name">Dr. </span>`
                  : `<span class="devanagari">डॉ. </span>`
              }
                ${doctorData?.first_name + " " + doctorData?.last_name}
              </div>
              <div class="subtitle">${doctorData?.clinic_name}</div>
              <div class="content">${doctorData?.address}</div>
          </div>
        </div>
    </body>
  </html>
  `;

  const festiveHtmlContent8 = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
        <title>Diwali Greeting</title>
        <style>
          body { 
          font-family: Arial, sans-serif;
          text-align: center;
          color: white;
          margin: 0;
          padding: 0;}
          .devanagari {
          font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
          }
          .bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/sweet-diwali-23-10-2024.jpg') no-repeat center center;
          background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height: 800px;}
          .header {font-size:18px; line-height:22px;margin-bottom:6px; padding-top:340px;  font-family: "Lustria", serif;}
          .subtitle {font-size: 22px;line-height:26px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 6px;}
          .content-name{font-weight:700;font-size:36px; line-height:42px; margin:0px 0 30px 0;  font-family: "Lustria", serif;}
          .content {font-size: 16px;font-family: "Lustria", serif;line-height:20px; max-width:500px; margin:0px auto;}
        </style>
    </head>
    <body>
        <div class="container">
          <div class="bgs">
              <div class="header">Warm Regards</div>
              <div class="content-name">${
                formLang === "english"
                  ? `<span class="content-name">Dr. </span>`
                  : `<span class="devanagari">डॉ. </span>`
              }
                ${doctorData?.first_name + " " + doctorData?.last_name}
              </div>
              <div class="subtitle">${doctorData?.clinic_name}</div>
              <div class="content">${doctorData?.address}</div>
          </div>
        </div>
    </body>
  </html>
  `;

  const festiveHtmlContent9 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
	<link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
	<link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">

	<link href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap" rel="stylesheet">
    <title>Diwali Greeting</title>
    <style>
body { 
	font-family: Arial, sans-serif;
	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
		.devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-laxmi-23-10-2024.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height: 800px;}
.header {font-size:18px; line-height:22px;margin-bottom:6px; padding-top:230px;  font-family: "Lustria", serif;}
.subtitle {font-size: 22px;line-height:26px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 6px;}
.content-name{font-weight:700;font-size:36px; line-height:42px; margin:0px 0 30px 0;  font-family: "Lustria", serif;}
.content {font-size: 14px;font-family: 'Poppins', sans-serif;line-height:20px; max-width:400px; margin:0px auto;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
		<div class="header">Warm Regards</div>
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>
  `;

  const festiveHtmlContent10 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
	<link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
	<link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap" rel="stylesheet">

    <title>Diwali Greeting</title>
    <style>
body { 
	font-family: Arial, sans-serif;
	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
		.devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-dr-23-10-2024.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height: 800px;}
.header {font-size:18px; line-height:22px;margin-bottom:6px; padding-top:526px;  font-family: "Lustria", serif; text-align:left;margin-left:50px;}
.subtitle {font-size: 22px;line-height:26px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 8px;text-align:left;margin-left:50px;}
.content-name{font-weight:700;font-size:36px;line-height:42px;margin:0px 0 36px 0;font-family:"Lustria", serif;text-align:left;margin-left:50px;}
.content {font-size: 14px;font-family: 'Poppins', sans-serif;line-height:20px; max-width:294px; margin:0px 0;text-align:left; margin-left:50px;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
		<div class="header">Warm Regards</div>
        <div class="content-name">${
          formLang === "english"
            ? `<span class="">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>

  `;
  const festiveHtmlContent11 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
	<link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap" rel="stylesheet">
	<link href="https://fonts.googleapis.com/css2?family=Lustria&display=swap" rel="stylesheet">

<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">
    <title>Diwali Greeting</title>
    <style>
body { 
	font-family: Arial, sans-serif;
	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
		.devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/sweet-diwali-orange-23-10-2024.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 800px;height: 800px;}
.header {font-size:18px; line-height:22px;margin-bottom:10px; padding-top:380px;  font-family: "Lustria", serif;}
.subtitle {font-size: 22px;line-height:26px; font-weight:500;  font-family: "Lustria", serif; margin-bottom: 10px;}
.content-name{font-weight:700;font-size:36px; line-height:42px; margin:0px 0 36px 0;  font-family: "Lustria", serif;}
.content {font-size: 14px;font-family: "Lustria", serif;line-height:20px; max-width:294px; margin:0px auto;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
		<div class="header">Warm Regards</div>
        <div class="content-name devanagari">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
        <div class="content devanagari">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>

  `;
  const festiveHtmlContent12 = `
 <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>Diwali Greeting</title>

    <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+Devanagari:wght@400;700&family=Noto+Serif+Devanagari:wght@400;700&display=swap" rel="stylesheet">

    <style>
        body { 
          
            text-align: center;
            color: #511146;
            margin: 0;
            padding: 0;
        }
        .devanagari {
            font-family: 'Noto Sans Devanagari', 'Noto Serif Devanagari', sans-serif;
        }
        .bgs {
            background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/diwali-laxmi-25-10-2024.jpg') no-repeat center center;
            background-size: contain;
            margin: 0 auto;
            padding: 0;
            width: 800px;
            height: 800px;
        }
        .header {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 6px;
            padding-top: 260px;
        }
        .subtitle {
            font-size: 22px;
            line-height: 26px;
            font-weight: 500;
        
            margin-bottom: 6px;
        }
        .content-name {
            font-weight: 700;
            font-size: 36px;
            line-height: 42px;
            margin: 0 0 30px;
            
        }
        .content {
            font-size: 14px;
            
            line-height: 20px;
            max-width: 400px;
            margin: 0 auto;
        }
    </style>
</head>
<body>
    <div class="container">
        <div class="bgs">
            <div class="header devanagari">सादर शुभकामनाएँ</div>
            <div class="content-name devanagari">${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
            <div class="subtitle devanagari">${doctorData?.clinic_name}</div>
            <div class="content devanagari">${doctorData?.address}</div>
        </div>
    </div>
</body>
</html>

  `;

  //elephant gif
  const festiveGifContent1 = `
   <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400..700&amp;display=swap" rel="stylesheet">
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />
    <style>
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/bg.png");
        background-position: center;
        background-size: cover;
      }
      .container {
        position: relative;
        width: 100vw;
        height: 100vh;
      }
      .elephant-left {
        position: absolute;
        left: -6rem;
        bottom: 10rem;
      }
      .elephant-right {
        position: absolute;
        right: -6rem;
        bottom: 10rem;
      }
      .header,
      .main-title,
      .message,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .elephant-left,
      .elephant-right,
      .diya {
        visibility: hidden;
      }
      .border {
        animation: showAndBlink 1s forwards;
        animation-delay: 0.5s;
        height: calc(100vh - 6px);
      }
      .header {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 1.5s;
      }
      .main-title {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 2.5s;
      }
      .message {
        animation: showMesssage 1s ease-out forwards;
        animation-delay: 3.5s;
      }
      .warm-regards {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 4.5s;
      }
      .dr-name,
      .clinic-name,
      .clinic-address {
        animation: showHeader 1s ease-out forwards;
        animation-delay: 5s;
      }
      .elephant-left {
        animation: moveRight 1s ease-in-out forwards;
        animation-delay: 6s;
      }
      .elephant-right {
        animation: moveLeft 1s ease-in-out forwards;
        animation-delay: 7s;
      }
      .diya {
        animation: moveTop 1s ease-in-out forwards;
        animation-delay: 7.5s;
      }
      .flower {
        animation: rotate 80s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .diya-light {
        animation: shake-light infinite;
        animation-delay: 9s;
        transform: rotate(5deg);
      }
      @keyframes shake-light {
        0% {
          transform: rotate(0deg);
        }
        20% {
          transform: rotate(5deg);
        }
        40% {
          transform: rotate(0deg);
        }
        60% {
          transform: rotate(-5deg);
        }
        80% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(5deg);
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes moveTop {
        0% {
          transform: translateY(80%);
          /* bottom: -30%; */
        }
        100% {
          /* bottom: 10%; */
          transform: translateY(0%);
          visibility: visible;
        }
      }
      @keyframes showAndBlink {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        10% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @keyframes showHeader {
        0% {
          transform: translateY(-80%); /* Start 5% from the bottom */
          opacity: 0;
        }
        100% {
          transform: translateY(0); /* End at current position */
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes showMesssage {
        0% {
          transform: translateY(80%); /* Start 5% from the bottom */
          opacity: 0;
        }
        100% {
          transform: translateY(0); /* End at current position */
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveRight {
        0% {
          left: -30%;
        }
        100% {
          left: -20%;
          visibility: visible;
        }
      }
      @keyframes moveLeft {
        0% {
          right: -30%;
        }
        100% {
          right: -20%;
          visibility: visible;
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgblue">
        <div style="position: absolute; top: 0px; width: 100%; z-index: 9">
          <img
            class="border"
            style="width: 100%;"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-border.png"
          />
        </div>
        <div style="position: absolute; top: 0px; width: 100%; z-index: 9">
          <div
            class="header"
            style="
              
              text-align: center;
              color: #fff;
              text-transform: uppercase;
              margin-top: 3.7rem;
              letter-spacing: 1px;
              font-weight: 700;
              font-family: 'Poppins', sans-serif;
              font-size: 22px;
            
            "
          >
            HAPPY AND PROSPEROUS
          </div>
          <div
            class="main-title"
            style="
              text-align: center;
              color: #ffb900;
              font-weight: 400;
              font-family: 'Lora', serif;
              font-size: 70px;
            "
          >
            Diwali
          </div>
          <div
            class="message"
            style="
              text-align: center;
              color: #fff;
              font-weight: 300;
              font-family: 'Poppins', sans-serif;
              font-size: 20px;
              line-height: 1.75rem;
              max-width: 398px;
              margin: 10px auto;  
            "
          >
            May the festival of lights fill your life with happiness and
            prosperity
          </div>
          <div
            style="
              font-size: 18px;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              line-height: 30px;
              max-width: 140px;
              width: 100%;
              text-align: center;
              color: #fff;
              margin: 1.2rem auto 1rem auto;
            "
            class="warm-regards"
          >
            Warm Regards
          </div>
          <div
            style="
              font-size: 30px;
              font-family: 'Inter', sans-serif;
              font-weight: 700;
              line-height: 32px;
              text-align: center;
              max-width: 330px;
              width: 100%;
              color: #fff;
              margin: 0 auto;
            "
            class="dr-name"
          >
            ${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div
            style="
              font-size: 24px;
              font-family: 'Inter', sans-serif;
              font-weight: 700;
              line-height: 24px;
              max-width: 270px;
              width: 100%;
              color: #fff;
              text-align: center;
              margin: 2rem auto 0.6rem auto;
            "
            class="clinic-name"
          >
            ${doctorData?.clinic_name}
          </div>
          <div
            style="
              font-size: 22px;
              font-family: 'Inter', sans-serif;
              line-height: 1.5rem;
              text-align: center;
              width: 70%;
              color: #fff;
              margin: 0 auto;
            "
            class="clinic-address"
          >
           ${doctorData?.address}
          </div>
        </div>
        <img
          class="elephant-left"
          width="300"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-left.png"
        />
        <img
          class="elephant-right"
          width="300"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-right.png"
        />
        <div
          style="
            position: absolute;
            bottom: -11rem;
            width: 100%;
            z-index: 9;
            display: flex;
            justify-content: center;
          "
          class="rangoli"
        >
          <img
            width="440"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-rangoli.png"
            class="flower"
          />
          <div
            style="
              margin: 0px auto;
              width: 220px;
              position: absolute;
              bottom: 108px;
              z-index: 99;
            "
          >
            <div
              style="
                height: 220px;
                width: 220px;
                background: #340068;
                border-radius: 800px;
              "
            >
              <div
                class="diya"
                style="width: 150px; margin: 0px auto 0 auto; padding-top: 26px"
              >
                <img
                  width="150"
                  src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-diya-s.png"
                  class="diya-light"
                />
                <img
                  width="150"
                  src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-elephant/diwali-elephant-diya.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>

  `;

  //couple gif
  const festiveGifContent2 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Diwali Wishes Layout</title>
    <link href="https://cdn.example.com/canva-sans.css" rel="stylesheet" />
    <style>
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        background: white;
        background-position: center;
        background-size: cover;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100vw;
        height: 100vh;
      }
      .red-line-container-left,
      .red-line-container-right {
        width: 4rem;
        height: 100vh;
        background-color: red;
      }
      .red-line-container-left {
        position: absolute;
        left: 0;
      }
      .red-line-container-right {
        position: absolute;
        right: 0;
      }
      .border {
        width: 4rem;
        height: 35vh;
      }
      .top-border,
      .bottom-border {
        position: absolute;
        height: 9rem;
      }
      .top-border {
        top: -4rem;
      }
      .bottom-border {
        bottom: -4rem;
        z-index: 9;
      }
      .logo {
        position: absolute;
        top: 0rem;
        height: 15.5rem;
        width: 15.5rem;
        left: 4rem;
      }
      .msg {
        color: #f7941c;
        font-weight: 700;
        width: 50%;
        position: absolute;
        top: 12.2rem;
        font-size: 16px;
        left: 6rem;
      }
      .main-div {
        position: absolute;
        top: 30%;
        left: 6rem;
        width: 70%;
      }
      .main-div p {
        margin: 0 0 5px 0;
      }
      .bold-name {
        font-weight: 600;
      }
      .mandap {
        position: absolute;
        bottom: -5rem;
        height: 29rem;
      }
      .couple {
        position: absolute;
        bottom: -4rem;
        height: 24rem;
      }
      .diya {
        position: absolute;
        bottom: 23rem;
        height: 5rem;
        width: 25rem;
      }
      .warm-regards {
        font-size: 18px;
      }
      .border,
      .top-border,
      .bottom-border,
      .main-div,
      .logo,
      .msg,
      .diya,
      .mandap,
      .couple {
        visibility: hidden;
      }
      .border-1,
      .border-2,
      .border-3,
      .border-4 {
        animation: showBorder 1.5s ease-in forwards;
      }
      .border-1 {
        animation-delay: 0.8s;
      }
      .border-2 {
        animation-delay: 1s;
      }
      .border-3 {
        animation-delay: 1.5s;
      }
      .border-4 {
        animation-delay: 2s;
      }
      .top-border {
        animation: showTopBorder 1s ease-in forwards;
        animation-delay: 2.5s;
      }
      .bottom-border {
        animation: showBottomBorder 1s ease-in forwards;
        animation-delay: 3s;
      }
      .logo {
        animation: moveLogo 1s ease-in-out forwards;
        animation-delay: 3.5s;
      }
      .msg {
        animation: moveLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .main-div {
        animation: moveClinic 1s ease-out forwards;
        animation-delay: 4.5s;
      }
      .mandap {
        animation: showMandap 1s ease-in forwards;
        animation-delay: 5s;
      }
      .diya {
        animation: moveLogo 1s ease-in forwards;
        animation-delay: 5.5s;
      }
      .couple {
        animation: moveLogo 1s ease-in forwards;
        animation-delay: 6s;
      }
      @keyframes moveLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          visibility: visible;
          opacity: 1;
        }
      }
      @keyframes showTopBorder {
        0% {
          top: -30%;
        }
        100% {
          top: -4rem;
          visibility: visible;
        }
      }
      @keyframes showBottomBorder {
        0% {
          bottom: -30%;
        }
        100% {
          bottom: -4rem;
          visibility: visible;
        }
      }
      @keyframes showBorder {
        0% {
          transform: translateY(-30%);
        }
        100% {
          transform: translateY(0%);
          visibility: visible;
        }
      }
      @keyframes moveClinic {
        0% {
          transform: translateY(80%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes showMandap {
        0% {
          bottom: -80%;
        }
        100% {
          bottom: -5rem;
          visibility: visible;
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="red-line-container-left">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-1"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-2"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-3"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-4"
        />
      </div>
      <div class="red-line-container-right">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-1"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-2"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-3"
        />
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/border.png"
          class="border border-4"
        />
      </div>
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/top_border.png"
        class="top-border"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/bottom_border.png"
        class="bottom-border"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/logo.png"
        class="logo"
      />
      <p class="msg">
        May the divine light of Diwali fill your life with love, laughter and
        prosperity!.
      </p>
      <div class="main-div">
        <p class="warm-regards">Warm Regards</p>
        <p  style="font-size: 20px;" class="dr-name bold-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</p>
        <p class="clinic">
          <span class="bold-name">${doctorData?.clinic_name}, </span>${
    doctorData?.address
  }
        </p>
      </div>
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/diya.png"
        class="diya"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/mandap.png"
        class="mandap"
      />
      <img
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-couple/couple.png"
        class="couple"
      />
    </div>
  </body>
</html>
  `;

  //laxmi mata gif
  const festiveGifContent3 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,400..700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
      rel="stylesheet"
    />
    <style>
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        background-position: center;
        background-size: cover;
        background-color: #ad1429;
      }
      .container {
        width: 100vw;
        height: 100vh;
      }
      .border,
      .flower-one,
      .flower-two,
      .greeting,
      .happy-text,
      .diwali-text,
      .greeting,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .laxmi-img,
      .coin-img {
        visibility: hidden;
      }
      .laxmi-img {
        position: absolute;
        right: 4rem;
        bottom: 4rem;
      }
      .border {
        animation: showBorder 1s ease-in-out forwards;
        animation-delay: 0.5s;
      }
      .flower-one {
        animation: moveFlower 1s ease-in-out forwards;
        animation-delay: 1.5s;
      }
      .flower-two {
        animation: moveFlower 1s ease-in-out forwards;
        animation-delay: 1.5s;
        transform: scaleX(-1);
      }
      .happy-text,
      .diwali-text {
        animation: moveGreetings 1s ease-out forwards;
        animation-delay: 2.5s;
      }
      .laxmi-img {
        transform: translateX(-50%);
        animation: moveLaxmiImg 1s ease-in-out forwards;
        animation-delay: 3s;
        right: -8rem;
        bottom: -2rem;
      }
      .coin-img {
        position: absolute;
        animation: moveTop 1s ease-out forwards;
        animation-delay: 3.5s;
        right: 150px;
      }
      .greeting,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address {
        animation: slideInLeft 1s ease-out forwards;
        animation-delay: 4.5s;
      }
      .container-2 {
        overflow: hidden;
      }
      @keyframes slideInLeft {
        from {
          transform: translateX(-100%);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveTop {
        0% {
          bottom: -60%;
          transform: translateY(30%);
        }
        100% {
          bottom: -30%;
          transform: translateY(0%);
          visibility: visible;
        }
      }
      @keyframes moveLaxmiImg {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveGreetings {
        0% {
          transform: translateY(-30%);
          opacity: 0;
        }
        100% {
          transform: translateY(30%);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveFlower {
        0% {
          top: -30%;
          opacity: 0;
        }
        100% {
          top: 5%;
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes showBorder {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
    </style>
  </head>
  <body>
    <div style="position: relative" class="container">
      <img
        class="border"
        style="
          width: calc(100vw - 24px);
          left: 10px;
          position: absolute;
          bottom: 10px;
        "
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-border.png"
      />
      <div
        style="
          position: absolute;
          top: 0px;
          width: 100%;
          z-index: 9;
          display: flex;
          justify-content: center;
        "
      >
        <img
          style="width: 428px; height: 428px; opacity: 0.8; margin: 9rem 0 0 0"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-foot.png"
        />
        <img
          style="width: 428px; height: 428px"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-circle.png"
        />
      </div>
      <div
        style="
          position: absolute;
          top: 0px;
          width: 100%;
          z-index: 9;
          display: flex;
          justify-content: space-between;
        "
      >
        <img
          class="flower-one"
          style="width: 24rem; margin: 0rem 0 0 0"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-flwr.png"
        />
        <img
          class="flower-two"
          style="width: 24rem"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-flwr.png"
        />
      </div>
      <div style="text-align: center; padding-top: 80px; margin: 0">
        <p
          style="
            font-family: 'Alex Brush', cursive;
            color: #fff;
            font-size: 3.7rem;
            line-height: 2.5rem;
            font-weight: 400;
            margin-bottom: 0px;
          "
          class="happy-text"
        >
          Happy
        </p>
        <p
          style="
            font-family: 'Lora', serif;
            color: #edb45d;
            font-size: 58px;
            line-height: 3.3rem;
            font-weight: 700;
            margin: 0;
          "
          class="diwali-text"
        >
          Diwali
        </p>
      </div>
      <div
        style="
          position: absolute;
          bottom: 11rem;
          width: 100%;
          z-index: 9;
          display: flex;
          justify-content: space-between;
        "
      >
        <div class="" style="padding: 0 0rem 2rem 2rem" class="container-2">
          <div
            style="
              font-size: 20px;
              font-family: 'Lora', serif;
              font-weight: 400;
              line-height: 1.5rem;
              max-width: 326px;
              width: 100%;
              color: #fff;
              text-align: left;
              margin: 2rem 0 2rem 0;
            "
            class="greeting"
          >
            May the festival of lights illuminate your life with health,
            happiness, and success.
          </div>
          <div
            style="
              font-size: 16px;
              font-family: 'Lora', serif;
              font-weight: 300;
              line-height: 1.15rem;
              max-width: 140px;
              width: 100%;
              text-align: left;
              color: #fff;
              margin: 1.2rem 0 0.5rem 0;
            "
            class="warm-regards"
          >
            Warm Regards
          </div>
          <div
            style="
              font-size: 20px;
              font-family: 'Lora', serif;
              font-weight: 400;
              line-height: 2rem;
              text-align: left;
              max-width: 330px;
              width: 100%;
              color: #fff;
              margin: 0 0;
            "
            class="dr-name"
          >
            ${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div
            style="
              font-size: 18px;
              font-family: 'Lora', serif;
              font-weight: 600;
              line-height: 1.25rem;
              max-width: 270px;
              width: 100%;
              color: #fff;
              text-align: left;
              margin: 2rem 0 1rem 0;
            "
            class="clinic-name"
          >
            ${doctorData?.clinic_name}
          </div>
          <div
            style="
              font-size: 18px;
              font-family: 'Lustria', serif;
              line-height: 1.15rem;
              text-align: left;
              max-width: 280px;
              width: 100%;
              color: #fff;
              margin: 0 0;
            "
            class="clinic-address"
          >
            ${doctorData?.address}
          </div>
        </div>
        <div style="position: relative">
          <img
            class="laxmi-img"
            style="width: 340px"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-laxmi.png"
          />
          <img
            class="coin-img"
            style="width: 118px; margin: -1.5rem auto 0rem auto"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-laxmi/diwali-gold-dhantera-coin.png"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  //diwali green gif
  const festiveGifContent4 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <style>
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100vw;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: hidden;
        background: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bg.png");
        background-position: center;
        background-size: cover;
      }
      img {
        max-width: 100%;
      }
      .overflow-hidden {
        overflow: hidden;
      }
      .top-flower,
      .bottom-flower,
      .top-light,
      .happy-diwali-logo,
      .msg,
      .dr-name,
      .warm-regards,
      .clinic-address {
        visibility: hidden;
      }
      .light-one {
        position: absolute;
        left: 15%;
        animation: light-drop-two 2.5s ease-in forwards;
        animation-delay: 1.4s;
      }
      @keyframes light-drop-one {
        0% {
          top: -60%;
        }
        80% {
          top: 0;
        }
        100% {
          top: -2%;
        }
      }
      .light-two {
        position: absolute;
        right: 15%;
        animation: light-drop-three 3s ease-in forwards;
        animation-delay: 1.8s;
      }
      @keyframes light-drop-two {
        0% {
          top: -60%;
        }
        80% {
          top: 0;
        }
        100% {
          top: -2%;
        }
      }
      .light-three {
        position: absolute;
        left: 41%;
        animation: light-drop-one 2s ease-in forwards;
        animation-delay: 1s;
      }
      @keyframes light-drop-three {
        0% {
          top: -60%;
        }
        80% {
          top: 0;
        }
        100% {
          top: -2%;
        }
      }
      .top-flower,
      .top-side-flower {
        animation: moveTop 1s ease-in-out forwards;
        animation-delay: 1.2s;
        position: absolute;
      }
      .top-flower {
        width: 23rem;
      }
      .top-light {
        animation: moveLight 1s ease-in-out forwards;
        animation-delay: 1.4s;
      }
      .happy-diwali-logo {
        animation: moveDiwaliLogo 1.5s ease-in-out forwards;
        animation-delay: 3.5s;
      }
      .msg {
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .warm-regards {
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 4.5s;
      }
      .dr-name {
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 5s;
      }
      .clinic-address {
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 5.5s;
      }
      .bottom-flower {
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 6s;
      }
      .text-shadow {
        font-size: 2em;
        background: linear-gradient(90deg, white, #eeb70a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .bottom-deep {
        width: 16rem;
        height: 12rem;
        position: absolute;
        bottom: 0rem;
        z-index: 99999999;
        animation: showAndBlink 1.5s forwards;
        animation-delay: 0.5s;
      }
      .top-side-flower {
        position: absolute;
        top: -4rem;
      }
      @keyframes moveName {
        0% {
          transform: translateY(20%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveDiwaliLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveLight {
        0% {
          top: -60%;
        }
        100% {
          top: -5%;
          visibility: visible;
        }
      }
      @keyframes moveTop {
        from {
          top: -30%;
          transform: translateY(-100px);
        }
        to {
          top: -8%;
          visibility: visible;
          transform: translate(0px);
        }
      }
      @keyframes moveBottom {
        0% {
          bottom: -60%;
          transform: translateY(-100px);
        }
        100% {
          bottom: -2rem;
          visibility: visible;
          transform: translateY(0px);
        }
      }
      @keyframes showAndBlink {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        10% {
          opacity: 1;
        }
        25% {
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        75% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
         .light-container {
    visibility: hidden;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    animation: showAfterDelay 1s forwards;
    animation-delay: 1.4s;
  }

  /* Animation to change visibility and opacity */
  @keyframes showAfterDelay {
    to {
      visibility: visible;
      opacity: 1;
    }
  }
    </style>
  </head>
  <body>
    <div class="container">
      <img
        class="top-side-flower"
        style="opacity: 0.26"
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/top-diwali-green-23-10-2024.png"
      />
      <img
        class="top-flower"
        style="opacity: 0.26"
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/top-middle-diwali-green-23-10-2024.png"
      />
      <div class="top-light" style="position: absolute; top: 0px">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/lights.png"
        />
      </div>
      <div class="light-container">
        <div class="light-one">
          <img
            width="130"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/kandil-light-left.png"
          />
        </div>
        <div class="light-three">
          <img
            width="150"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/kandil-light-center.png"
          />
        </div>
        <div class="light-two">
          <img
            width="130"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/kandil-light-right.png"
          />
        </div>
      </div>
      <div style="position: absolute; top: 15rem" class="happy-diwali-logo">
        <img
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/happy-diwali-diwali-green-23-10-2024.png"
        />
      </div>
      <div
        style="
          position: absolute;
          top: 28rem;
          text-align: center;
          position: absolute;
          left: 14rem;
          left: 50%;
          transform: translate(-50%, -50%);
        "
        class="msg"
      >
        <p
          class="text-shadow msg"
          style="
            color: #eeb70a;
            font-size: 14px;
            width: 100%;
            text-align: center;
            margin: 0;
          "
        >
          May the light of Diwali bring you peace, happiness, and success in all
          your endeavors
        </p>
      </div>
      <div
        style="
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          position: absolute;
          top: 30rem;
        "
        class="warm-regards"
      >
        Warm Regards
      </div>
      <h1
        class="dr-name"
        style="
          font-size: 16px;
          line-height: 48px;
          text-align: center;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          position: absolute;
          top: 32rem;
        "
      >
         ${
           formLang === "english"
             ? `<span class="content-name">Dr. </span>`
             : `<span class="devanagari">डॉ. </span>`
         }
            ${doctorData?.first_name + " " + doctorData?.last_name}
      </h1>
        <p
        class="clinic-address"
        style="
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          position: absolute;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          position: absolute;
          top: 35rem;
          width: 70%;
        "
      >
         ${doctorData?.clinic_name}
      </p>
      <p
        class="clinic-address"
        style="
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          position: absolute;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          position: absolute;
          top: 37rem;
          width: 70%;
        "
      >
         ${doctorData?.address}
      </p>
      <div
        style="
          position: absolute;
          bottom: 20px;
          z-index: 99999999;
          width: 100%;
          text-align: center;
        "
      ></div>
      <div style="position: absolute; width: 100%" class="bottom-flower">
        <div style="display: flex; justify-content: space-between">
          <div>
            <img
              style="opacity: 0.26"
              src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bottom-diwali-green-23-10-2024.png"
            />
          </div>
          <div
            style="
              position: absolute;
              bottom: -60px;
              width: 400px;
              margin-left: -200px;
              left: 50%;
            "
          >
            <img
              style="opacity: 0.26"
              src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bottom-middle-diwali-green-23-10-2024.png"
            />
          </div>
        </div>
      </div>
      <img
        class="bottom-deep"
        src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-green/bottom-deep.png"
      />
    </div>
  </body>
</html>
  `;

  // baby with diya gif
  const festiveGifContent5 = `
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <title>Diwali Wishes Layout</title>
  <link href="https://cdn.example.com/canva-sans.css" rel="stylesheet" />
  <style>
    body {
      font-family: "Canva Sans", sans-serif;
      margin: 0;
      padding: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      background: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/bg.png");
      background-position: center;
      background-size: cover;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100vw;
      /* max-width: 600px; */
      height: 100vh;
    }

    .container img {
      position: absolute;
    }

    /* Positioning the lantern image at the top center */
    .lantern-left {
      top: 0;
      /* left: -4rem; */
      width: 12rem;
      animation: moveRight 1s ease-in-out forwards;
      animation-delay: 3s;
      visibility: hidden;
    }

    .lantern-right {
      top: 0;
      /* right: -4rem; */
      width: 12rem;
      animation: moveLeft 1s ease-in-out forwards;
      animation-delay: 3s;
      visibility: hidden;
    }

    /* Positioning the baby with diya image */
    .baby-with-diya {
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 24rem;
      animation: moveTest 4s ease-in-out forwards;
      animation-delay: 3s;
      visibility: hidden;
    }

    /* Positioning the diyas on the left and right */
    .diya-left {
      left: -15%;
      width: 15rem;
      animation: moveTop 3s ease-in-out forwards;
    }

    .diya-right {
      right: -15%;
      width: 15rem;
      animation: moveTop 3s ease-in-out forwards;
    }

    /* Positioning the best wishes image at the center */
    .best-wishes {
      top: 3%;
      left: 50%;
      transform: translateX(-50%);
      width: 15rem;
      animation: wisses 15s ease-in-out;
    }

    .main-div {
      position: absolute;
      top: 28%;
      left: 50%;
      transform: translateX(-50%);
      width: 26rem;
      text-align: center;
      /* animation: moveTest 4s ease-in-out forwards; */
    }

    .warm-regards {
      color: white;
      margin-top: 15px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .dr-name {
      color: white;
      margin-top: 10px;
      font-size: 24px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .clinic-name {
      color: white;
      margin-top: 28px;
      font-size: 20px;
      margin-bottom: 0;
    }

    .clinic-address {
      color: white;
      margin-top: 10px;
      font-size: 20px;
      margin-bottom: 0;
    }

    .msg {
      color: #ffff66;
      font-size: 14px;
      animation: wisses 8s ease-in-out;
    }

    .clinic-animate {
      animation: moveClinic 1s ease-out forwards;
      animation-delay: 3.1s;
      visibility: hidden;
    }

    @keyframes moveRight {
      0% {
        left: -12%;
      }

      100% {
        left: -10%;
        visibility: visible;
      }
    }

    @keyframes moveLeft {
      0% {
        right: -12%;
      }

      100% {
        right: -10%;
        visibility: visible;
      }
    }

    @keyframes moveTop {
      0% {
        bottom: -60%;
      }

      100% {
        bottom: -15%;
      }
    }

    @keyframes wisses {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0.8;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes moveTest {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 0.8;
      }

      100% {
        opacity: 1;
        visibility: visible;
      }
    }

    @keyframes moveClinic {
      0% {
        transform: translateY(80%);
        /* Start 5% from the bottom */
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        /* End at current position */
        opacity: 1;
        visibility: visible;
      }
    }
  </style>
</head>

<body>
  <div class="container">
    <!-- Lantern Image at the top -->
    <img src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/lantene_left.png" alt="Lantern" class="lantern-left" />
    <img src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/lantene_right.png" alt="Lantern" class="lantern-right" />
    <!-- Baby with Diya Image -->
    <img src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/baby_with_diya.png" alt="Baby with Diya" class="baby-with-diya" />
    <!-- Left Diya Image -->
    <img src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/diya_left.png" alt="Diya on Left" class="diya-left" />
    <!-- Right Diya Image -->
    <img src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/diya_right.png" alt="Diya on Right" class="diya-right" />
    <!-- Best Wishes Image in the center -->
    <img src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-baby/best_wishes1.png" alt="Best Wishes" class="best-wishes" />
    <div class="main-div">
      <p class="msg">
        May the festival of lights illuminate your life <br />
        with endless joy and success. Happy Diwali!
      </p>
      <p class="warm-regards clinic-animate">Warm Regards</p>
      <p class="dr-name clinic-animate">${
        formLang === "english"
          ? `<span class="content-name">Dr. </span>`
          : `<span class="devanagari">डॉ. </span>`
      }
            ${doctorData?.first_name + " " + doctorData?.last_name}</p>
      <p class="clinic-name clinic-animate">${doctorData?.clinic_name}</p>
      <p class="clinic-address clinic-animate">
       ${doctorData?.address}
      </p>
    </div>
  </div>
</body>

</html>
  `;

  // mithai
  const festiveGifContent6 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />

    <style>
      body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        background: white;
        background-position: center;
        background-size: cover;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100vw;
        height: 100vh;
      }
      .container-main {
        width: 100vw;
        height: 100vh;
        position: relative;
        background-image: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-bg.png");
        background-repeat: repeat;
        background-size: contain;
        background-position: center center;
      }
      .diwali-blue-cream-mithai-text-main,
      .toptext,
      .diwali-blue-cream-mithai-happy-diwali,
      .warmregards,
      .drnames,
      .clinicname,
      .address,
      .diwali-blue-cream-mithai-men,
      .diwali-blue-cream-mithai-flwr-left,
      .diwali-blue-cream-mithai-flwr-right,
      .diwali-blue-cream-mithai-flwr-main,
      .diwali-blue-cream-mithai-tops {
        visibility: hidden;
      }
      .diwali-blue-cream-mithai-tops {
        width: 100%;
        position: absolute;
        top: 0px;
        top: 0px;
        z-index: 9;
        animation: moveTop 0.5s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-blue-cream-mithai-flwr-main {
        position: absolute;
        top: 0px;
        width: 100%;
        z-index: 999;
        display: flex;
        justify-content: space-between;
      }
      .diwali-blue-cream-mithai-flwr-left {
        transform: scaleX(-1);
        height: 19rem;
        animation: moveFromLeft 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .diwali-blue-cream-mithai-flwr-right {
        height: 19rem;
        animation: moveFromLeft 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .diwali-blue-cream-mithai-text-main {
        position: absolute;
        top: 100px;
        z-index: 9;
        width: 100%;
        text-align: center;
        animation: moveWomen 1.5s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-blue-cream-mithai-luts {
        width: 130px;
        animation: fadeFlower 0.5s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-blue-cream-mithai-text {
        position: absolute;
        top: 14rem;
        width: 100%;
      }
      .toptext {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        width: 60%;
        color: #000;
        margin: 0 auto 0.8rem auto;
        animation: showHeader 1s ease-out forwards;
        animation-delay: 2s;
      }
      .diwali-blue-cream-mithai-happy-diwali {
        width: 350px;
        margin: 0px auto;
        display: block;
        animation: showWomen 1s ease-out forwards;
        animation-delay: 2.5s;
      }
      .warmregards {
        font-size: 18px;
        max-width: 140px;
        width: 100%;
        text-align: center;
        color: #000;
        margin: 1.2rem auto 12px auto;
        animation: showHeader 1s ease-out forwards;
        animation-delay: 3s;
      }
      .drnames {
        font-size: 28px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        width: 75%;
        color: #000;
        margin: 12px auto;
        animation: showHeader 1s ease-out forwards;
        animation-delay: 4s;
      }
      .clinicname {
        font-size: 26px;
        font-weight: 600;
        line-height: 17px;
        width: 75%;
        color: #000;
        text-align: center;
        margin: 2.8rem auto 0.8rem auto;
        animation: moveTextToTop 1s ease-out forwards;
        animation-delay: 5s;
        z-index: 999;
      }
      .address {
        font-size: 24px;
        line-height: 30px !important;
        text-align: center;
        width: 60%;
        color: #000;
        margin: 22px auto;
        animation: moveTextToTop 1s ease-out forwards;
        animation-delay: 5s;
        z-index: 9;
      }

      .diwali-blue-cream-mithai-cerl {
        position: absolute;
        bottom: 0px;
        z-index: 9;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .diwali-blue-cream-mithai-cerl img {
        width: 465px;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .diwali-blue-cream-mithai-men {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .diwali-blue-cream-mithai-men img {
        position: absolute;
        bottom: 0px;
        z-index: 999;
        width: 25rem;
        animation: showWomen 1.5s ease-out forwards;
        animation-delay: 6s;
      }
      @keyframes moveTextToTop {
        0% {
          transform: translateY(80%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes showWomen {
        0% {
          opacity: 0;
        }
        30% {
          opacity: 0.2;
        }
        50% {
          opacity: 0.5;
        }
        75% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes fadeFlower {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes showHeader {
        0% {
          transform: translateY(-80%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveTop {
        from {
          top: -30%;
          transform: translateY(-100px);
        }
        to {
          top: -2%;
          visibility: visible;
          transform: translate(0px);
        }
      }
      @keyframes moveFromLeft {
        0% {
          left: -4%;
        }
        100% {
          left: 2%;
          visibility: visible;
        }
      }
    </style>
  </head>

  <body style="margin: 0px; padding: 0px">
    <div class="container">
      <div class="container-main">
        <img
          class="diwali-blue-cream-mithai-tops"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-tops.png"
        />
        <div class="diwali-blue-cream-mithai-flwr-main">
          <img
            class="diwali-blue-cream-mithai-flwr-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-flwr.png"
          />
          <img
            class="diwali-blue-cream-mithai-flwr-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-flwr.png"
          />
        </div>

        <div class="diwali-blue-cream-mithai-text-main">
          <img
            class="diwali-blue-cream-mithai-luts"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-luts.png"
          />
        </div>

        <div class="diwali-blue-cream-mithai-text">
          <div class="toptext">
            Sending you loads of love, happiness, and blessings this Diwali.
            Let's celebrate the festival of lights together!
          </div>

          <img
            class="diwali-blue-cream-mithai-happy-diwali"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-happy-diwali.png"
          />
          <div class="warmregards">Warm Regards</div>
          <div class="drnames">
          ${
            formLang === "english"
              ? `<span class="content-name">Dr. </span>`
              : `<span class="devanagari">डॉ. </span>`
          }
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div class="clinicname">${doctorData?.clinic_name}</div>
          <div class="address">
            ${doctorData?.address}
          </div>
        </div>

        <div class="diwali-blue-cream-mithai-cerl">
          <img
            src="https://d3typ5yqytip29.cloudfront.net/images_uploaded/diwali-blue-cream-mithai-flower.png"
          />
        </div>
        <div class="diwali-blue-cream-mithai-men">
          <img
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-mithai/diwali-blue-cream-mithai-men.png"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  // girl holding diya
  const festiveGifContent7 = `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <style>
        body {
        margin: 0;
        padding: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
          width: 100vw;
        height: 100vh;
        background: url(https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-pachs.png);
        background-repeat: round;
      }
      .container-main {
        height: 600px;
        position: relative;
        overflow: hidden;
          width: 100vw;
        height: 100vh;
      }
      .girl-holding-crcl-top-left {
        width: 320px;
        position: absolute;
        top: -136px;
        left: -148px;
        z-index: 9;
      }
      .bg-img {
          position: absolute;
top: 14rem;
      } 
      .girl-holding-crcl-md-right {
        width: 180px;
        position: absolute;
        top: -4rem;
        right: -4rem;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-crcl-sm-top {
        width: 93px;
        position: absolute;
        top: -1rem;
        left: -2rem;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-diya-flwr-main {
        position: absolute;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        animation: moveLight 1s ease-in-out forwards;
        animation-delay: 0.5s;
        visibility: hidden;
      }
      .girl-holding-diya-top-left-flwr {
        height: 200px;
      }
      .girl-holding-diya-top-right-flwr {
        transform: scaleX(-1);
        height: 200px;
      }
      .girl-holding-diya-happy {
        width: 210px;
        margin: 0 auto;
        display: block;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .messagetext {
        font-size: 18px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 20px;
        width: 30%;
        text-align: center;
        color: #d9aca7;
        margin: 1.25rem auto 0.5rem auto;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 2.5s;
        visibility: hidden;
      }
      .warmregards {
        font-size: 18px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 16px;
        max-width: 140px;
        width: 100%;
        text-align: center;
        color: #fff;
        margin: 20px auto 12px auto;
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }
      .drname {
        font-size: 22px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        text-align: center;
        width: 50%;
        color: #fff;
        margin: 2rem auto;
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }
      .clinicname {
        font-size: 18px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        width: 50%;
        color: #fff;
        text-align: center;
        margin: 12px auto 0px auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }

      .address {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        text-align: center;
        width: 60%;
        color: #fff;
        margin: 0.7rem auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }

      .girl-holding-hand-diya-main {
        position: absolute;
        bottom: 280px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
      }
      .girl-holding-hand-diya-left {
        height: 125px;
        transform: scaleX(-1);
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 5s;
        visibility: hidden;
      }
      .girl-holding-hand-diya-right {
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 5s;
        visibility: hidden;
        height: 125px;
      }

      .girl-holding-crcl-sm-left {
        width: 35px;
        position: absolute;
        top: 7rem;
        left: 2rem;
        z-index: 9;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-crcl-sm-bottom {
        width: 80px;
        position: absolute;
        bottom: 68px;
        left: 0;
        z-index: 9;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-crcl-sm-right {
        width: 100px;
        position: absolute;
        bottom: 65px;
        right: -58px;
        z-index: 9;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-girl-diya {
        position: absolute;
        bottom: -4px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
        margin-left: 1.5rem;
      }
      .girl-holding-girl {
        width: 14rem;
        position: absolute;
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 5.5s;
      }
      .girl-holding-diyas {
        width: 25px;
        position: absolute;
        top: 7px;
        left: 489px;
        z-index: 9;
        text-align: center;
      }

      .girl-holding-louts-main {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
      }
      .girl-holding-louts-left {
        width: 40px;
        height: 40px;
      }
      .girl-holding-louts-right {
        width: 40px;
        height: 40px;
      }
      .girl-holding-diya-diya-main {
        width: 100%;
      }
      .girl-holding-diya-diya-left {
        position: absolute;
        bottom: 0px;
        left: 10%;
        z-index: 9;
        width: 100px;
        animation: moveBottom 1s ease-in-out forwards;
        visibility: hidden;
        animation-delay: 6s;
      }
      .girl-holding-diya-diya-right {
        position: absolute;
        bottom: 0px;
        right: 21%;
        z-index: 9;
        width: 100px;
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 6s;
        visibility: hidden;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(250deg);
        }
        100% {
          transform: rotate(500deg);
        }
      }
      @keyframes moveLight {
        0% {
          top: -60%;
        }
        100% {
          top: -2%;
          visibility: visible;
        }
      }
      @keyframes moveDiwaliLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveName {
        0% {
          transform: translateY(20%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveRight {
        0% {
          left: -12%;
        }

        100% {
          left: -17%;
          visibility: visible;
        }
      }
      @keyframes moveLeft {
        0% {
          right: -12%;
        }

        100% {
          right: -17%;
          visibility: visible;
        }
      }
      @keyframes moveBottom {
        0% {
          bottom: -200px;
        }
        100% {
          bottom: 0;
          visibility: visible;
        }
      }
    </style>
  </head>

  <body>
    <div class="container">
      <div class="container-main">
        <img
          class="girl-holding-crcl-top-left"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-md.png"
        />
        <img
          class="girl-holding-crcl-md-right"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-md.png"
        />
        <img
          class="girl-holding-crcl-sm-top"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />
          <div class="girl-holding-diya-flwr-main">
          <img
            class="girl-holding-diya-top-left-flwr"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-flwr.png"
          />
          <img
            class="girl-holding-diya-top-right-flwr"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-flwr.png"
          />
        </div>
                        <div class="bg-img">

          <img
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-happy.png"
            class="girl-holding-diya-happy"
          />
        <div class="messagetext">
          May this Diwali bring love & light to your family!
        </div>
        <div class="warmregards">Warm Regards</div>
        <div class="drname">
          ${formLang === "english" ? "Dr. " : "डॉ. "}
        ${doctorData?.first_name + " " + doctorData?.last_name}
        </div>
        <div class="clinicname">
        ${doctorData?.clinic_name}
        </div>
        <div class="address">
        ${doctorData?.address}
        </div>
            </div>
         <div class="girl-holding-hand-diya-main">
          <img
            class="girl-holding-hand-diya-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-hand-diya.png"
          />
          <img
            class="girl-holding-hand-diya-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-hand-diya.png"
          />
        </div>
        <img
          class="girl-holding-crcl-sm-left"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />
        <img
          class="girl-holding-crcl-sm-bottom"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />
        <img
          class="girl-holding-crcl-sm-right"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />

        <div class="girl-holding-girl-diya">
          <img
            class="girl-holding-girl"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya.png"
          />
          <img
            class="girl-holding-diyas"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diyas.png"
          />
        <div class="girl-holding-diya-diya-main">
          <img
            class="girl-holding-diya-diya-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-diya.png"
          />
            <img
            class="girl-holding-diya-diya-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-diya.png"
          />
        </div>
          </div>

      </div>
    </div>
  </body>
</html>
  `;

  // traditional
  const festiveGifContent8 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
      rel="stylesheet"
    />
    <style>
      body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        background: white;
        background-position: center;
        background-size: cover;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100vw;
        height: 100vh;
      }
      .container-main {
        width: 100vw;
        height: 100vh;
        position: relative;
        overflow: hidden;
        background-image: url("https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #193b1e;
        background-position: center center;
      }
      .diwali-green-yellow-traditional-family-crcl {
        width: 700px;
        position: absolute;
        top: -80px;
        z-index: 9;
      }
      .diwali-green-yellow-traditional-top-main {
        position: absolute;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-green-yellow-traditional-top-left {
        height: 200px;
      }
      .diwali-green-yellow-traditional-top-right {
        height: 200px;
      }

      .diwali-green-yellow-traditional-happy-diwalis-main {
        position: absolute;
        top: 30px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
      }
      .diwali-green-yellow-traditional-happy-diwalis {
        width: 250px;
        height: 250px;
        position: relative;
        background-image: url('https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-happy-diwalis.png');
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        background-position: center center;
        align-items: center;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }

      .diwali-green-yellow-traditional-happy-diwalis-cer {
        width: 200px;
        position: absolute;
        top: 54px;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 1s;
      }
      .diwali-green-yellow-traditional-main {
        position: absolute;
        top:0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        animation: moveLight 3s ease-in-out forwards;
        animation-delay: 2s;
        visibility: hidden;
      }

      .diwali-green-yellow-traditional-left {
        width: 70px;
        transform: scaleX(-1);
      }
      .diwali-green-yellow-traditional-right {
        width: 70px;
      }
      .diwali-green-yellow-traditional-top-right-anim {
        position: absolute;
        top: 290px;
        z-index: 9;
        text-align: center;
        width: 100%;
      }
      .diwali-green-yellow-traditional-top-right-anim img {
        width: 550px;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .contant-text {
        position: absolute;
        top: 370px;
        width: 100%;
      }
      .warmregards {
        font-size: 20px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 16px;
        max-width: 140px;
        width: 100%;
        text-align: center;
        color: #fff;
        margin: 1.2rem auto 1rem auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 3s;
      }
      .drname {
        font-size: 26px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 17px;
        text-align: center;
        max-width: 330px;
        width: 100%;
        color: #fff;
        margin: 0 auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 3s;
      }
      .clinicname {
        font-size: 22px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
        line-height: 17px;
        max-width: 270px;
        width: 100%;
        color: #fff;
        text-align: center;
        margin: 2rem auto 0.5rem auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .address {
        font-size: 18px;
        font-family: "Inter", sans-serif;
        line-height: 22px;
        text-align: center;
        max-width: 340px;
        width: 100%;
        color: #fff;
        margin: 10px auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }
      .diwali-green-yellow-traditional-flwr-leftmain {
    position: absolute;  
    top: 50%;
    left: 50%;          
    transform: translate(-50%, -50%);  
    width: 100%;         
    z-index: 9;         
    display: flex;       
    justify-content: space-between; 
}
      .diwali-green-yellow-traditional-flwr-left {
        width: 100px;
        animation: moveRight 10s ease-in-out forwards;
      }
      .diwali-green-yellow-traditional-flwr-right {
        width: 100px;
        transform: scaleX(-1);
        animation: moveLeft 10s ease-in-out forwards;
      }
      .diwali-green-yellow-traditional-family-leftmains {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
        align-items: end;
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 5.5s;
        visibility: hidden;
      }
      .diwali-green-yellow-traditional-family-left {
        width: 210px;
      }
      .diwali-green-yellow-traditional-flwr-bottom {
        width: 200px;
        object-fit: contain;
      }
      .diwali-green-yellow-traditional-family-right {
        width: 210px;
      }

      @keyframes moveBottom {
        0% {
          bottom: -200px;
        }
        100% {
          bottom: 0;
          visibility: visible;
        }
      }

      @keyframes moveLight {
        0% {
          top: -60%;
        }
        100% {
          top: 15%;
          visibility: visible;
        }
      }
      @keyframes moveDiwaliLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }

      @keyframes moveLeft {
        0% {
          transform: translateX(300px); /* Starting from the right */
        }
        100% {
          transform: translateX(-1); /* Moving back to the original position */
        }
      }

      @keyframes moveRight {
        0% {
          transform: translateX(-300px); /* Starting from the right */
        }
        100% {
          transform: translateX(1px); /* Moving back to the original position */
        }
      }
    </style>
  </head>

  <body>
    <div class="container">
      <div class="container-main">
        <img
          class="diwali-green-yellow-traditional-family-crcl"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-family-crcl.png"
        />

        <div class="diwali-green-yellow-traditional-top-main">
          <img
            class="diwali-green-yellow-traditional-top-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-top-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-top-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-top-right.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-happy-diwalis-main">
          <div class="diwali-green-yellow-traditional-happy-diwalis"></div>
          <img
            class="diwali-green-yellow-traditional-happy-diwalis-cer"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-happy-diwalis-cer.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-main">
          <img
            class="diwali-green-yellow-traditional-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-left.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-top-right-anim" style="
            color: #ffffff;
            font-size: 22px;
          ">
          Wishing you and your family a very Happy Diwali from us. 
        </div>

        <div class="contant-text">
          <div class="warmregards">Warm Regards</div>
          <div class="drname">
             ${
               formLang === "english"
                 ? `<span class="content-name">Dr. </span>`
                 : `<span class="devanagari">डॉ. </span>`
             }
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
          <div class="clinicname">${doctorData?.clinic_name}</div>
          <div class="address">
          ${doctorData?.address}
          </div>
        </div>

        <div class="diwali-green-yellow-traditional-flwr-leftmain">
          <img
            class="diwali-green-yellow-traditional-flwr-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-flwr-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-flwr-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-flwr-left.png"
          />
        </div>

        <div class="diwali-green-yellow-traditional-family-leftmains">
          <img
            class="diwali-green-yellow-traditional-family-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-family-left.png"
          />
          <img
            class="diwali-green-yellow-traditional-flwr-bottom"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-flwr.png"
          />
          <img
            class="diwali-green-yellow-traditional-family-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-traditional/diwali-green-yellow-traditional-family-right.png"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  // guru nanak template 1
  const festiveGifContent10 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Guru Nanak Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <style>
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: hidden;
      }

      .container {
        overflow: hidden;
        background: url("https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/bg.png");
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100vw;
        height: 100vh;
      }

      .guru {
        height: 20rem;
        text-align: center;
        margin: 10px auto;
      }

      .left-light,
      .right-light {
        height: 10rem;
      }

      .left-light {
        position: absolute;
        left: 0;
        top: 0;
      }

      .right-light {
        position: absolute;
        right: 0;
        top: 0;
      }

      .greeting {
        position: absolute;
        top: 4rem;
        width: 100%;
        display: grid;
        justify-items: center;
        grid-gap: 10px;
      }

      .flower-left,
      .flower-right,
      .flower-bottom {
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }

      .flower-left,
      .flower-right {
        position: absolute;
        bottom: 6rem;
        height: 13rem;
        width: 13rem;
      }

      .flower-left {
        left: -5.5rem;
      }

      .flower-right {
        right: -5.5rem;
      }

      .diya {
        height: 4rem;
      }

      .flower-bottom {
        position: absolute;
        height: 8rem;
        bottom: -4rem;
      }

      .bottom-diya {
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: center;
        width: 100%;
        grid-gap: 10rem;
      }

      .header,
      .main-title,
      .message,
      .warm-regards,
      .dr-name,
      .clinic-name,
      .clinic-address,
      .guru,
      .diya {
        visibility: hidden;
      }

      .main-title {
        text-align: center;
        color: #ffb900;
        font-weight: 400;
        font-family: "Lora", serif;
        font-size: 26px;
        margin: 5px auto;
        display: grid;
        justify-items: flex-start;
        width: 35%;
      }

      .dr-name {
        font-size: 24px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        color: #fff;
        margin: 0 auto 15px auto;
      }

      .clinic-name {
        font-size: 24px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        line-height: 22px;
        color: #fff;
        text-align: center;
        margin: 20px auto 0.5rem auto;
        width: 70%;
      }

      .clinic-address {
        font-size: 20px;
        font-family: "Inter", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        width: 70%;
      }

      .main-animate {
        animation: showMesssage 1s ease-out forwards;
        animation-delay: 0.5s;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }

        50% {
          transform: rotate(450deg);
        }

        100% {
          transform: rotate(720deg);
        }
      }

      @keyframes showMesssage {
        0% {
          transform: translateY(40%);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
    </style>
  </head>

  <body>
    <div class="container">
      <img
        class="left-light"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/leftLight.png"
      />
      <img
        class="right-light"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/rightLight.png"
      />
      <img
        class="flower-left"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/flower.png"
      />
      <img
        class="flower-right"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/flower.png"
      />
      <div class="bottom-diya">
        <img
          class="diya main-animate"
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/diya.png"
        />
        <img
          class="flower-bottom"
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/flower.png"
        />
        <img
          class="diya main-animate"
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/diya.png"
        />
      </div>
      <div class="greeting">
        <img
          class="guru main-animate"
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_1/guru.png"
        />
        <div class="main-title main-animate">
          Happy
          <p style="margin: 0">Guru Nanak Jayanti</p>
        </div>
        <div>
          <div
            style="
              font-size: 16px;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              line-height: 30px;
              width: 100%;
              text-align: center;
              color: #fff;
              margin: 12px auto 5px auto;
            "
            class="warm-regards main-animate"
          >
            Warm Regards
          </div>
          <div class="dr-name main-animate">
            ${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
        </div>
        <div>
          <div class="clinic-name main-animate">
          ${doctorData?.clinic_name}
          </div>
          <div class="clinic-address main-animate">
           ${doctorData?.address}
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  // guru nanak template 2
  const festiveGifContent11 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Guru Nanak Greetings</title>
    <link
      href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lustria&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Lumanosimo&amp;family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lora:ital,wght@0,400..700;1,400..700&family=Lumanosimo&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <style>
      body {
        font-family: "Canva Sans", sans-serif;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        background-position: center;
        background-size: cover;
      }
      .container {
        position: relative;
        overflow: hidden;
        background: url("https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/bg.png");
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
      }
      .guru {
        height: 13rem;
        text-align: center;
        margin: 23px auto;
        z-index: 9;
      }
      .guru-bg {
        height: 20rem;
        text-align: center;
        margin: 12px auto;
        position: absolute;
        top: -43px;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .left-light,
      .right-light {
        height: 9rem;
        position: absolute;
        top: -5px;
        transform-origin: top left;
        animation: swing 4s ease-in-out infinite;
      }
      .left-light {
        left: 1.5rem;
      }
      .right-light {
        right: 0;
      }
      .greeting {
        position: absolute;
        top: 11rem;
        width: 100%;
        display: grid;
        justify-items: center;
        grid-gap: 8px;
      }
      .flower-left,
      .flower-right {
        position: absolute;
        bottom: -4rem;
        height: 12rem;
        width: 12rem;
      }
      .flower-left {
        left: -4rem;
      }
      .flower-right {
        right: -4rem;
      }
      .img-left,
      .img-right {
        position: absolute;
        top: 12px;
        height: 7rem;
      }
      .img-left {
        left: 4rem;
      }
      .img-right {
        right: 5rem;
      }
      .warm-regards {
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        width: 100%;
        text-align: center;
        color: #fff;
        margin: 10px auto;
      }
      .main-title {
        text-align: center;
        color: #fff;
        font-weight: 400;
        font-family: "Lora", serif;
        font-size: 24px;
        margin: 3rem auto 0px auto;
        width: 65%;
      }
      .dr-name {
        font-size: 24px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        color: #fff;
        margin: 0 auto;
      }
      .clinic-name {
        font-size: 22px;
        font-family: "Inter", sans-serif;
        font-weight: 700;
        line-height: 24px;
        color: #fff;
        text-align: center;
        margin: 0 auto;
        width: 80%;
      }
      .clinic-address {
        font-size: 20px;
        font-family: "Inter", sans-serif;
        line-height: 22px;
        text-align: center;
        color: #fff;
        margin: 15px auto;
        width: 80%;
      }
      .main-animate {
        animation: showMesssage 1s ease-out forwards;
        animation-delay: 0.5s;
      }
      .animate-guru {
        animation: moveLogo 1s ease-out forwards;
        animation-delay: 1s;
      }
      @keyframes moveLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          visibility: visible;
          opacity: 1;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(450deg);
        }
        100% {
          transform: rotate(720deg);
        }
      }
      @keyframes showMesssage {
        0% {
          transform: translateY(40%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes swing {
        0% {
          transform: translateX(-30%) rotate(-10deg);
        }
        50% {
          transform: translateX(-30%) rotate(10deg);
        }
        100% {
          transform: translateX(-30%) rotate(-10deg);
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <img
        class="left-light"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower.png"
      />
      <img
        class="right-light"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower.png"
      />
      <img
        class="flower-left"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower2.png"
      />
      <img
        class="flower-right"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower2.png"
      />
      <img
        class="img-left animate-guru"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/img1.png"
      />
      <img
        class="img-right animate-guru"
        src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/img1.png"
      />
      <div class="greeting">
        <img
          class="guru animate-guru"
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/guru.png"
        />
        <img
          class="guru-bg"
          src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/gifs/gurunanak_template_2/flower2.png"
        />
        <div class="main-title main-animate">
          Happy
          <p style="margin: 5px 0">Guru Nanak Jayanti!</p>
        </div>
        <div style="margin: 25px 0">
          <div class="warm-regards main-animate">Best Wishes</div>
          <div class="dr-name main-animate">
            ${
              formLang === "english"
                ? `<span class="content-name">Dr. </span>`
                : `<span class="devanagari">डॉ. </span>`
            }
            ${doctorData?.first_name + " " + doctorData?.last_name}
          </div>
        </div>
        <div>
          <div class="clinic-name main-animate">
          ${doctorData?.clinic_name}
          </div>
          <div class="clinic-address main-animate">
          ${doctorData?.address}
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const brandContent1 = `
    <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <link href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap" rel="stylesheet">
		<link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Lumanosimo&family=Lustria&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet">
 <title>Diwali Greeting</title>
        <style>
          body {
          font-family: "Inter", sans-serif;
          text-align: center;
          color: white;
          margin: 0;
          padding: 0;
          }
          .bgs {
          position: relative;
          margin: 0px auto;
          padding: 0px;
          width: 800px;
          height: 800px;
          border: solid 1px #ccc;
          }
          img{ max-width: 100%;}
          .logo {
          background-color: #fff;
          display: inline-block;
          padding: 0.15rem 1rem 0.15rem 0.25rem;
          border-radius: 0px 0px 37px 0px;
          position: absolute;
          left: 0px;width:22%;
          }
          .leftcols {
          background: url(${
            brandSpecData?.speciality?.background_image
          }) no-repeat;
          position: relative;background-size: cover;
          width: 64%;
          }
          .rightcols {border-left:solid 1.75px #fff;
          padding:0.85rem 1.5rem 0.75rem 1.5rem;
          width: 36%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;   
          background: linear-gradient(90deg, ${
            brandSpecData?.speciality?.dark_color
          } 0%, ${brandSpecData?.speciality?.light_color} 100%);
          }
          .subtitle {
          text-align: left;
          font-size: 1.5rem;
          line-height: 1.8rem;
          font-weight: 700;
          font-family: "Inter", sans-serif;
          margin-bottom: 0px;
          }
          .content {
          font-family: "Inter", sans-serif;
          text-align: left;
          font-size: 1rem;
          line-height: 1.3rem;
          max-width: 241px;
          margin: 8px 0px 10px 0px;
          }
          .drname{color:${
            brandSpecData?.speciality?.dark_color
          }; font-size:1.65rem; line-height:1.65rem; font-weight:700; margin-bottom:0.65rem; text-align:left;}
          ul {
          padding-left: 1.75rem;
          font-family: "Roboto", sans-serif;
          margin-top:35px;
          }
          ul li::marker{font-weight:bold;}
          ul li {
          color: #262626;
          font-weight: 600;
          text-align: left;
          list-style: decimal;
          line-height: 1.75rem;
          font-size: 1.75rem;
          margin-bottom: 1.75rem;
          }
          .parasbold{color:#486FAF; text-transform: capitalize; text-align:left;font-size:1.50rem; line-height:1.5rem;font-weight:700; margin-bottom:0.50rem;}
          .paras{color:#9CA3AF; text-align:left;font-size:1.25rem;line-height:1.5rem;}
        </style>
    </head>
    <body>
        <div class="container">
          <div class="bgs">
              <div style="display:flex; height: 220px">
                <div class="leftcols">
                    <div class="logo">
                     <img src=${brandSpecData?.icon_logo} />
                    </div>
                </div>
                <div class="rightcols">
                    <div class="subtitle">${
                      brandSpecData?.speciality?.heading
                    }</div>
                    <div style="text-align: left;">
                  <img src=${brandSpecData?.border_icon} width="210"/>
					  
                    </div>
                    <div class="content">${
                      brandSpecData?.speciality?.sub_heading
                    }</div>
                </div>
              </div>
              <div style="position:absolute;top:140px;left: 32px; width:18%"> 
               <img src=${brandSpecData?.icon_quality} />
              </div>
              <div style="display:block; margin:4rem 2rem 0rem 2rem;">
                <div class="drname">Dear Dr. ${
                  doctorData?.first_name + " " + doctorData?.last_name
                },
                </div>
                <div class="paras" style="margin: 15px auto;">Request your Support</div>
                ${convertBrandSerList()}
              </div>
              <div style="position: absolute; bottom:36px; left: 2rem">
                <div class="paras">Thank You,</div>
                <div class="parasbold" style="color: ${
                  brandSpecData?.speciality?.dark_color
                }; margin-top: 1rem;">
                 ${doctorData?.rep_name || ""}
                </div>
                <div class="paras" style="color: ${
                  brandSpecData?.speciality?.dark_color
                }; margin-bottom: 0.5rem; margin-top: 0.25rem; font-weight: 600;">
                +91 ${
                  Array.isArray(doctorData?.payload) &&
                  doctorData.payload[0]?.rep_mobile_number
                    ? doctorData.payload[0].rep_mobile_number
                    : ""
                }
					
                </div>
              </div>
              <div style="display:flex; position: absolute;right:0px;bottom: 36px; max-width:298px; width:100%;    align-items: center;background-color: #EEFAFF;border-radius:16px 0 0 0;padding: 10px 0px 8px 0px;">
                <div style="{
                  brandSpecData?.speciality?.dark_color
                };padding:0rem 0.15rem 0rem 0.15rem; "> 
                <img src=${
                  brandSpecData?.icon_logo
                } width="140" style="vertical-align: bottom;"  />
					
                </div>
                <div style=" padding:0.25rem 0.35rem 0.25rem 0rem;">
                   <div style="max-width:148px;color:#315289; font-size:14px; text-align:left; font-weight:700;line-height:18px;border-left: solid 2px #315289;padding-left: 8px;">India’s First Technology Enabled Pharma Partner</div>
                </div>
              </div>
			  
			
			 <div style="background-color:${
         brandSpecData?.speciality?.dark_color
       };position: absolute;right:0px;bottom: 0;display: flex; justify-content: flex-end; align-items: center; width: 100%;text-align: right; font-size:10px; font-family: 'Roboto', sans-serif; color:#fff; text-transform:uppercase;line-height: 36px;font-weight: 400;
    letter-spacing: 0.5px;" class="footertext">We are going Paperless <img style="vertical-align: middle; margin:0 12px 0 10px;" width="30" src="${
      brandSpecData?.speciality?.paperless_image
    }" /></div>
			  
          </div>
        </div>
    </body>
  </html>
  `;

  const medicalDays18_1 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
      rel="stylesheet"
    />
    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 50%;
      }
      .rightcols {
        padding: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .parasbold {
        color: #486faf;
        text-align: left;
        font-size: 1.3rem;
        line-height: 1.25rem;
        font-weight: 700;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 27px;
      }
      .paras {
        color: #486faf;
        font-weight: 700;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.25rem;
        padding-left: 27px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/world-7-11-2024-1.jpg"
            style="vertical-align: top"
          />
        </div>
        <div style="display: flex">
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
              +91 ${repDetails?.mobile_number}
            </div>
          </div>
          <div class="rightcols">
            <div>
              <img
                src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/world-7-11-2024-2.jpg"
                style="vertical-align: top"
              />
            </div>
          </div>
        </div>
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/world-7-11-2024-3.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const medicalDays18_2 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    rel="stylesheet">

    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 50%;
      }
      .rightcols {
        padding: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .parasbold {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        text-align: left;
        font-size: 1.45rem;
        line-height: 1.25rem;
        font-weight: 400;
        margin-top: 0.6rem;
        margin-bottom: 0.99rem;
        padding-left: 27px;
      }
      .paras {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        font-weight: 400;
        text-align: left;
        font-size: 1.45rem;
        line-height: 1.25rem;
        padding-left: 27px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/02-world-7-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            display: flex;
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/02-world-7-11-2024-02.jpg)
              repeat;
            position: relative;
            background-size: cover;
          "
        >
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
              +91 ${repDetails?.mobile_number}
            </div>
          </div>
        </div>

        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/02-world-7-11-2024-03.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const medicalDays18_3 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    rel="stylesheet">

    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 50%;
      }
      .rightcols {
        padding: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .parasbold {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-top: 0.4rem;
        margin-bottom: 1rem;
        padding-left: 22px;
      }
      .paras {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        font-weight: 600;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.25rem;
        padding-left: 22px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/05-world-10-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            display: flex;
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/05-world-10-11-2024-02.jpg)
              repeat;
            position: relative;
            background-size: contain;
          "
        >
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
               +91 ${repDetails?.mobile_number}
            </div>
          </div>
          <div class="rightcols">
            <div>
              <img
                src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/05-world-10-11-2024-03.jpg"
                style="vertical-align: top"
              />
            </div>
          </div>
        </div>

        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/05-world-10-11-2024-04.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const medicalDays18_4 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    rel="stylesheet">

    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 50%;
      }
      .rightcols {
        padding: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .parasbold {
        font-family: "Poppins", sans-serif;
        color: #d40b22;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-top: 0rem;
        margin-bottom: 1rem;
        padding-left: 27px;
      }
      .paras {
        font-family: "Poppins", sans-serif;
        color: #d40b22;
        font-weight: 600;
        text-align: left;
        font-size: 1.4rem;
        line-height: 1.25rem;
        padding-left: 27px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/03-world-8-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            display: flex;
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/03-world-8-11-2024-2.jpg)
              repeat;
            position: relative;
            background-size: contain;
          "
        >
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
               +91 ${repDetails?.mobile_number}
            </div>
          </div>
          <div class="rightcols">
            <div>
              <img
                src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/03-world-8-11-2024-3.jpg"
                style="vertical-align: top"
              />
            </div>
          </div>
        </div>

        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/03-world-8-11-2024-4.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const medicalDays18_5 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    rel="stylesheet">

    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }

      .parasbold {
        font-family: "Poppins", sans-serif;
        color: #1a1a1a;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-top: 0rem;
        margin-bottom: 0.7rem;
        padding-left: 27px;
      }
      .paras {
        font-family: "Poppins", sans-serif;
        color: #1a1a1a;
        font-weight: 600;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.25rem;
        padding-left: 27px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/04-world-8-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/04-world-8-11-2024-02.jpg)
              repeat;
            position: relative;
            background-size: cover;
          "
        >
          <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
          <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
            +91 ${repDetails?.mobile_number}
          </div>
        </div>

        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/04-world-8-11-2024-03.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const medicalDays18_6 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    rel="stylesheet">

    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 100%;
        text-align: center;
      }
      .parasbold {
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #252525;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-top: 0.4rem;
        margin-bottom: 1rem;
        padding-left: 0px;
      }
      .paras {
        text-align: center;
        font-family: "Poppins", sans-serif;
        color: #252525;
        font-weight: 600;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.25rem;
        padding-left: 0px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/06-world-10-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            display: flex;
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/06-world-10-11-2024-02.jpg)
              no-repeat;
            position: relative;
            background-size: contain;
            height: 89px;
          "
        >
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
              +91 ${repDetails?.mobile_number}
            </div>
          </div>
        </div>

        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/06-world-10-11-2024-03.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const medicalDays18_7 = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    rel="stylesheet">

    <title> </title>
    <style>
      body {
        font-family: "Inter", sans-serif;
        text-align: center;
        color: white;
        margin: 0;
        padding: 0;
      }
      .bgs {
        position: relative;
        margin: 0px auto;
        padding: 0px;
        width: 800px;
        height: 800px;
        border: solid 1px #ccc;
      }
      img {
        max-width: 100%;
      }
      .leftcols {
        position: relative;
        width: 50%;
      }
      .rightcols {
        padding: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .parasbold {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-top: 0.4rem;
        margin-bottom: 1rem;
        padding-left: 36px;
      }
      .paras {
        font-family: "Poppins", sans-serif;
        color: #ffffff;
        font-weight: 600;
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.25rem;
        padding-left: 36px;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="bgs">
        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/07-world-11-11-2024-01.jpg"
            style="vertical-align: top"
          />
        </div>
        <div
          style="
            display: flex;
            background: url(https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/07-world-11-11-2024-02.jpg)
              repeat;
            position: relative;
            background-size: contain;
          "
        >
          <div class="leftcols">
            <div class="parasbold">${repDetails?.fname} ${repDetails?.lname}</div>
            <div class="paras" style="margin-bottom: 0rem; margin-top: 0rem">
              +91 ${repDetails?.mobile_number}
            </div>
          </div>
          <div class="rightcols">
            <div>
              <img
                src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/07-world-11-11-2024-03.jpg"
                style="vertical-align: top"
              />
            </div>
          </div>
        </div>

        <div>
          <img
            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/07-world-11-11-2024-04.jpg"
          />
        </div>
      </div>
    </div>
  </body>
</html>
  `;

  const festiveHtmlContent13 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">

    <title>Diwali Greeting</title>
    <style>
body { 
  font-family: "Oswald", sans-serif;

	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
	
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/01-guru-nanak-jayanti-12-11-2024-text.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{color:#47210c; font-weight:600;font-size:52px; line-height:77px; padding:814px 0 0px 0;    font-family: "Oswald", sans-serif;}

.subtitle {color:#47210c; font-size: 30px;line-height:44px; font-weight:600;   font-family: "Oswald", sans-serif;serif; margin-bottom: 6px;}
.content {color:#47210c; font-size: 20px;font-family: "Oswald", sans-serif;line-height:23px; max-width:500px; margin:0px auto;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>

  `;

  const festiveHtmlContent14 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">

    <title>Diwali Greeting</title>
    <style>
body { 
  font-family: "Oswald", sans-serif;

	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
	
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/03-guru-nanak-jayanti-12-11-2024-text.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{text-align: left;color:#47210C; font-weight:600;font-size:52px; line-height:77px; padding:864px 0 14px 30px;    font-family: "Oswald", sans-serif;}

.subtitle {text-align: left;color:#47210C; font-size: 30px; padding-left:30px;line-height:44px; font-weight:600;   font-family: "Oswald", sans-serif;serif; margin-bottom: 6px;}
.content {text-align: left;color:#47210C; font-size: 20px;padding-left:30px;font-family: "Oswald", sans-serif;line-height:23px; max-width:500px; margin:0px 0;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>`;

  const festiveHtmlContent15 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">

    <title>Diwali Greeting</title>
    <style>
body { 
  font-family: "Oswald", sans-serif;

	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
	
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/02-guru-nanak-jayanti-12-11-2024-text.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{color:#ffffff; font-weight:600;font-size:52px; line-height:77px; padding:814px 0 14px 0;    font-family: "Oswald", sans-serif;}

.subtitle {color:#ffffff; font-size: 30px;line-height:44px; font-weight:600;   font-family: "Oswald", sans-serif;serif; margin-bottom: 6px;}
.content {color:#ffffff; font-size: 20px;font-family: "Oswald", sans-serif;line-height:23px; max-width:500px; margin:0px auto;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>
  `;

  const festiveHtmlContent16 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">


    <title>Diwali Greeting</title>
    <style>
body { 
  font-family: "Oswald", sans-serif;

	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
	
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/05-guru-nanak-jayanti-12-11-2024-text.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{color:#C47311; font-weight:700;font-size:52px; line-height:65px; padding:874px 0 10px 0px;font-family: "Oxygen", sans-serif;}

.subtitle {color:#373737; font-size: 30px; line-height:44px; font-weight:700; font-family: "Oxygen", sans-serif; margin-bottom: 6px;}
.content {color:#373737; font-size: 20px;font-family: "Oswald", sans-serif;line-height:23px; max-width:500px; margin:0px auto;text-align: center;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>
  `;

  const festiveHtmlContent17 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">

    <title>Diwali Greeting</title>
    <style>
body { 
  font-family: "Oswald", sans-serif;

	text-align: center;
	color: white;
	margin: 0;
	padding: 0;}
	
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/images_uploaded/04-guru-nanak-jayanti-12-11-2024-text.jpg') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{color:#FF3574; font-weight:600;font-size:52px; line-height:77px; padding:764px 0 14px 30px;    font-family: "Oswald", sans-serif;}

.subtitle {color:#373737; font-size: 30px; line-height:44px; font-weight:600;   font-family: "Oswald", sans-serif;serif; margin-bottom: 6px;}
.content {color:#373737; font-size: 20px;font-family: "Oswald", sans-serif;line-height:23px; max-width:500px; margin:0px auto;text-align: center;}
        
    </style>
</head>
<body>
    <div class="container">
	<div class="bgs">
       
        <div class="content-name">${
          formLang === "english"
            ? `<span class="content-name">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>
  `;

  const childrenDay1 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">
    <title>Diwali Greeting</title>
    <style>
body { 
    color: white;
    margin: 0;
font-family: "Oswald", sans-serif;    padding: 0;
    }
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/children-day-1.png') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{color:#3c98ff; font-style: italic; font-weight:600;font-size:32px; line-height:77px; padding:764px 0 0 30px;font-family: "Oswald", sans-serif}
.subtitle {color:#373737; font-style: italic; margin-top:20px; padding-left: 34px; font-size: 20px; line-height:44px; font-weight:600; margin-bottom: 6px;font-family: "Oswald", sans-serif}
.content {color:#494949; font-weight: 600; font-size: 20px;line-height:28px; max-width:600px;  padding-left:34px;font-family: "Oswald", sans-serif}
    </style>
</head>
<body>
    <div class="container">
    <div class="bgs">
        <div class="content-name">${
          formLang === "english"
            ? `<span class="">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>
  `;

  const childrenDay2 = `
 <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet">
    <title>Diwali Greeting</title>
    <style>
body { 
  font-family: "Oswald", sans-serif; 
    color: white;
    margin: 0;
    padding: 0;}
    
.bgs{background: url('https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/children-day-2.png') no-repeat center center;
background-size: cover;margin: 0px auto;padding: 0px;width: 1080px;height: 1080px;}
.content-name{font-weight:600;font-size:30px; line-height:77px; padding:764px 0 0 40px; }
.subtitle {padding-left: 40px; font-size: 22px; line-height:44px; font-weight:900; serif; margin-top:20px; margin-bottom: 6px;}
.content { font-weight: 900; font-size: 20px;line-height:28px; max-width:500px; margin-top:10px;  padding-left:40px;}
        
    </style>
</head>
<body>
    <div class="container">
    <div class="bgs">
       
        <div class="content-name">${
          formLang === "english"
            ? `<span class="">Dr. </span>`
            : `<span class="devanagari">डॉ. </span>`
        }
            ${doctorData?.first_name + " " + doctorData?.last_name}</div>
        <div class="subtitle">${doctorData?.clinic_name}</div>
        <div class="content">${doctorData?.address}</div>
       </div>
    </div>
</body>
</html>
  `;

  const chemistCard1 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Image with Transparent Background</title>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
        rel="stylesheet" />
    <script src="https://kit.fontawesome.com/ed1a8c0c21.js" crossorigin="anonymous"></script>
     <script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script>
    <script>
        window.onload = function() {
          // Generate the QR Code after the page loads
          const qrcode = new QRCode(document.getElementById("qrcode"), {
            text: "${htmlQrImage(doctorData?.lat, doctorData?.long)}",
            width: 55,
            height: 55,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        };
    </script>
    <style>
        body,
        html {
            margin: 0;
            padding: 0;
            background-color: #f0f0f0;
            font-family: "Roboto", sans-serif;
        }
        .image-container {
            color: #f0f0f0;
            position: relative;
            width: 500px;
            height: 300px;
            background-image: url(${
              doctorData?.shop_photo ??
              "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-1.webp"
            });
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
        }
        .overlay-image {
            position: absolute;
            inset: 0;
            height: 301px;
            left: 0;
        }
        .image-header {
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin: 20px;
        }
        .image-header .name {
            font-size: 20px;
            font-weight: 500;
        }
        .image-header .speciality {
            font-size: 10px;
            font-weight: 500;
        }
        .image-header .mci-number {
            font-size: 10px;
            font-weight: 500;
        }
        .location-qr-container {
            width: 60px;
            position: absolute;
            right: 37%;
            bottom: 2%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-wrap: nowrap;
            gap: 8px;
        }
        .qr-container {
            position: relative;
            width: 100%;
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .corner {
            position: absolute;
            width: 5px;
            height: 8px;
            border: 1px solid #66ccff;
            /* Light blue */
        }
        .corner.top-left {
            top: -2px;
            left: -2px;
            border-right: none;
            border-bottom: none;
        }
        .corner.top-right {
            top: -2px;
            right: -2px;
            border-left: none;
            border-bottom: none;
        }
        .corner.bottom-left {
            bottom: -2px;
            left: -2px;
            border-right: none;
            border-top: none;
        }
        .corner.bottom-right {
            bottom: -2px;
            right: -2px;
            border-left: none;
            border-top: none;
        }
        /* Placeholder for the QR code */
        .qr-placeholder {
            width: 90%;
            height: 90%;
            background: white;
        }
        .qr-location-text {
            font-size: 8px;
        }
        .chemist-info {

            border-left: 1px solid;
            border-image: linear-gradient(to top, #1B3D6E, rgb(83, 165, 197)) 1;
            position: absolute;
            display: flex;
            flex-direction: column;
            gap: 8px;
            top: 68px;
            margin-top: 30px;
            margin-left: 14px;
        }
        .fa-icon-custom-color {
            color: rgb(115, 201, 223);
            background: #1B3D6E;
        }
        .fa-location-custom-style {
            position: absolute;
            top: 0;
            left: -8px;
            border: 1px solid rgb(83, 165, 197);
            font-size: 9px;
            aspect-ratio: 1;
            padding: 2px 3px;
            border-radius: 50%;
        }
        .fa-phone-custom-style {
            position: absolute;
            top: 0;
            left: -8px;
            border: 1px solid rgb(83, 174, 197);
            font-size: 8px;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 50%;
        }
        .fa-whatsapp-custom-style {
            position: absolute;
            top: 0;
            left: -8px;
            border: 1px solid rgb(83, 174, 197);
            font-size: 10px;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 50%;
        }
        .fa-clock-custom-style {
            border: 1px solid rgb(83, 174, 197);
            font-size: 9px;
            aspect-ratio: 1;
            padding: 2px;
            border-radius: 50%;
        }
        .address-container {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 6px;
        }
        .address-header {
            display: flex;
            align-items: start;
            gap: 6px;
        }
        .address-container .store-name {
            font-weight: 500;
            font-size: 14px;
        }
        .address-container .store-address {
            font-size: 10px;
        }
    </style>
</head>
<body>
    <div class="image-container">
        <img src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-empty.png"
            class="overlay-image" />
        <div class="image-header">
          <span class="name">${doctorData?.first_name} ${
    doctorData?.last_name
  }</span>
      <span class="speciality">${doctorData?.designation ?? "Pharmacist"}</span>
      <span class="mci-number">${doctorData?.mci_number ?? ""}</span>
     </div>
        <div class="chemist-info">
            <div class="address-container">
      
                    <i class="fa-solid fa-location-dot fa-icon-custom-color fa-location-custom-style"></i>
                    <div class="" style="
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        width: 40%;
                        padding-left: 12px; 
                        min-height: 15px;
                    ">
                        <span class="store-name">${
                          doctorData?.clinic_name
                        }</span>
            <span class="store-address">
              ${doctorData?.address}
              </span>
                    </div>
              
            </div>
            <div style="display: flex; align-items: start; gap: 6px;
             position:relative; min-height: 15px;">
                <i class="fa-solid fa-phone fa-icon-custom-color fa-phone-custom-style"></i>
                <div style="display: flex; flex-direction: column; gap: 4px; padding-left: 12px;">
                 <span style="font-size: 10px;">+91 ${
                   doctorData?.clinic_number
                 }</span>
          <span style="font-size: 10px;">${doctorData?.email ?? ""}</span>
       </div>
            </div>
            ${
              doctorData?.whatsapp_number
                ? `<div style="display: flex; gap: 6px; align-items: center; margin: 10px 0; position:relative; min-height: 25px;">
                  <i class="fa-brands fa-whatsapp fa-icon-custom-color fa-whatsapp-custom-style"></i>
                  <span style="font-size: 10px; position: absolute; top:3px; left: 12px;">
                    +91 ${doctorData?.whatsapp_number ?? ""}
                  </span>
                </div>`
                : ""
            }
           ${
             doctorData?.timing
               ? `<div style="display: flex; gap: 6px; align-items: start; margin-left: -7px; width: 70%; flex-wrap: wrap;">
        <i class="fa-regular fa-clock fa-icon-custom-color fa-clock-custom-style"></i>
        <div style="width: 70%;">${formatClinicTimings(timing)}</div>
      </div>`
               : null
           }

        </div>
        <div class="location-qr-container">
            <div class="qr-container">
                <div id="qrcode"></div>
                <div class="corner top-left"></div>
                <div class="corner top-right"></div>
                <div class="corner bottom-left"></div>
                <div class="corner bottom-right"></div>
            </div>
            <span class="qr-location-text">Scan for location</span>
        </div>
    </div>
</body>
</html>
  `;

  const chemistCard2 = `
  <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
        href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
        rel="stylesheet" />
    <script src="https://kit.fontawesome.com/ed1a8c0c21.js" crossorigin="anonymous"></script>
    <style>
        body,
        html {
            margin: 0;
            padding: 0;
            background-color: #f0f0f0;
            font-family: "Roboto", sans-serif;
        }
        .card-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: white;
            width: 500px;
            height: 300px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        }
        .left-section .left-section-image {
            position: absolute;
            width: 250px;
            top: -20px;
            object-fit: contain;
        }
        .left-section .image-circle {
            width: 195px;
            aspect-ratio: 1;
            position: absolute;
            border-radius: 50%;
            border: 4px solid white;
            top: 47px;
            left: 38px;
        }
        .card-container .top-left-shape {
            height: 8px;
            width: 45%;
            background-color: #1b3d6e;
            border-bottom-right-radius: 25px 100%;
            position: absolute;
            top: 0;
        }
        .card-container .bottom-right-shape {
            position: absolute;
            width: 95%;
            height: 20px;
            bottom: 0;
            left: 25px;
            background-color: #1b3d6e;
            border-top-left-radius: 154px 40px;
            right: -40px;
        }
        .card-container .left-vertical-line {
            position: absolute;
            top: 5px;
            right: 10px;
            height: 90%;
            width: 2px;
            background: rgba(132, 241, 241, 0.568);
        }
        .card-container .right-section {
            color: #1b3d6e;
            width: 50%;
            margin-right: 18px;
            top: 20px;
            right: 20px;
            margin-top: -15px;
        }
        .right-section-header {
            display: flex;
            flex-direction: column;
            align-items: end;
        }
        .right-section-header .name {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 20px;
            font-weight: 700;
        }
        .name .plus-image {
            width: 35px;
        }
        .right-section-header .speciality {
            font-size: 10px;
            font-weight: 500;
            padding: 2px 4px;
            border-radius: 2px;
            background: rgb(107, 223, 223);
        }
        .right-section .mci-number {
            font-size: 10px;
            font-weight: 500;
            margin-top: 10px;
            text-align: end;
        }
        .right-section .chemist-info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 15px;
        }
        .chemist-info .chemist-info-section-one {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 4px;
            font-size: 15px;
            font-weight: 600;
        }
        .chemist-info-section-one .store-address {
            font-size: 12px;
            font-weight: 400;
            width: 225px;
            text-align: end;
        }
        .chemist-info .chemist-info-section-two {
            display: flex;
            flex-direction: column;
            align-items: end;
            gap: 2px;
            font-size: 12px;
        }
        .chemist-info .whatsapp {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 4px;
            font-size: 12px;
        }
        .chemist-info .timings {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            gap: 4px;
            font-size: 12px;
        }
        .main-content {
            width: 500px;
            display: flex;
            justify-content: space-between;
        }
    </style>
</head>
<body>
    <div class="card-container">
        <div class="top-left-shape"></div>
        <div class="bottom-right-shape"></div>
        <div class="main-content">
            <div class="left-section">
                <img src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/card--circle-shape.png"
                    class="left-section-image" />
                <img class="image-circle" src=${
                  doctorData?.shop_photo ??
                  "https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-card-default-2.jpg"
                } />
            </div>
            <div class="right-section">
                <div class="right-section-header">
                    <div class="name"><img
                            src="https://d3qziwubca5qnz.cloudfront.net/posts/field_rep_poster/chemist-icon.png"
                            class="plus-image" /><span>${
                              doctorData?.first_name
                            } ${doctorData?.last_name}</span></div>
                    <span class="speciality">${
                      doctorData?.designation ?? "Pharmacist"
                    }</span>
                </div>
                <div class="mci-number">${doctorData?.mci_number ?? ""}</div>
                <div class="chemist-info">
                    <div class="chemist-info-section-one">
                        <span class="store-name">${
                          doctorData?.clinic_name
                        }</span>
                        <span class="store-address">${
                          doctorData?.address
                        }</span>
                    </div>
                    <div class="chemist-info-section-two">
                        <span class="mobile-number">${
                          doctorData?.clinic_number
                        }</span>
                        <span class="email">${doctorData?.email ?? ""}</span>
                    </div>
                    ${
                      doctorData?.whatsapp_number ? (
                        <div class="whatsapp">
                          <span>+91 ${doctorData?.whatsapp_number} </span>
                          <i class="fa-brands fa-whatsapp"></i>
                        </div>
                      ) : null
                    }
                    ${
                      doctorData?.timing
                        ? `<div class="timings">
                          ${formatClinicTimings(timing)}
                        </div>`
                        : null
                    }
                    
                </div>
            </div>
        </div>
        <div class="left-vertical-line">
        </div>
    </div>
</body>
</html>
  `;

  const contentMap = {
    1: { 1: htmlContent1, 2: htmlContent2, 3: htmlContent3 }, // Content for campaignId 1
    2: {
      // Content for campaignId 2
      4: festiveHtmlContent1,
      5: festiveHtmlContent2,
      6: festiveHtmlContent3,
      7: festiveHtmlContent4,
      8: festiveHtmlContent5,
      9: festiveHtmlContent6,
      10: festiveHtmlContent7,
      11: festiveHtmlContent8,
      12: festiveHtmlContent9,
      13: festiveHtmlContent10,
      14: festiveHtmlContent11,
      15: festiveHtmlContent12,
      16: festiveGifContent1,
      17: festiveGifContent2,
      18: festiveGifContent3,
      19: festiveGifContent4,
      20: festiveGifContent5,
      21: festiveGifContent6,
      22: festiveGifContent8,
      23: festiveGifContent7,
      39: festiveGifContent10,
      40: festiveGifContent11,
      41: festiveHtmlContent13,
      42: festiveHtmlContent14,
      43: festiveHtmlContent15,
      44: festiveHtmlContent16,
      45: festiveHtmlContent17,
      46: childrenDay1,
      47: childrenDay2,
    },
    3: { any: brandContent1 }, // For campaignId 3, any cardNo returns brandContent1
    4: {
      32: medicalDays18_1,
      33: medicalDays18_2,
      34: medicalDays18_3,
      35: medicalDays18_4,
      36: medicalDays18_5,
      37: medicalDays18_6,
      38: medicalDays18_7,
    },
    5: { 48: chemistCard1, 49: chemistCard2 },
  };

  if (contentMap[campaignId] && contentMap[campaignId][cardNo]) {
    return contentMap[campaignId][cardNo]; // Return the matched content
  }
  // Special case for campaignId 3: return brandContent1 regardless of cardNo
  else if (campaignId === 3) {
    return contentMap[3].any;
  }
  // If no matching content is found, return a default message
  else {
    return "no html content";
  }
};

export default HtmlFunction;
