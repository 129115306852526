// add girl holding diya code here

import DOMPurify from "dompurify";
import React from "react";

const FestiveGif8 = ({ doctorData, formLang }) => {
  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Diwali Greetings</title>
  </head>

  <body>
    <div class="container">
      <div class="container-main">
        <img
          class="girl-holding-crcl-top-left"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl.png"
        />
        <img
          class="girl-holding-crcl-md-right"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-md.png"
        />
        <img
          class="girl-holding-crcl-sm-top"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />
          <div class="girl-holding-diya-flwr-main">
          <img
            class="girl-holding-diya-top-left-flwr"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-flwr.png"
          />
          <img
            class="girl-holding-diya-top-right-flwr"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-flwr.png"
          />
        </div>
                        <div class="bg-img">

          <img
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-happy.png"
            class="girl-holding-diya-happy"
          />
        <div class="messagetext">
          May this Diwali bring love & light to your family!
        </div>
        <div class="warmregards">Warm Regards</div>
        <div class="drname">
          ${formLang === "english" ? "Dr. " : "डॉ. "}
        ${doctorData?.first_name + " " + doctorData?.last_name}
        </div>
        <div class="clinicname">
        ${doctorData?.clinic_name}
        </div>
        <div class="address">
        ${doctorData?.address}
        </div>
            </div>
         <div class="girl-holding-hand-diya-main">
          <img
            class="girl-holding-hand-diya-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-hand-diya.png"
          />
          <img
            class="girl-holding-hand-diya-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-hand-diya.png"
          />
        </div>
        <img
          class="girl-holding-crcl-sm-left"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />
        <img
          class="girl-holding-crcl-sm-bottom"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />
        <img
          class="girl-holding-crcl-sm-right"
          src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-crcl-sm.png"
        />

        <div class="girl-holding-girl-diya">
          <img
            class="girl-holding-girl"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya.png"
          />
          <img
            class="girl-holding-diyas"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diyas.png"
          />
        <div class="girl-holding-diya-diya-main">
          <img
            class="girl-holding-diya-diya-left"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-diya.png"
          />
            <img
            class="girl-holding-diya-diya-right"
            src="https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-diya-diya.png"
          />
        </div>
          </div>

      </div>
    </div>
  </body>
</html>
  `;

  let sanitizedHtml = html ? DOMPurify.sanitize(html) : "";

  return (
    <>
      <style>
        {`
          body {
        margin: 0;
        padding: 0;
      }
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        width: calc(100vw - 32px);
        background: url(https://d3typ5yqytip29.cloudfront.net/posts/field_rep_poster/gifs/diwali-girl-holding-diya/girl-holding-pachs.png);
        background-repeat: round;
      }
      .container-main {
        height: 600px;
        position: relative;
        overflow: hidden;
        width: calc(100vw - 32px);
      }
      .girl-holding-crcl-top-left {
        width: 320px;
        position: absolute;
        top: -136px;
        left: -148px;
        z-index: 9;
      }
      .bg-img {
          position: absolute;
          bottom: 13.5rem;
      } 
      .girl-holding-crcl-md-right {
        width: 180px;
        position: absolute;
        top: -4rem;
        right: -4rem;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-crcl-sm-top {
        width: 93px;
        position: absolute;
        top: -1rem;
        left: -2rem;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-diya-flwr-main {
        position: absolute;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        animation: moveLight 1s ease-in-out forwards;
        animation-delay: 0.5s;
        visibility: hidden;
      }
      .girl-holding-diya-top-left-flwr {
        height: 150px;
      }
      .girl-holding-diya-top-right-flwr {
        transform: scaleX(-1);
        height: 150px;
      }
      .girl-holding-diya-happy {
        width: 110px;
        margin: 0 auto;
        display: block;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 2s;
      }
      .messagetext {
        font-size: 12px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 16px;
        width: 50%;
        text-align: center;
        color: #d9aca7;
        margin: 1.25rem auto 0rem auto;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 2.5s;
        visibility: hidden;
      }
      .warmregards {
        font-size: 10px;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        line-height: 16px;
        max-width: 140px;
        width: 100%;
        text-align: center;
        color: #fff;
        margin: 10px auto 5px auto;
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }
      .drname {
        font-size: 14px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        line-height: 17px;
        text-align: center;
        width: 50%;
        color: #fff;
        margin: 0 auto;
        animation: moveName 1s ease-in-out forwards;
        animation-delay: 3s;
        visibility: hidden;
      }
      .clinicname {
        font-size: 12px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
        line-height: 16px;
        width: 50%;
        color: #fff;
        text-align: center;
        margin: 10px auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }

      .address {
        font-size: 11px;
        font-family: "Inter", sans-serif;
        line-height: 16px;
        text-align: center;
        width: 60%;
        color: #fff;
        margin: 0.5rem auto;
        visibility: hidden;
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 4s;
      }

      .girl-holding-hand-diya-main {
        position: absolute;
        bottom: 280px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
      }
      .girl-holding-hand-diya-left {
        height: 45px;
        transform: scaleX(-1);
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 5s;
        visibility: hidden;
      }
      .girl-holding-hand-diya-right {
        animation: moveDiwaliLogo 1s ease-in-out forwards;
        animation-delay: 5s;
        visibility: hidden;
        height: 45px;
      }

      .girl-holding-crcl-sm-left {
        width: 35px;
        position: absolute;
        top: 7rem;
        left: 2rem;
        z-index: 9;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-crcl-sm-bottom {
        width: 40px;
        position: absolute;
        bottom: 68px;
        left: 0;
        z-index: 9;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-crcl-sm-right {
        width: 100px;
        position: absolute;
        bottom: 65px;
        right: -58px;
        z-index: 9;
        animation: rotate 50s infinite;
        animation-delay: 0.5s;
        transform: rotate(10deg);
      }
      .girl-holding-girl-diya {
        position: absolute;
        bottom: -4px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: center;
        margin-left: 1.5rem;
      }
      .girl-holding-girl {
        width: 10rem;
        position: absolute;
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 5.5s;
      }
      .girl-holding-diyas {
        width: 25px;
        position: absolute;
        top: 7px;
        left: 489px;
        z-index: 9;
        text-align: center;
      }

      .girl-holding-louts-main {
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 9;
        display: flex;
        justify-content: space-between;
      }
      .girl-holding-louts-left {
        width: 40px;
        height: 40px;
      }
      .girl-holding-louts-right {
        width: 40px;
        height: 40px;
      }
      .girl-holding-diya-diya-main {
        width: 100%;
      }
      .girl-holding-diya-diya-left {
        position: absolute;
        bottom: 0px;
        left: 10%;
        z-index: 9;
        width: 60px;
        animation: moveBottom 1s ease-in-out forwards;
        visibility: hidden;
        animation-delay: 6s;
      }
      .girl-holding-diya-diya-right {
        position: absolute;
        bottom: 0px;
        right: 21%;
        z-index: 9;
        width: 60px;
        animation: moveBottom 1s ease-in-out forwards;
        animation-delay: 6s;
        visibility: hidden;
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(250deg);
        }
        100% {
          transform: rotate(500deg);
        }
      }
      @keyframes moveLight {
        0% {
          top: -60%;
        }
        100% {
          top: -2%;
          visibility: visible;
        }
      }
      @keyframes moveDiwaliLogo {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveName {
        0% {
          transform: translateY(20%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      @keyframes moveRight {
        0% {
          left: -12%;
        }

        100% {
          left: -17%;
          visibility: visible;
        }
      }
      @keyframes moveLeft {
        0% {
          right: -12%;
        }

        100% {
          right: -17%;
          visibility: visible;
        }
      }
      @keyframes moveBottom {
        0% {
          bottom: -200px;
        }
        100% {
          bottom: 0;
          visibility: visible;
        }
      }
        `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </>
  );
};
export default FestiveGif8;
